import { useQuery } from '@tanstack/react-query';
import {
  type Forms_SearchSubmissionFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import dayjs from 'dayjs';
import { formsKeys } from './keys';

const searchFormsSubmissions = graphql(/* GraphQL */ `
  query forms_searchFormsSubmissions($filter: Forms_SearchSubmissionFilter!) {
    forms_searchFormsSubmissions(filter: $filter) {
      id {
        provider
        forPartyId
        objectId
      }
      title
      description
      type
      singleSubmissionInfo {
        status
        confirmationStatus
        submissionCount
      }
      infoRequestInfo {
        id {
          id
          provider
        }
        responseExpected
        responseCount
        responseCompleteCount
        responseConfirmationCount
        responseRejectedCount
        dueDate
        publishDate
        isConfirmable
        currentlyVisibleForResponders
        metaConfigType
        metaConfig {
          type
          ... on Timetable_OnCallAssignerOptions {
            type
            timetableId
            periodsToChooseFrom
            maxPeriodsTeacherCanGet
            requiredTeachersPerPeriod
            dueDate
            publishDate
            excludeTeachers
          }
        }
      }
      links {
        id
        type
      }
      tags {
        id
        name
        systemTag
      }
      updatedBy {
        person {
          partyId
          title {
            id
            name
            nameTextId
          }
          firstName
          lastName
          avatarUrl
          type
        }
      }
      updatedOn
    }
  }
`);

const searchFormsSubmissionsQuery = (filter: Forms_SearchSubmissionFilter) => ({
  queryKey: formsKeys.searchFormsSubmissions(filter),
  queryFn: () => gqlClient.request(searchFormsSubmissions, { filter }),
});

export function getSearchFormsSubmissions(
  filter: Forms_SearchSubmissionFilter,
) {
  return queryClient.fetchQuery(searchFormsSubmissionsQuery(filter));
}

export function useSearchFormsSubmissions(
  filter: Forms_SearchSubmissionFilter,
) {
  return useQuery({
    ...searchFormsSubmissionsQuery(filter),
    select: ({ forms_searchFormsSubmissions }) =>
      forms_searchFormsSubmissions.sort((a, b) =>
        b.infoRequestInfo
          ? dayjs(a?.infoRequestInfo?.dueDate).unix() -
            dayjs(b?.infoRequestInfo?.dueDate).unix()
          : dayjs(b.updatedOn).unix() - dayjs(a.updatedOn).unix(),
      ),
  });
}

export type ReturnTypeFromUseSearchFormsSubmissions = UseQueryReturnType<
  typeof useSearchFormsSubmissions
>[number];

import { IconButton, type IconButtonProps } from '@mui/material';
import { usePermissions } from '@tyro/api';
import { useTranslation } from '@tyro/i18n';
import { memo } from 'react';
import { useStudentActions } from '../../../store/student-actions';
import {
  StudentAvatarPicture,
  type StudentAvatarPictureProps,
} from './student-avatar-picture';

interface StudentAvatarProps extends StudentAvatarPictureProps {
  partyId: number;
  currentGroupId?: number;
  ContainingButtonProps?: IconButtonProps;
}

export function StudentAvatarInner({
  ContainingButtonProps,
  currentGroupId,
  ...props
}: StudentAvatarProps) {
  const { t } = useTranslation(['common']);
  const { isStaffUser } = usePermissions();
  const { getNeedToKnowButtonProps } = useStudentActions();

  return (
    <>
      <IconButton
        disabled={!isStaffUser}
        aria-label={t('common:studentsAvatarLabel', { name: props.name })}
        {...ContainingButtonProps}
        sx={{
          ...(ContainingButtonProps?.sx ?? {}),
          padding: 0,
          '&:hover': {
            boxShadow: '0px 0px 0px 8px rgba(71, 85, 105, 0.08)',
            backgroundColor: 'transparent',
          },
        }}
        {...getNeedToKnowButtonProps(props.partyId, currentGroupId)}
      >
        <StudentAvatarPicture {...props} />
      </IconButton>
    </>
  );
}

export const StudentAvatar = memo(StudentAvatarInner);

import { getUser } from '@tyro/api';
import {
  type NavObjectFunction,
  NavObjectType,
  lazyWithRetry,
} from '@tyro/core';
import { PieChartIcon } from '@tyro/icons';
import { getNoticeBoard } from '@tyro/notice-board';

const Dashboard = lazyWithRetry(() => import('./pages/dashboard'));

export const getShellRoutes: NavObjectFunction = (t) => [
  {
    type: NavObjectType.Category,
    title: t('navigation:general.title'),
    children: [
      {
        type: NavObjectType.RootLink,
        title: t('navigation:general.dashboard'),
        path: '/dashboard',
        hasAccess: ({ isStaffUser }) => isStaffUser,
        icon: <PieChartIcon />,
        element: <Dashboard />,
        loader: async () => {
          const { activeProfile } = await getUser();
          return getNoticeBoard({
            active: true,
            assignedTo: [activeProfile?.partyId ?? 0],
          });
        },
      },
    ],
  },
];

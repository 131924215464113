import { useQuery } from '@tanstack/react-query';
import {
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { rulesKeys } from './keys';

const rulesActionsMeta = graphql(/* GraphQL */ `
  query rules_actionTypesMetadata {
    rules_actionTypesMetadata {
      actionType
      name
      description
      inputObject
      canRevert
    }
  }
`);

const rulesActionsMetaQuery = () => ({
  queryKey: rulesKeys.actionMeta(),
  queryFn: () => gqlClient.request(rulesActionsMeta),
});

export function getRulesActionsMeta() {
  return queryClient.fetchQuery(rulesActionsMetaQuery());
}

export function useRulesActionsMeta() {
  return useQuery({
    ...rulesActionsMetaQuery(),
    select: ({ rules_actionTypesMetadata }) => rules_actionTypesMetadata,
  });
}

export type ReturnTypeFromUseRulesActionsMeta = UseQueryReturnType<
  typeof useRulesActionsMeta
>[number];

import { LoadingButton } from '@mui/lab';
import { Box, Button, Slider, Stack } from '@mui/material';
import { FileTransferFeature, queryClient, useUploadFile } from '@tyro/api';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FileDropzone,
  IconButtonWithTooltip,
  useToast,
} from '@tyro/core';
import { groupsKeys } from '@tyro/groups';
import { useTranslation } from '@tyro/i18n';
import { RotateItemIcon, ZoomMinusIcon, ZoomPlusIcon } from '@tyro/icons';
import { nanoid } from 'nanoid';
import { useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { peopleKeys } from '../../api/keys';

export type UploadPartyPhotoModalProps = {
  id: string;
  open: boolean;
  onClose: () => void;
  partyId: number | undefined;
  uploadType:
    | FileTransferFeature.StaffPhotos
    | FileTransferFeature.StudentPhotos
    | undefined;
};

export function UploadPartyPhotoModal({
  id,
  open,
  onClose,
  partyId,
  uploadType = FileTransferFeature.StudentPhotos,
}: UploadPartyPhotoModalProps) {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);
  const editorRef = useRef<AvatarEditor | null>(null);
  const [newPhoto, setNewPhoto] = useState<File | null>(null);
  const [photoSettings, setPhotoSettings] = useState({
    rotate: 0,
    scale: 0,
  });

  const { mutateAsync: uploadPhoto, isPending } = useUploadFile({
    documentType: uploadType,
    additionalFormValues: {
      overwrite: 'true',
      referenceId: partyId?.toString() ?? '',
    },
    mutationOptions: {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: peopleKeys.all,
          }),
          queryClient.invalidateQueries({
            queryKey: groupsKeys.all,
          }),
        ]);
        toast(t('common:snackbarMessages.uploadSuccess'));
        onClose();
      },
      onError: () => {
        toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
      },
    },
  });

  const onSave = () => {
    editorRef.current?.getImageScaledToCanvas().toBlob(
      (blob) => {
        if (blob) {
          uploadPhoto([new File([blob], `${partyId}-${nanoid(6)}.jpg`)]);
        }
      },
      'image/jpeg',
      0.8,
    );
  };

  useEffect(() => {
    if (open) {
      setNewPhoto(null);
      setPhotoSettings({ rotate: 0, scale: 0 });
    }
  }, [open]);

  const hasPhotoInState = newPhoto !== null;

  return (
    <Dialog id={id} open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onClose}>{t('common:uploadAvatar')}</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: hasPhotoInState ? 'center' : 'stretch',
          flexDirection: 'column',
        }}
      >
        {hasPhotoInState ? (
          <>
            <Box sx={{ borderRadius: 1, overflow: 'hidden', display: 'flex' }}>
              <AvatarEditor
                ref={editorRef}
                width={300}
                height={300}
                borderRadius={150}
                image={newPhoto}
                rotate={photoSettings.rotate}
                scale={1 + photoSettings.scale * 0.03}
              />
            </Box>
            <Stack
              direction="row"
              width="100%"
              maxWidth={350}
              spacing={4}
              justifyContent="space-between"
              mt={1}
            >
              <IconButtonWithTooltip
                onClick={() =>
                  setPhotoSettings((previousSettings) => ({
                    ...previousSettings,
                    rotate: previousSettings.rotate + 90,
                  }))
                }
                title={t('common:actions.rotate')}
                size="small"
              >
                <RotateItemIcon />
              </IconButtonWithTooltip>
              <Stack
                spacing={2}
                direction="row"
                sx={{ alignItems: 'center', flex: 1 }}
              >
                <ZoomMinusIcon />
                <Slider
                  aria-label={t('common:actions.zoom')}
                  value={photoSettings.scale}
                  onChange={(_, value) => {
                    setPhotoSettings((previousSettings) => ({
                      ...previousSettings,
                      scale: value as number,
                    }));
                  }}
                />
                <ZoomPlusIcon />
              </Stack>
            </Stack>
          </>
        ) : (
          <FileDropzone
            onUpload={(files) => setNewPhoto(files[0])}
            uploading={false}
            DropzoneOptions={{
              accept: {
                'image/jpeg': ['.jpeg', '.jpg', '.png'],
              },
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant="soft"
          color="inherit"
          onClick={hasPhotoInState ? () => setNewPhoto(null) : onClose}
        >
          {hasPhotoInState
            ? t('common:actions.back')
            : t('common:actions.cancel')}
        </Button>
        {hasPhotoInState && (
          <LoadingButton
            variant="contained"
            onClick={onSave}
            loading={isPending}
          >
            {t('common:actions.save')}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

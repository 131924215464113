import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { Notes_BehaviourType, Timeline_EventType } from '@tyro/api';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RHFTextField,
  useFormValidator,
  useToast,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import type { ReturnTypeFromUseStudentBehaviour } from '../../api/behaviour/student-behaviour';
import { useSaveTimelineHighlight } from '../../api/student/timeline';
import {
  RHFStaffAutocomplete,
  type StaffSelectOption,
} from '../common/staff-autocomplete';

export type CreateBehaviourHighlightModalProps = {
  open: boolean;
  onClose: () => void;
  behaviours: NonNullable<
    ReturnTypeFromUseStudentBehaviour['behaviours']
  >[number][];
  studentPartyId: number;
};

export type CreateHighlightFormState = {
  title: string;
  description: string;
  assignedTo: StaffSelectOption[];
};

export const eventTypeByBehaviourType = {
  [Notes_BehaviourType.Positive]: Timeline_EventType.BehaviourPositive,
  [Notes_BehaviourType.Negative]: Timeline_EventType.BehaviourNegative,
  [Notes_BehaviourType.Neutral]: Timeline_EventType.BehaviourNeutral,
};

export function CreateBehaviourHighlightModal({
  open,
  onClose,
  behaviours,
  studentPartyId,
}: CreateBehaviourHighlightModalProps) {
  const { t } = useTranslation(['common', 'people']);
  const { toast } = useToast();

  const { resolver, rules } = useFormValidator<CreateHighlightFormState>();

  const { control, handleSubmit, reset } = useForm<CreateHighlightFormState>({
    resolver: resolver({
      title: rules.required(),
      description: rules.required(),
      assignedTo: rules.required(),
    }),
  });

  const { mutate, isPending } = useSaveTimelineHighlight();

  const onSubmit = handleSubmit(({ title, description, assignedTo }) => {
    mutate(
      {
        title,
        highlight: description,
        assignedToPartyIds: assignedTo.map(({ partyId }) => partyId),
        studentPartyId,
        events: behaviours.map(({ noteId, tags }) => {
          const behaviourType = tags![0]?.behaviourType;
          const eventType = eventTypeByBehaviourType[behaviourType!];

          return {
            eventId: noteId.toString(),
            eventType,
          };
        }),
      },
      {
        onSuccess: () => {
          toast(t('common:snackbarMessages.createSuccess'));
          onClose();
        },
      },
    );
  });

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      fullWidth
      maxWidth="sm"
    >
      <form onSubmit={onSubmit}>
        <DialogTitle onClose={onClose}>
          {t('common:createHighlight')}
        </DialogTitle>
        <DialogContent>
          <Stack gap={3} mt={1}>
            <RHFTextField
              label={t('common:title')}
              controlProps={{
                name: 'title',
                control,
              }}
            />
            <RHFTextField
              label={t('common:description')}
              controlProps={{
                name: 'description',
                control,
              }}
              textFieldProps={{
                fullWidth: true,
                multiline: true,
                rows: 4,
              }}
            />
            <RHFStaffAutocomplete
              label={t('people:assignedTo')}
              multiple
              controlProps={{
                name: 'assignedTo',
                control,
              }}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            {t('common:actions.cancel')}
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isPending}>
            {t('common:actions.save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

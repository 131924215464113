import { useMutation } from '@tanstack/react-query';
import { type WriteOffInput, gqlClient, graphql, queryClient } from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { feeKeys } from './keys';

const writeOffBalance = graphql(/* GraphQL */ `
  mutation fees_writeOffStudentsFee($input: [WriteOffInput!]) {
    fees_writeOffStudentsFee(input: $input) {
      success
    }
  }
`);

export function useWriteOffBalance() {
  const { t } = useTranslation(['common', 'fees']);
  const { toast } = useToast();

  return useMutation({
    mutationFn: (input: WriteOffInput[]) =>
      gqlClient.request(writeOffBalance, { input }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: feeKeys.all });
      toast(t('fees:writeOffBalanceSuccess'));
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

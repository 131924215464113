import { Reporting_ReportCellType } from '@tyro/api';
import {
  type ICellRendererParams,
  type StatusPanelDef,
  Table,
  type TableProps,
} from '@tyro/core';
import { useMemo } from 'react';
import type { ReturnTypeFromUseRunReports } from '../api/run-report';

import {
  type UseReportFormatValuesArg,
  useReportFormatValues,
} from '../hooks/use-report-format-values';
import type { ExtendedReportData, ReportColumnDef } from './types';

export type ReportTableViewProps = {
  isLoading: boolean;
  reportData: ReturnTypeFromUseRunReports | undefined | null;
  cellSettings?: UseReportFormatValuesArg['settings'];
};

export function ReportTableView({
  isLoading,
  reportData,
  cellSettings,
}: ReportTableViewProps) {
  const {
    getValue,
    formatValue,
    renderValue,
    aggFunc,
    buildHeaderClass,
    valueComparator,
    buildCellClass,
    buildCellStyle,
    getColumnFilterSettings,
  } = useReportFormatValues({
    settings: cellSettings,
  });

  const mainColumns = useMemo<ReportColumnDef[]>(() => {
    const fields = reportData?.fields || [];

    return fields.map<ReportColumnDef>((column) => ({
      field: column.id,
      headerName: column.label,
      valueGetter: ({ data }) => {
        if (!data) return null;

        return getValue(column, data[column.id]);
      },
      valueFormatter: ({ data, node, column: formatterColumn }) => {
        if (node?.group && formatterColumn.isRowGroupActive()) {
          return node.key ?? '-';
        }

        if (!data) return '';

        return formatValue(column, data[column.id]) ?? '';
      },
      cellRenderer: ({ data }: ICellRendererParams<ExtendedReportData>) => {
        if (!data) return null;

        return renderValue(column, data[column.id]) || '-';
      },
      filterValueGetter: ({ data }) => getValue(column, data?.[column.id]),
      sortable: column.sortable,
      initialHide: !column.visibleByDefault,
      pinned: column.pinned ?? null,
      rowGroup: column.rowGroup ?? false,
      wrapText: true,
      autoHeight: true,
      aggFunc: aggFunc(column),
      headerClass: () => buildHeaderClass(column),
      cellClass: ({ data }) => buildCellClass(column, data?.[column.id]),
      comparator: valueComparator(column),
      ...(column.minWidth && {
        minWidth: column.minWidth,
      }),
      ...(column.maxWidth && {
        maxWidth: column.maxWidth,
      }),
      cellStyle: ({ data }) => buildCellStyle(column, data?.[column.id]),
      ...(column.hideMenu
        ? {
            suppressHeaderMenuButton: true,
          }
        : {
            ...getColumnFilterSettings(column),
            enableRowGroup: true,
            suppressHeaderMenuButton:
              column.cellType === Reporting_ReportCellType.Number,
          }),
    }));
  }, [reportData?.fields]);

  const genericReportData = useMemo<ExtendedReportData[]>(() => {
    const reportFieldsData = (reportData?.data || []) as ExtendedReportData[];

    return reportFieldsData.reduce<ExtendedReportData[]>(
      (reportFieldData, obj) => {
        const rowData = Object.keys(obj).reduce((row, key) => {
          row[key] ??= obj[key];
          return row;
        }, {} as ExtendedReportData);

        reportFieldData.push(rowData);
        return reportFieldData;
      },
      [],
    );
  }, [reportData?.data]);

  const statusPanels = (reportData?.statusPanels ?? []) as StatusPanelDef[];

  return (
    <Table<ExtendedReportData>
      tableId={reportData?.id}
      isLoading={isLoading}
      rowData={genericReportData}
      columnDefs={mainColumns}
      gridOptions={
        reportData?.tableDisplayOptions
          ?.gridOptions as TableProps<ExtendedReportData>['gridOptions']
      }
      tableContainerSx={
        reportData?.tableDisplayOptions
          ?.tableContainerSx as TableProps<ExtendedReportData>['tableContainerSx']
      }
      getRowId={({ data }) => String(data?.id.value)}
      statusBar={{
        statusPanels: [
          {
            statusPanel: 'agTotalAndFilteredRowCountComponent',
            align: 'left',
          },
          ...(statusPanels.length > 0
            ? statusPanels
            : [
                { statusPanel: 'agFilteredRowCountComponent' },
                { statusPanel: 'agSelectedRowCountComponent' },
              ]),
        ],
      }}
    />
  );
}

import { Chip } from '@mui/material';
import { type RoomFilter, getColorBasedOnIndex } from '@tyro/api';
import {
  Autocomplete,
  type AutocompleteProps,
  type CustomCellEditorProps,
  RHFAutocomplete,
  type RHFAutocompleteProps,
  TableAutocomplete,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import type { FieldValues } from 'react-hook-form';
import { useCoreRooms } from '../../api/rooms/rooms';

export interface RoomSelect {
  roomId: number;
  name: string;
}

type RHFRoomAutocompleteProps<TField extends FieldValues> = Omit<
  RHFAutocompleteProps<TField, RoomSelect>,
  'options'
> & { filter?: RoomFilter };

type RoomAutocompleteProps = Omit<
  AutocompleteProps<RoomSelect>,
  | 'optionIdKey'
  | 'optionTextKey'
  | 'getOptionLabel'
  | 'filterOptions'
  | 'renderAvatarTags'
  | 'renderAvatarOption'
  | 'renderAvatarAdornment'
> & { filter?: RoomFilter };

export const RHFRoomAutocomplete = <TField extends FieldValues>({
  filter,
  ...props
}: RHFRoomAutocompleteProps<TField>) => {
  const { t } = useTranslation(['common']);
  const { data: roomsData, isLoading } = useCoreRooms(filter);

  return (
    <RHFAutocomplete<TField, RoomSelect>
      label={t('common:room', { count: 1 })}
      {...props}
      fullWidth
      optionIdKey="roomId"
      optionTextKey="name"
      loading={isLoading}
      options={roomsData ?? []}
      renderTags={(tags, getTagProps) =>
        tags.map((tag, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              key={key}
              {...tagProps}
              size="small"
              variant="soft"
              color={getColorBasedOnIndex(tag?.roomId)}
              label={tag.name}
            />
          );
        })
      }
    />
  );
};

export const RoomAutocomplete = ({
  filter,
  ...props
}: RoomAutocompleteProps) => {
  const { t } = useTranslation(['common']);
  const { data: roomsData, isLoading } = useCoreRooms(filter ?? {});

  return (
    <Autocomplete
      label={t('common:room', { count: props.multiple ? 2 : 1 })}
      fullWidth
      optionIdKey="roomId"
      optionTextKey="name"
      loading={isLoading}
      {...props}
      options={roomsData ?? []}
    />
  );
};

export interface TableRoomAutocompleteProps
  extends CustomCellEditorProps<unknown, RoomSelect | RoomSelect[]> {
  multiple: boolean;
  filter?: RoomFilter;
}

export function TableRoomAutocomplete(props: TableRoomAutocompleteProps) {
  const { t } = useTranslation(['common']);
  const { filter, multiple, ...restProps } = props;

  const { data: roomsData = [], isLoading } = useCoreRooms(filter);

  return (
    // @ts-expect-error
    <TableAutocomplete<RoomSelect | null>
      {...restProps}
      options={roomsData ?? []}
      getOptionLabel={(option) => option?.name ?? ''}
      optionIdKey="roomId"
      AutocompleteProps={{
        autoHighlight: true,
        loading: isLoading,
        loadingText: t('common:loading'),
        multiple,
      }}
    />
  );
}

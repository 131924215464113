import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  DialogContentText,
  Stack,
} from '@mui/material';
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  type DisplayNamePersonProps,
  PreferredNameFormat,
  usePreferredNameLayout,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useDeleteBehaviour } from '../../api/behaviour/delete-behaviour';
import { useStudentBehaviour } from '../../api/behaviour/student-behaviour';
import { StudentAvatar } from '../students/student-avatar';

interface ConfirmDeleteBehaviourProps {
  idToDelete?: number | null;
  student?: DisplayNamePersonProps & { partyId: number };
  onClose: () => void;
}

export function ConfirmDeleteBehaviour({
  idToDelete,
  student,
  onClose,
}: ConfirmDeleteBehaviourProps) {
  const { t } = useTranslation(['common', 'people']);
  const { displayName } = usePreferredNameLayout();

  const { data: behaviourData, isLoading: isLoadingBehaviourData } =
    useStudentBehaviour(
      { noteIds: [idToDelete ?? 0] },
      { enabled: Boolean(idToDelete && student) },
    );
  const { mutateAsync: deleteBehaviour, isPending } = useDeleteBehaviour();

  const onConfirmDelete = async (studentId?: number) => {
    if (idToDelete) {
      await deleteBehaviour(
        {
          noteId: idToDelete,
          referencedPartyIds: studentId ? [studentId] : undefined,
        },
        { onSuccess: onClose },
      );
    }
  };

  const recipients = behaviourData?.behaviours?.[0]?.referencedParties ?? [];
  const studentName = displayName(student, {
    format: PreferredNameFormat.FirstnameSurname,
  });
  const askWhoTheyWantToDeleteFor = recipients.length > 1 && student;

  return (
    <Dialog
      open={!!idToDelete}
      onClose={onClose}
      fullWidth
      maxWidth={askWhoTheyWantToDeleteFor ? 'sm' : 'xs'}
    >
      {isLoadingBehaviourData ? (
        <Stack
          height="20vh"
          maxHeight={380}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <DialogTitle onClose={onClose}>
            {t('people:deleteBehaviour')}
          </DialogTitle>
          <DialogContent>
            {askWhoTheyWantToDeleteFor ? (
              <>
                <DialogContentText>
                  {t('people:thisBehaviourIsAssociatedWithMultipleStudent')}
                </DialogContentText>
                <Box
                  component="ul"
                  sx={{
                    color: 'text.secondary',
                    pl: 2,

                    '& li': {
                      display: 'flex',
                      alignItems: 'center',

                      '& + li': {
                        mt: 1,
                      },
                    },

                    '& .MuiAvatar-root': {
                      mr: 1,
                    },
                  }}
                >
                  {recipients.map((recipient) => {
                    const name = displayName(recipient, {
                      format: PreferredNameFormat.FirstnameSurname,
                    });
                    return (
                      <li key={recipient.partyId}>
                        <Avatar
                          name={name}
                          person={recipient}
                          src={recipient.avatarUrl}
                        />
                        {name}
                      </li>
                    );
                  })}
                </Box>
                <DialogContentText>
                  {t('people:wouldYouPreferToRemoveStudentOrDeleteBehaviour', {
                    name: studentName,
                  })}
                </DialogContentText>
              </>
            ) : (
              <DialogContentText>
                {t('people:areYouSureYouWantToDeleteBehaviour')}
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button autoFocus color="inherit" variant="soft" onClick={onClose}>
              {t('common:actions.cancel')}
            </Button>
            {askWhoTheyWantToDeleteFor && (
              <LoadingButton
                variant="soft"
                color="error"
                loading={isPending}
                onClick={() => onConfirmDelete(student.partyId)}
              >
                {t('people:deleteForName', { name: studentName })}
              </LoadingButton>
            )}
            <LoadingButton
              variant="contained"
              color="error"
              loading={isPending}
              onClick={() =>
                onConfirmDelete(
                  askWhoTheyWantToDeleteFor ? undefined : student?.partyId,
                )
              }
            >
              {askWhoTheyWantToDeleteFor
                ? t('people:deleteBehaviour')
                : t('common:actions.confirm')}
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

import type { NotificationFilter } from '@tyro/api';

export const shellKeys = {
  all: ['shell'] as const,
  notifications: {
    all: () => [...shellKeys.all, 'notifications'] as const,
    list: (filter: NotificationFilter) =>
      [...shellKeys.notifications.all(), 'list', filter] as const,
    unreadCount: () =>
      [...shellKeys.notifications.all(), 'unreadCount'] as const,
  },
};

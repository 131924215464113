import { useMutation, useQuery } from '@tanstack/react-query';
import {
  type NoticeAction,
  type NoticeboardFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { noticeBoardKeys } from './keys';

const noticeBoard = graphql(/* GraphQL */ `
  query communications_noticeboard($filter: NoticeboardFilter) {
    communications_noticeboard(filter: $filter) {
      id
      title
      notice
      priority
      validFrom
      validUntil
      photoUrl
      allStaff
      assignedToPartyIds
      assignedToParties {
        __typename
        ... on GeneralGroup {
          name
          partyId
          avatarUrl
        }
        ... on ProgrammeStageEnrollment {
          partyId
          name
        }
        ... on SubjectGroup {
          name
          partyId
          avatarUrl
        }
        ... on YearGroupEnrollment {
          name
          partyId
        }
        ... on Staff {
          partyId
          person {
            partyId
            firstName
            lastName
            avatarUrl
          }
        }
        ... on Student {
          partyId
          person {
            partyId
            firstName
            lastName
            avatarUrl
          }
        }
        ... on StudentContact {
          partyId
          person {
            partyId
            firstName
            lastName
            avatarUrl
          }
        }
      }
      recipients {
        partyId
        acknowledged
        acknowledgedOn
      }
      availableToStaff
      availableToContacts
      availableToStudents
      pinned
      archived
      archivedByPartyId
      archivedBy {
        partyId
        title {
          id
          name
        }
        firstName
        lastName
        avatarUrl
        type
        archived
      }
    }
  }
`);

const acknowledgeNotice = graphql(/* GraphQL */ `
  mutation communications_acknowledgeNotice($input: NoticeAction) {
    communications_acknowledgeNotice(input: $input) {
      success
    }
  }
`);

const noticeBoardQuery = (filter: NoticeboardFilter) => ({
  queryKey: noticeBoardKeys.noticeBoard(filter),
  queryFn: () =>
    gqlClient.request(noticeBoard, {
      filter,
    }),
});

export function getNoticeBoard(filter: NoticeboardFilter) {
  return queryClient.fetchQuery(noticeBoardQuery(filter));
}
export function useNoticeBoard(filter: NoticeboardFilter) {
  return useQuery({
    ...noticeBoardQuery(filter),
    select: ({ communications_noticeboard }) =>
      communications_noticeboard.sort((a, b) => {
        if (a.pinned && !b.pinned) {
          return -1;
        }

        if (!a.pinned && b.pinned) {
          return 1;
        }

        return (b.priority ?? 0) - (a.priority ?? 0);
      }),
  });
}

export function useAcknowledgeNotice() {
  return useMutation({
    mutationFn: async (input: NoticeAction) =>
      gqlClient.request(acknowledgeNotice, { input }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: noticeBoardKeys.all });
    },
  });
}

export type ReturnTypeFromUseNoticeBoard = UseQueryReturnType<
  typeof useNoticeBoard
>[number];

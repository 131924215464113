import { useQuery } from '@tanstack/react-query';
import {
  FeeStatus,
  type StudentFeeFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { feeKeys } from './keys';

const studentFees = graphql(/* GraphQL */ `
  query fees_studentFees($filter: StudentFeeFilter) {
    fees_studentFees(filter: $filter) {
      id {
        feeId
        studentPartyId
      }
      person {
        partyId
        firstName
        lastName
        avatarUrl
      }
      feeName
      dueDate
      amount
      amountPaid
      amountDue
      feeType
      feeStatus
      amountDiscounted
      adHocAmount
      discounts {
        id
        name
        description
        discountType
        value
        siblingDiscount
      }
      amountWrittenOff
      writeOffNote
      allowPartialPayments
      classGroup {
        name
      }
    }
  }
`);

const studentFeesQuery = (filter: StudentFeeFilter) => ({
  queryKey: feeKeys.studentFees(filter),
  queryFn: async () => {
    const data = await gqlClient.request(studentFees, { filter });

    return {
      ...data,
      fees_studentFees: data.fees_studentFees.map((studentFee) => ({
        ...studentFee,
        feeStatus:
          studentFee.amountDue <= 0 ? FeeStatus.Complete : studentFee.feeStatus,
      })),
    };
  },
});

export function getStudentFees(filter: StudentFeeFilter) {
  return queryClient.fetchQuery(studentFeesQuery(filter));
}

export function useStudentFees(filter: StudentFeeFilter) {
  return useQuery({
    ...studentFeesQuery(filter),
    select: ({ fees_studentFees }) => fees_studentFees,
  });
}

export type ReturnTypeFromUseStudentFees = UseQueryReturnType<
  typeof useStudentFees
>[number];

import { useMutation } from '@tanstack/react-query';
import {
  type Templating_SaveTemplateInput,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { templatesKeys } from './keys';

const saveTemplate = graphql(/* GraphQL */ `
  mutation templating_saveTemplate($input: Templating_SaveTemplateInput) {
    templating_saveTemplate(input: $input) {
      success
    }
  }
`);

export function useSaveTemplate() {
  const { toast } = useToast();
  const { t } = useTranslation(['templates', 'common']);

  return useMutation({
    mutationFn: async (input: Templating_SaveTemplateInput) =>
      gqlClient.request(saveTemplate, { input }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: templatesKeys.all,
      });
      toast(t('templates:savedSuccessfullyMessage'));
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { AttendanceCodeType } from '@tyro/api';
import { useTranslation } from '@tyro/i18n';
import { useAttendanceCodeById } from '../hooks/use-attendance-by-id';
import { useAttendanceCodeByType } from '../hooks/use-attendance-by-type';

type AttendanceToggleProps = {
  codeId?: number | null;
  onChange: (newCodeId: number) => void;
};

export function AttendanceToggle({ codeId, onChange }: AttendanceToggleProps) {
  const { t } = useTranslation(['attendance']);
  const codeByType = useAttendanceCodeByType({ teachingGroupCodes: true });
  const codeById = useAttendanceCodeById({ teachingGroupCodes: true });

  const handleAttendanceCodeChange = (code: AttendanceCodeType | number) => {
    if (codeById && codeByType) {
      const currentCode =
        typeof code === 'number' ? codeById[code] : codeByType[code];

      if (currentCode) {
        onChange(currentCode.id);
      }
    }
  };

  const codeType = codeId
    ? codeById?.[codeId]?.codeType
    : codeByType?.PRESENT?.codeType;

  return (
    <>
      <ToggleButtonGroup
        size="small"
        exclusive
        value={codeType}
        sx={{
          '& .MuiToggleButtonGroup-grouped': {
            margin: {
              xs: 0.25,
              sm: 0.5,
            },
            paddingX: {
              xs: 0.5,
              sm: 1.25,
            },
          },
        }}
        onChange={(_ev, code: AttendanceCodeType) => {
          handleAttendanceCodeChange(code);
        }}
      >
        <ToggleButton value={AttendanceCodeType.Present} color="success">
          {t('attendance:nameByCodeType.PRESENT')}
        </ToggleButton>
        <ToggleButton value={AttendanceCodeType.Late} color="info">
          {t('attendance:nameByCodeType.LATE')}
        </ToggleButton>
        <ToggleButton
          value={AttendanceCodeType.UnexplainedAbsence}
          color="error"
        >
          {t('attendance:absent')}
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
}

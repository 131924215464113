import { useMutation, useQuery } from '@tanstack/react-query';
import {
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { adminKeys } from './keys';

const adminTenants = graphql(/* GraphQL */ `
  query admin__tenants {
    admin__tenants {
      tenant
      name
      imgUrl
      liveSchool
      rollNo
      description
      location
    }
  }
`);

const adminResetTenantCache = graphql(/* GraphQL */ `
  mutation admin__resetTenantCache($input: Int) {
    admin__resetTenantCache(input: $input) {
      success
    }
  }
`);

const adminTenantsQuery = {
  queryKey: adminKeys.tenants(),
  queryFn: async () => gqlClient.request(adminTenants),
};

export function getTenants() {
  return queryClient.fetchQuery(adminTenantsQuery);
}

export function useAdminTenants() {
  return useQuery({
    ...adminTenantsQuery,
    select: ({ admin__tenants }) => admin__tenants,
  });
}

export function useEvictTenantLevelCache() {
  const { toast } = useToast();

  return useMutation({
    mutationFn: async (input: number) =>
      gqlClient.request(adminResetTenantCache, { input }),
    onSuccess: () => {
      toast('Tenant cache evicted successfully');
    },
    onError: () => {
      toast('Issue evicting tenant cache', { variant: 'error' });
    },
  });
}

export type ReturnTypeFromUseAdminTenants = UseQueryReturnType<
  typeof useAdminTenants
>[number];

export type ReturnTypeFromUseEvictTenantLevelCache = ReturnType<
  typeof useEvictTenantLevelCache
>;

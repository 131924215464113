import { useQuery } from '@tanstack/react-query';
import {
  type AccountingCodeFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { feeKeys } from './keys';

const accountingCodes = graphql(/* GraphQL */ `
  query fees_accountingCodes($filter: AccountingCodeFilter) {
    fees_accountingCodes(filter: $filter) {
      id
      code
      description
      active
      createdBy {
        partyId
        firstName
        lastName
        avatarUrl
      }
    }
  }
`);

const accountingCodesQuery = (filter: AccountingCodeFilter) => ({
  queryKey: feeKeys.accountingCodes(filter),
  queryFn: () => gqlClient.request(accountingCodes, { filter }),
});

export function getAccountingCodes(filter: AccountingCodeFilter) {
  return queryClient.fetchQuery(accountingCodesQuery(filter));
}

export function useAccountingCodes(filter: AccountingCodeFilter) {
  return useQuery({
    ...accountingCodesQuery(filter),
    select: ({ fees_accountingCodes }) => fees_accountingCodes,
  });
}

export type ReturnTypeFromUseAccountingCodes = UseQueryReturnType<
  typeof useAccountingCodes
>[number];

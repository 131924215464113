import { LoadingButton } from '@mui/lab';
import {
  Button,
  Chip,
  DialogContent,
  InputAdornment,
  Stack,
} from '@mui/material';
import { DiscountType, getColorBasedOnIndex } from '@tyro/api';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  RHFAutocomplete,
  RHFRadioGroup,
  RHFTextField,
  useFormValidator,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  type ReturnTypeFromUseDiscounts,
  useDiscounts,
} from '../../../api/discounts';
import { getDiscountName } from '../../../utils/get-discount-name';

enum AddDiscountType {
  Existing = 'EXISTING',
  OneTime = 'ONE_TIME',
}

type FormState = {
  discountType: AddDiscountType;
  discount: ReturnTypeFromUseDiscounts;
  adhocAmount?: number;
};

export type BulkAddIndividualDiscountModalProps = {
  isOpen: boolean;
  feeAmount: number;
  onClose: () => void;
  onSave: (discount: Pick<FormState, 'discount' | 'adhocAmount'>) => void;
};

const defaultValues: Partial<FormState> = {
  discountType: AddDiscountType.Existing,
};

// NOTE: id -1 is used for adHocAmount
export const DISCOUNT_ID_FOR_ADHOC = -1;

export function BulkAddIndividualDiscountModal({
  isOpen,
  feeAmount,
  onClose,
  onSave,
}: BulkAddIndividualDiscountModalProps) {
  const { t } = useTranslation(['common', 'fees']);

  const [loading, setLoading] = useState<boolean>(false);

  const { resolver, rules } = useFormValidator<FormState>();
  const { control, handleSubmit, reset, watch } = useForm<FormState>({
    resolver: resolver({
      discountType: rules.required(),
      adhocAmount: [
        rules.required(),
        rules.isNumber(),
        rules.validate((adhocAmount, throwError) => {
          if (Number(adhocAmount ?? 0) > Number(feeAmount)) {
            throwError(t('fees:amountToDiscountExceedsTheFeeAmount'));
          }
        }),
      ],
      discount: [
        rules.required(),
        rules.validate<ReturnTypeFromUseDiscounts>(
          (selectedDiscount, throwError) => {
            if (
              selectedDiscount.discountType === DiscountType.Fixed &&
              (selectedDiscount.value ?? 0) > Number(feeAmount)
            ) {
              throwError(t('fees:amountToDiscountExceedsTheFeeAmount'));
            }
          },
        ),
      ],
    }),
    defaultValues,
  });

  const { data: discountsData = [] } = useDiscounts({ ids: [] });
  const nonSiblingDiscounts = discountsData.filter(
    ({ siblingDiscount, id }) =>
      !siblingDiscount && id !== DISCOUNT_ID_FOR_ADHOC,
  );

  const handleSave = async ({ discount, adhocAmount }: FormState) => {
    setLoading(true);
    await (async () => {
      const adhocAmountNumber = Number(adhocAmount);
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await onSave({
        adhocAmount: adhocAmount ? adhocAmountNumber : undefined,
        discount: discount ?? {
          id: DISCOUNT_ID_FOR_ADHOC,
          name: t('fees:adHoc'),
          discountType: DiscountType.Fixed,
          value: adhocAmountNumber ?? 0,
        },
      });
    })();
    setLoading(false);
  };

  const [discountType] = watch(['discountType']);

  useEffect(() => {
    reset({ ...defaultValues, discountType });
  }, [isOpen, discountType]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll="paper"
      fullWidth
      maxWidth="xs"
    >
      <form
        onSubmit={(ev) => {
          ev.stopPropagation();
          handleSubmit(handleSave)(ev);
        }}
      >
        <DialogTitle>{t('fees:addDiscount')}</DialogTitle>
        <DialogContent>
          <Stack gap={2} mt={1}>
            <RHFRadioGroup
              radioGroupProps={{ sx: { flexDirection: 'row' } }}
              label={t('fees:discountType')}
              options={[AddDiscountType.Existing, AddDiscountType.OneTime].map(
                (option) => ({
                  value: option,
                  label: t(`fees:individualDiscountsType.${option}`),
                }),
              )}
              controlProps={{
                name: 'discountType',
                control,
              }}
            />

            {discountType === AddDiscountType.Existing && (
              <RHFAutocomplete
                label={t('fees:selectDiscount')}
                optionIdKey="id"
                getOptionLabel={getDiscountName}
                controlProps={{
                  name: 'discount',
                  control,
                }}
                options={nonSiblingDiscounts}
                renderTags={(tags, getTagProps) =>
                  tags.map((tag, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                      <Chip
                        key={key}
                        {...tagProps}
                        size="small"
                        variant="soft"
                        color={getColorBasedOnIndex(tag.id)}
                        label={tag.name}
                      />
                    );
                  })
                }
              />
            )}

            {discountType === AddDiscountType.OneTime && (
              <RHFTextField
                label={t('fees:enterAmount')}
                textFieldProps={{
                  type: 'number',
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">€</InputAdornment>
                    ),
                  },
                }}
                controlProps={{
                  name: 'adhocAmount',
                  control,
                }}
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="soft" color="inherit" onClick={onClose}>
            {t('common:actions.cancel')}
          </Button>

          <LoadingButton type="submit" variant="contained" loading={loading}>
            {t('common:actions.save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

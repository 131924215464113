import { useQuery } from '@tanstack/react-query';
import {
  type FormsAdminTagsFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { formsKeys } from './keys';

const tags = graphql(/* GraphQL */ `
  query formsAdmin_tags($filter: FormsAdminTagsFilter) {
    formsAdmin_tags(filter: $filter) {
      id
      name
      disabled
      tenantCanEdit
      systemTag
    }
  }
`);

const tagsQuery = (filter: FormsAdminTagsFilter) => ({
  queryKey: formsKeys.tags(filter),
  queryFn: () => gqlClient.request(tags, { filter }),
});

export function getTags(filter: FormsAdminTagsFilter) {
  return queryClient.fetchQuery(tagsQuery(filter));
}

export function useTags(filter: FormsAdminTagsFilter) {
  return useQuery({
    ...tagsQuery(filter),
    select: ({ formsAdmin_tags }) => formsAdmin_tags,
  });
}

export type ReturnTypeFromUseTags = UseQueryReturnType<typeof useTags>[number];

import type {
  Templating_ResolveTemplateInput,
  Templating_TemplateFilter,
  Templating_VariablesFilter,
} from '@tyro/api';

export const templatesKeys = {
  all: ['templates'] as const,
  variables: (filter: Templating_VariablesFilter) =>
    [...templatesKeys.all, 'variables', filter] as const,
  templates: (filter: Templating_TemplateFilter) =>
    [...templatesKeys.all, 'templates', filter] as const,
  resolveTemplates: (filter: Templating_ResolveTemplateInput) =>
    [...templatesKeys.all, 'resolveTemplates', filter] as const,
  schoolDetails: () => [...templatesKeys.all, 'schoolDetails'] as const,
};

import { useQuery } from '@tanstack/react-query';

import {
  type TtTimetableListFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';

import { timetableKeys } from '../keys';

const timetables = graphql(/* GraphQL */ `
  query tt_timetables($filter: TTTimetableListFilter) {
    tt_timetables(filter: $filter) {
      timetableId
      name
      created
      updatedAt
      liveTimetable
        constructionInfo {
            classGroupsPublished
            fullPublish
        }
      createdBy {
        person {
          partyId
          title {
            id
            name
            nameTextId
          }
          firstName
          lastName
          avatarUrl
          type
        }
      }
      updatedBy {
          person {
              partyId
              title {
                  id
                  name
                  nameTextId
              }
              firstName
              lastName
              avatarUrl
              type
          }
      }
      liveStatus {
        totalChanges
        lessonChanges
        timetableGroupChanges
        lastPublishedDate
      }
      
    }
  }
`);

const timeTablesQuery = (filter: TtTimetableListFilter) => ({
  queryKey: timetableKeys.timetableList(filter),
  queryFn: async () => gqlClient.request(timetables, { filter }),
});

export function getTimetables(filter: TtTimetableListFilter) {
  return queryClient.fetchQuery(timeTablesQuery(filter));
}

export function useTimetables(filter: TtTimetableListFilter) {
  return useQuery({
    ...timeTablesQuery(filter),
    select: ({ tt_timetables }) => {
      if (!Array.isArray(tt_timetables)) return [];
      return tt_timetables;
    },
  });
}

export type ReturnTypeFromUseTimeTables = UseQueryReturnType<
  typeof useTimetables
>[number];

import { useMutation } from '@tanstack/react-query';
import {
  type Forms_FormIdInput,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { formsKeys } from './keys';

const deleteFormSubmission = graphql(/* GraphQL */ `
  mutation forms_deleteFormSubmission($input: Forms_FormIdInput!) {
    forms_deleteFormSubmission(input: $input) {
      success
    }
  }
`);

export function useDeleteFormSubmission() {
  const { t } = useTranslation(['common']);
  const { toast } = useToast();

  return useMutation({
    mutationFn: (input: Forms_FormIdInput) =>
      gqlClient.request(deleteFormSubmission, { input }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: formsKeys.all });
      toast(t('common:snackbarMessages.deleteSuccess'));
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

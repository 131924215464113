import type {
  Reporting_TableFilter,
  Reporting_TableFilterInput,
} from '@tyro/api';
import { useMemo, useState } from 'react';

import type { InnerReportFilter } from '../api/keys';
import { useRunReports } from '../api/run-report';
import { DynamicForm } from '../components/dynamic-form';
import {
  ReportTableView,
  type ReportTableViewProps,
} from '../components/table-view';

type ReportWithFiltersProps = {
  topReportId: InnerReportFilter['topReportId'];
  reportFilter: InnerReportFilter['filter'] & {
    fixedFilters?: InnerReportFilter['filter']['filters'];
  };
  reportTableViewProps?: Partial<ReportTableViewProps>;
  customFilters?: Partial<Reporting_TableFilter & { hidden?: boolean }>[];
  onUpdateFilters?: (filters: Reporting_TableFilterInput[]) => void;
};

export function ReportWithFilters({
  topReportId,
  customFilters,
  reportFilter,
  reportTableViewProps,
  onUpdateFilters,
}: ReportWithFiltersProps) {
  const { fixedFilters, ...restReportFilter } = reportFilter;

  const [filters, setFilters] = useState([
    ...(fixedFilters || []),
    ...(restReportFilter.filters || []),
  ]);

  const [interactiveValues, setInteractiveValues] = useState<{
    metric?: string;
    groupings?: string[];
    timeGrouping?: string;
  }>({});

  const {
    data: reportData,
    isFetching,
    isLoading,
  } = useRunReports({
    topReportId,
    filter: {
      ...restReportFilter,
      filters,
      ...interactiveValues,
    },
  });

  const mappedFilterValues = useMemo(
    () =>
      reportData?.filters?.map((dataFilter) => {
        const filterValue = filters.find(
          (filter) => filter?.filterId === dataFilter.id,
        )?.filterValue;

        const currentFilter = customFilters?.find(
          (customFilter) => customFilter.id === dataFilter.id,
        );

        return {
          ...dataFilter,
          defaultValue: filterValue ?? dataFilter.defaultValue,
          ...currentFilter,
        };
      }),
    [filters, customFilters, reportData?.filters],
  );

  const updateValues = (newValues: {
    filters: Reporting_TableFilterInput[];
    metric?: string;
    groupings?: string[];
    timeGrouping?: string;
  }) => {
    const updatedFilters = [...(fixedFilters || []), ...newValues.filters];

    const uniqueFilters = Array.from(
      new Map(
        updatedFilters.flatMap((filter) =>
          filter ? [[filter?.filterId, filter]] : [],
        ),
      ).values(),
    );

    onUpdateFilters?.(uniqueFilters);
    setFilters(uniqueFilters);
    setInteractiveValues({
      metric: newValues.metric,
      groupings: newValues.groupings,
      timeGrouping: newValues.timeGrouping,
    });
  };

  return (
    <>
      <DynamicForm
        isFetching={isFetching}
        filters={mappedFilterValues ?? []}
        onValueChange={updateValues}
        sql={reportData?.debug?.sql}
        isInteractiveReport={!!reportData?.info.isInteractive}
        preFilterFields={{
          stats: reportData?.metrics,
        }}
        groupingFields={{
          groupBy: reportData?.groupBy,
          timeGroupBy: reportData?.timeGroupBy,
        }}
      />
      <ReportTableView
        isLoading={isLoading}
        reportData={reportData}
        {...reportTableViewProps}
      />
    </>
  );
}

import { Stack } from '@mui/material';
import { TemplatingContextType } from '@tyro/api';
import { RHFSelect, RHFTextField } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import type { Control } from 'react-hook-form';
import type { TemplateFormState } from './types';

type TemplateEditorFormFieldsProps = {
  control: Control<TemplateFormState>;
};

const contextValues = [
  TemplatingContextType.Student,
  TemplatingContextType.Staff,
];

export const TemplateEditorFormFields = ({
  control,
}: TemplateEditorFormFieldsProps) => {
  const { t } = useTranslation(['common', 'templates']);

  return (
    <Stack direction="row" spacing={1} mt={1} flexGrow={1}>
      <RHFTextField
        label={t('templates:nameOfTemplate')}
        textFieldProps={{
          sx: {
            maxWidth: 300,
            width: '50%',
            '& .MuiInputBase-input': {
              backgroundColor: '#FFF',
              borderRadius: 1,
            },
          },
          multiline: true,
        }}
        variant="white-filled-outlined"
        controlProps={{
          name: 'name',
          control,
        }}
      />

      <RHFSelect
        label={t('templates:contextOfTemplate')}
        fullWidth
        options={contextValues}
        getOptionLabel={(option) => t(`templates:context.${option}`)}
        variant="white-filled-outlined"
        controlProps={{
          name: 'context',
          control,
        }}
        sx={{
          maxWidth: 300,
          borderRadius: 1,
        }}
      />
    </Stack>
  );
};

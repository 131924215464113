import { useQuery } from '@tanstack/react-query';
import {
  type GradeSetFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { assessmentsKeys } from '../keys';

const gradeSets = graphql(/* GraphQL */ `
  query assessment_gradeSet($filter: GradeSetFilter) {
    assessment_gradeSet(filter: $filter) {
      id
      name
      description
      nameTextId
      active
      customGradeSet
      isCba
      grades {
        id
        name
        nameTextId
        start
        end
        active
      }
      years
    }
  }
`);

const gradeSetsQuery = (filter: GradeSetFilter) => ({
  queryKey: assessmentsKeys.gradeSets(filter),
  queryFn: () => gqlClient.request(gradeSets, { filter }),
});

export function getGradeSets(filter: GradeSetFilter) {
  return queryClient.fetchQuery(gradeSetsQuery(filter));
}

export function useGradeSets(filter: GradeSetFilter) {
  return useQuery({
    ...gradeSetsQuery(filter),
    select: ({ assessment_gradeSet }) => assessment_gradeSet ?? [],
  });
}

export type ReturnTypeFromUseGradeSets = UseQueryReturnType<
  typeof useGradeSets
>[number];

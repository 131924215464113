import { ConfirmDialog, RHFRadioGroup } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';

import { Calender_EventDeleteMode } from '@tyro/api';

import { useForm } from 'react-hook-form';
import { useDeleteCalendarEvents } from '../../../api/delete-events';
import type { ReturnTypeFromUseEventForEdit } from '../../../api/events-for-edit';

export interface DeleteCalendarEventConfirmModalProps {
  isOpen: boolean;
  isRecurringEvent?: boolean;
  event: ReturnTypeFromUseEventForEdit | null;
  deleteFromDate?: string;
  onClose: () => void;
}

const modeOptions = [
  Calender_EventDeleteMode.DeleteHistoric,
  Calender_EventDeleteMode.DeleteFromDate,
] as const;

type FormState = {
  mode: (typeof modeOptions)[number];
};

export function DeleteCalendarEventConfirmModal({
  isOpen,
  isRecurringEvent,
  deleteFromDate,
  event,
  onClose,
}: DeleteCalendarEventConfirmModalProps) {
  const { t } = useTranslation(['common', 'calendar']);

  const { mutateAsync: deleteEvent } = useDeleteCalendarEvents();

  const { control, handleSubmit } = useForm<FormState>({
    defaultValues: {
      mode: Calender_EventDeleteMode.DeleteHistoric,
    },
  });

  const onSubmit = handleSubmit(async ({ mode }) => {
    if (event) {
      await deleteEvent({
        mode,
        deleteByEventId: [event?.eventId],
        deleteBySource: [],
        ...(mode === Calender_EventDeleteMode.DeleteFromDate && {
          deleteFromDate,
        }),
      });
      onClose();
    }
  });

  return (
    <ConfirmDialog
      open={isOpen}
      onClose={onClose}
      onConfirm={onSubmit}
      title={
        isRecurringEvent
          ? t('calendar:deleteRecurringEvent')
          : t('calendar:deleteEvent')
      }
      content={
        isRecurringEvent ? (
          <RHFRadioGroup
            options={modeOptions.map((option) => ({
              value: option,
              label: t(`calendar:deleteEventMode.${option}`),
            }))}
            controlProps={{
              name: 'mode',
              control,
            }}
          />
        ) : (
          t('calendar:deleteEventConfirmation', {
            eventName: event?.name,
          })
        )
      }
      confirmText={t('common:actions.delete')}
      isDelete
    />
  );
}

import { useQuery } from '@tanstack/react-query';

import {
  type TtSolver_ScheduleFilter,
  type Ttsolve_ScheduleViewQuery,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useCallback } from 'react';
import { timetableKeys } from '../../keys';

const scheduleView = graphql(/* GraphQL */ `
  query ttsolve_scheduleView($filter: TtSolver_ScheduleFilter!) {
    ttsolve_scheduleView(filter: $filter) {
      byGroupTag {
        groupTagId
        groupTag {
          name
        }
        timeslots {
          id {
            gridIdx
            dayIdx
            periodIdx
          }
          info {
            dayOfWeek
            startTime
            endTime
            periodType
            name
          }
        }
        timetableGroups {
          timetableGroupId
          name
          subject {
            id
            name
            colour
            shortCode
          }
          groupType
          studentMembershipType
          groupTagIds
          spread
          lessonCount
          blockIdx
          blockId
          classGroupId
          teachers {
            person {
              partyId
              title {
                id
                nameTextId
                name
              }
              firstName
              lastName
              avatarUrl
              type
              archived
            }
            displayCode
          }
          room {
            roomId
            name
          }
          roomPool {
            id
            name
          }
          lessons {
            id {
              lessonIdx
              lessonInstanceIdx
              timetableGroupId
            }
            timeslotInfo {
              dayOfWeek
              startTime
              endTime
              periodType
              name
            }
            timeslotState
            teachers {
              person {
                partyId
                title {
                  id
                  nameTextId
                  name
                }
                firstName
                lastName
                avatarUrl
                type
                archived
              }
              displayCode
            }
            room {
              roomId
              name
            }
            roomPool {
              id
              name
            }
            spread
          }
        }
        rows {
          name
          id
          grid {
            timeslotId {
              gridIdx
              dayIdx
              periodIdx
            }
            lessonInstanceIds {
              lessonIdx
              lessonInstanceIdx
              timetableGroupId
            }
            included
          }
        }
      }
    }
  }
`);

const scheduleViewQuery = (filter: TtSolver_ScheduleFilter) => ({
  queryKey: timetableKeys.scheduleView(filter),
  queryFn: async () => gqlClient.request(scheduleView, { filter }),
});

export async function getScheduleView(filter: TtSolver_ScheduleFilter) {
  return queryClient.fetchQuery(scheduleViewQuery(filter));
}

export function useScheduleView(
  filter: TtSolver_ScheduleFilter,
  {
    enableRefetch = false,
    enabled = true,
  }: { enableRefetch?: boolean; enabled?: boolean } = {},
) {
  return useQuery({
    ...scheduleViewQuery(filter),
    enabled,
    select: useCallback(
      ({ ttsolve_scheduleView }: Ttsolve_ScheduleViewQuery) =>
        ttsolve_scheduleView,
      [],
    ),
    refetchInterval: enableRefetch ? 5000 : false,
  });
}

export type ReturnTypeFromUseScheduleView = UseQueryReturnType<
  typeof useScheduleView
>;

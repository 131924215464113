import { useQuery } from '@tanstack/react-query';
import {
  type Tt_GroupsFilter,
  type Tt_GroupsQuery,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { naturalSort } from '@tyro/core';
import { useCallback } from 'react';
import { timetableKeys } from '../keys';

const ttGroups = graphql(/* GraphQL */ `
  query tt_groups($filter: TT_GroupsFilter!) {
    tt_groups(filter: $filter) {
      __typename
      name
      timetableGroupId
      partyId
      subject {
        id
        name
        description
        shortCode
        nationalCode
        subjectSource
        examinable
        colour
        icon
        active
      }
      allowAutoTeacherSwaps
      groupType
      subjectId
      classGroupId
      blockId
      groupTagIds
      studentMembershipType
      teachers {
        person {
          partyId
          title {
            id
            nameTextId
            name
          }
          firstName
          lastName
          avatarUrl
          type
        }
      }
      room {
        roomId
        name
      }
      roomPool {
        id
        name
      }
      spread
      lessons {
        id {
          lessonIdx
          lessonInstanceIdx
          timetableGroupId
        }
        timeslotId {
          gridIdx
          dayIdx
          periodIdx
        }
        timeslotInfo {
          startTime
          endTime
        }
        timetableGroup {
          __typename
          timetableGroupId
          name
          subject {
            name
            colour
          }
        }
        room {
          roomId
          name
        }
        roomPool {
          id
          name
        }
        teachers {
          person {
            partyId
            title {
              id
              name
              nameTextId
            }
            firstName
            lastName
            avatarUrl
            type
          }
        }
        spread
      }
    }
  }
`);

const ttGroupQuery = (filter: Tt_GroupsFilter) => ({
  queryKey: timetableKeys.ttGroups(filter),
  queryFn: async () => {
    const { tt_groups: groups } = await gqlClient.request(ttGroups, { filter });

    return {
      tt_groups: groups.sort((a, b) => naturalSort(a.name, b.name)),
    };
  },
});

export function getTimetableGroups(filter: Tt_GroupsFilter) {
  return queryClient.fetchQuery(ttGroupQuery(filter));
}

export function useTimetableGroups(filter: Tt_GroupsFilter, enabled = true) {
  return useQuery({
    ...ttGroupQuery(filter),
    enabled: filter.timetableId !== 0 && enabled,
    select: useCallback(({ tt_groups }: Tt_GroupsQuery) => tt_groups, []),
  });
}

export type ReturnTypeFromUseTimetableGroups = UseQueryReturnType<
  typeof useTimetableGroups
>[number];

import type { RoomPoolFilter } from '@tyro/api';
import {
  Autocomplete,
  type AutocompleteProps,
  type CustomCellEditorProps,
  RHFAutocomplete,
  type RHFAutocompleteProps,
  TableAutocomplete,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import type { FieldValues } from 'react-hook-form';
import { useRoomPoolsSelect } from '../../api/rooms/room-pools';

export interface RoomPoolsSelect {
  id: number;
  name: string;
}

type RHFRoomPoolsAutocompleteProps<TField extends FieldValues> = Omit<
  RHFAutocompleteProps<TField, RoomPoolsSelect>,
  'options'
> & { filter?: RoomPoolFilter };

type RoomPoolsAutocompleteProps = Omit<
  AutocompleteProps<RoomPoolsSelect>,
  | 'optionIdKey'
  | 'optionTextKey'
  | 'getOptionLabel'
  | 'filterOptions'
  | 'renderAvatarTags'
  | 'renderAvatarOption'
  | 'renderAvatarAdornment'
> & { filter?: RoomPoolFilter };

export const RHFRoomPoolsAutocomplete = <TField extends FieldValues>({
  filter,
  ...props
}: RHFRoomPoolsAutocompleteProps<TField>) => {
  const { t } = useTranslation(['common']);
  const { data: roomPoolData, isLoading } = useRoomPoolsSelect(filter ?? {});

  return (
    <RHFAutocomplete<TField, RoomPoolsSelect>
      label={t('common:roomPool')}
      {...props}
      fullWidth
      optionIdKey="id"
      optionTextKey="name"
      loading={isLoading}
      options={roomPoolData ?? []}
    />
  );
};

export const RoomPoolsAutocomplete = ({
  filter,
  ...props
}: RoomPoolsAutocompleteProps) => {
  const { t } = useTranslation(['common']);
  const { data: roomPoolData, isLoading } = useRoomPoolsSelect(filter ?? {});

  return (
    <Autocomplete
      label={t('common:roomPool')}
      fullWidth
      optionIdKey="id"
      optionTextKey="name"
      loading={isLoading}
      {...props}
      options={roomPoolData ?? []}
    />
  );
};

export interface TableRoomAndPoolsAutocompleteProps
  extends CustomCellEditorProps<unknown, RoomPoolsSelect | RoomPoolsSelect[]> {
  multiple: boolean;
  filter?: RoomPoolFilter;
}

export function TableRoomPoolsAutocomplete(
  props: TableRoomAndPoolsAutocompleteProps,
) {
  const { t } = useTranslation(['common']);
  const { multiple, filter, ...restProps } = props;

  const { data: roomPoolData, isLoading } = useRoomPoolsSelect(filter ?? {});

  return (
    // @ts-expect-error
    <TableAutocomplete<RoomPoolsSelect | null>
      {...restProps}
      options={roomPoolData ?? []}
      getOptionLabel={(option) => option?.name ?? ''}
      optionIdKey="id"
      AutocompleteProps={{
        autoHighlight: true,
        loading: isLoading,
        loadingText: t('common:loading'),
        multiple,
      }}
    />
  );
}

import { useMutation } from '@tanstack/react-query';

import {
  type Calendar_EditEvents,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { calendarKeys } from './keys';

const editEvent = graphql(/* GraphQL */ `
  mutation calendar_editCalendarEvents($input: Calendar_EditEvents!) {
    calendar_editCalendarEvents(input: $input) {
      success
    }
  }
`);

export function useEditCalendarEvents() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationFn: async (input: Calendar_EditEvents) =>
      gqlClient.request(editEvent, { input }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: calendarKeys.all });
      toast(t('common:snackbarMessages.updateSuccess'));
    },
    onError: async () => {
      await queryClient.invalidateQueries({ queryKey: calendarKeys.all });
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

import { Chip } from '@mui/material';
import { type Search, SearchType, getColorBasedOnIndex } from '@tyro/api';
import { type AutocompleteProps, Avatar, useDebouncedValue } from '@tyro/core';
import { type TFunction, useTranslation } from '@tyro/i18n';
import { useMemo } from 'react';
import { useNoticeSearch } from '../api/notice-search';
import type { AssignedToParty } from '../utils/types';

export type NoticeParty = Pick<Search, 'partyId' | 'text' | 'avatarUrl'> & {
  type?: Search['type'];
};

export const getAdditionalOptions = (
  t: TFunction<['common', 'noticeBoard']>,
) => [
  {
    __typename: 'Staff',
    partyId: -1,
    text: t('noticeBoard:allStaff'),
    type: SearchType.Staff,
    avatarUrl: undefined,
  },
];

export const useNoticeBoardSearchProps = (
  customProps?: Partial<AutocompleteProps<NoticeParty, true>>,
): AutocompleteProps<NoticeParty, true> => {
  const { t } = useTranslation(['common', 'noticeBoard']);

  const {
    value: searchValue,
    setValue: setSearchValue,
    debouncedValue: debouncedSearchValue,
  } = useDebouncedValue({
    defaultValue: '',
  });

  const {
    data: options,
    isLoading,
    isFetching,
  } = useNoticeSearch(debouncedSearchValue);

  const groups = options?.groups ?? [];
  const people = options?.people ?? [];

  const customOptions = useMemo(
    () =>
      getAdditionalOptions(t).filter((option) =>
        option.text.toLowerCase().includes(debouncedSearchValue.toLowerCase()),
      ),
    [t, debouncedSearchValue],
  );

  const mergedOptions = [...customOptions, ...groups, ...people];

  return {
    label: t('noticeBoard:assignees'),
    optionIdKey: 'partyId',
    optionTextKey: 'text',
    multiple: true,
    open: searchValue.length > 0,
    filterSelectedOptions: true,
    clearOnBlur: true,
    filterOptions: (x) => x,
    freeSolo: true,
    loading: isLoading,
    options: mergedOptions ?? [],
    onInputChange: (_, newInputValue) => {
      return setSearchValue(newInputValue);
    },
    renderTags: (tags, getTagProps) => {
      return tags.map((tag, index) => {
        const { key, ...tagProps } = getTagProps({ index });
        return (
          <Chip
            key={key}
            size="small"
            variant="soft"
            color={getColorBasedOnIndex(index)}
            avatar={<Avatar name={tag.text ?? '-'} src={tag?.avatarUrl} />}
            label={tag.text}
            {...tagProps}
          />
        );
      });
    },
    renderAvatarOption: (option, renderOption) =>
      renderOption({
        name: option.text,
        src: option.avatarUrl ?? undefined,
        caption: option.type ? t(`common:searchType.${option.type}`) : '',
      }),
    ...customProps,
  };
};

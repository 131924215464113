import { Stack, Typography } from '@mui/material';
import { LineClampContainer, LinkRender } from '@tyro/core';
import type { ReturnTypeFromUseStudentAen } from '../../../api/student/aen/student-aen-data';

interface NTKAenProps {
  aen: ReturnTypeFromUseStudentAen[number]['entries'][number];
}

export function NTKAen({ aen: { type, typeNote, note } }: NTKAenProps) {
  const title = type ?? typeNote ?? note ?? '-';
  const description = typeNote ?? note ?? '-';

  return (
    <Stack
      sx={{
        mt: 2,
        px: 0.5,
      }}
    >
      {title && (
        <Typography variant="subtitle1" component="h4">
          {title}
        </Typography>
      )}
      <LineClampContainer numberOfLines={3} lineHeight={24} allowExpand>
        <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
          <LinkRender text={description} />
        </Typography>
      </LineClampContainer>
    </Stack>
  );
}

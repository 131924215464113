import { useQuery } from '@tanstack/react-query';

import {
  type AssessmentFilter,
  EmulateHeaders,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { assessmentsKeys } from '../keys';

const inClassAssessments = graphql(/* GraphQL */ `
  query inClassAssessments($filter: AssessmentFilter) {
    assessment_assessment(filter: $filter) {
      id
      name
      assessmentType
    }
  }
`);
interface AssessmentListFilter extends AssessmentFilter {
  academicNameSpaceId: number;
}

const inClassAssessmentsQuery = (filter: AssessmentListFilter) => ({
  queryKey: assessmentsKeys.assessments(filter),
  queryFn: () =>
    gqlClient.request(
      inClassAssessments,
      { filter },
      {
        [EmulateHeaders.ACADEMIC_NAMESPACE_ID]:
          filter.academicNameSpaceId.toString(),
      },
    ),
});

export function getInClassAssessmentsList(filter: AssessmentListFilter) {
  return queryClient.fetchQuery(inClassAssessmentsQuery(filter));
}

export function useInClassAssessmentsList(filter: AssessmentListFilter) {
  return useQuery({
    ...inClassAssessmentsQuery(filter),
    select: ({ assessment_assessment }) => {
      if (!Array.isArray(assessment_assessment)) return [];

      return assessment_assessment;
    },
  });
}

export type ReturnTypeFromUseInClassAssessments = UseQueryReturnType<
  typeof useInClassAssessmentsList
>[number];

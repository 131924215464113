import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import {
  ConfirmDialog,
  RHFTextField,
  getNumber,
  useDisclosure,
  useFormValidator,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { AddIcon, TrashIcon } from '@tyro/icons';
import { useFieldArray, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import type { ReturnTypeFromUseGradeSets } from '../../api/grade-sets';
import { useSaveGradeSet } from '../../api/grade-sets/save';

interface FormState {
  name: string;
  description: string;
  grades: {
    name: string;
    end: string | number;
  }[];
}

const defaultGrades = [
  {
    name: 'A',
    end: '100',
  },
  {
    name: 'B',
    end: '80',
  },
  {
    name: 'C',
    end: '70',
  },
  {
    name: 'D',
    end: '60',
  },
  {
    name: 'E',
    end: '50',
  },
  {
    name: 'F',
    end: '40',
  },
];

interface UpsertGradeSetFormProps {
  gradeSet?: ReturnTypeFromUseGradeSets;
}

export function UpsertGradeSetForm({ gradeSet }: UpsertGradeSetFormProps) {
  const { t } = useTranslation(['common', 'assessments']);
  const navigate = useNavigate();
  const {
    isOpen: isCancelModalOpen,
    onClose: onCancelModalClose,
    onOpen: onCancelModalOpen,
  } = useDisclosure();

  const { mutateAsync: saveGradeSet } = useSaveGradeSet();

  const { resolver, rules } = useFormValidator<FormState>();
  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitting },
  } = useForm<FormState>({
    defaultValues: {
      name: gradeSet?.name ?? '',
      description: gradeSet?.description ?? '',
      grades: gradeSet?.grades ?? defaultGrades,
    },
    resolver: resolver({
      name: rules.required(),
      description: rules.required(),
      grades: {
        name: rules.required(),
        end: [
          rules.required(),
          rules.isNumber(),
          rules.min(0),
          rules.max(100),
          rules.validate((value, throwError, formValues, fieldName) => {
            const valueAsNumber = getNumber(value) as number;
            const gradeIndex = Number(fieldName.split('.')[1]);

            if (fieldName === 'grades.0.end' && valueAsNumber !== 100) {
              throwError(t('assessments:theFirstGradeMustEndAt100%'));
            }

            if (fieldName !== 'grades.0.end') {
              const previousGradeEnd = getNumber(
                formValues.grades[gradeIndex - 1].end,
              );

              if (
                typeof previousGradeEnd === 'number' &&
                valueAsNumber >= previousGradeEnd
              ) {
                throwError(
                  t('assessments:gradeMustBeBeforePrevious', {
                    previousGrade: previousGradeEnd,
                  }),
                );
              }
            }
          }),
        ],
      },
    }),
  });

  const [controlledGrades] = watch(['grades']);

  const {
    fields: grades,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'grades',
  });

  const goBack = () => {
    navigate('/settings/grade-sets');
  };

  const handleCancelForm = () => {
    if (isDirty) {
      onCancelModalOpen();
    } else {
      goBack();
    }
  };

  const onSubmit = handleSubmit(({ name, description, grades }) => {
    const gradesForRequest = grades.map(({ name, end }, index) => {
      const nextGrade = grades[index + 1];
      let start = 0;

      if (nextGrade?.end && !Number.isNaN(Number(nextGrade.end))) {
        start = Number(nextGrade.end) + 1;
      }

      return {
        name: [
          {
            locale: 'en',
            value: name,
          },
        ],
        end: getNumber(end) as number,
        start,
      };
    });
    saveGradeSet(
      {
        id: gradeSet?.id,
        name: [
          {
            locale: 'en',
            value: name,
          },
        ],
        description: [
          {
            locale: 'en',
            value: description,
          },
        ],
        grades: gradesForRequest,
      },
      {
        onSuccess: goBack,
      },
    );
  });

  return (
    <>
      <Stack component="form" gap={3} onSubmit={onSubmit}>
        <Card variant="outlined">
          <CardHeader component="h2" title={t('common:details')} />
          <Stack gap={3} p={3}>
            <RHFTextField
              label={t('common:name')}
              controlProps={{
                name: 'name',
                control,
              }}
              textFieldProps={{
                fullWidth: true,
                sx: {
                  maxWidth: 400,
                  width: '100%',
                },
              }}
            />
            <RHFTextField
              label={t('common:description')}
              controlProps={{
                name: 'description',
                control,
              }}
              textFieldProps={{
                fullWidth: true,
                multiline: true,
                rows: 3,
                sx: {
                  maxWidth: 400,
                  width: '100%',
                },
              }}
            />
          </Stack>
        </Card>
        <Card variant="outlined">
          <CardHeader component="h2" title={t('assessments:grades')} />
          <Stack gap={3} p={3}>
            <Typography
              variant="body1"
              component="h3"
              color="text.secondary"
              fontWeight={600}
            >
              {t('assessments:gradeSetCreateGradesDescription')}
            </Typography>
            <Stack spacing={1}>
              {grades.map((grade, index) => {
                const nextGrade = controlledGrades[index + 1];
                const thisGrade = controlledGrades[index];
                const endOfRange =
                  thisGrade?.end && !Number.isNaN(Number(thisGrade.end))
                    ? `${thisGrade.end}%`
                    : 'TBD';

                let startOfRange = 'TBD';

                if (!nextGrade) {
                  startOfRange = '0%';
                } else if (
                  nextGrade?.end &&
                  !Number.isNaN(Number(nextGrade.end))
                ) {
                  startOfRange = `${Number(nextGrade.end) + 1}%`;
                }

                return (
                  <Card
                    key={grade.id}
                    variant="soft"
                    sx={{
                      p: 1,
                      boxShadow: 'none',
                      display: 'flex',
                      gap: 2,
                      borderRadius: 1,
                    }}
                  >
                    <RHFTextField
                      label={t('common:name')}
                      controlProps={{
                        name: `grades.${index}.name`,
                        control,
                      }}
                      variant="white-filled-outlined"
                      textFieldProps={{
                        fullWidth: true,
                        sx: { flex: 1 },
                      }}
                    />
                    <RHFTextField
                      label={t('assessments:percent')}
                      controlProps={{
                        name: `grades.${index}.end`,
                        control,
                      }}
                      variant="white-filled-outlined"
                      textFieldProps={{
                        fullWidth: true,
                        disabled: index === 0,
                        sx: { flex: 1, maxWidth: 200 },
                      }}
                    />
                    <Stack gap={1} sx={{ minWidth: 100 }}>
                      <Typography variant="body1" component="h3">
                        {t('assessments:range')}
                      </Typography>
                      <Typography variant="body2" component="p">
                        {startOfRange} - {endOfRange}
                      </Typography>
                    </Stack>
                    <IconButton
                      color="primary"
                      aria-label={t('assessments:deleteGrade')}
                      onClick={() => remove(index)}
                      disabled={index === 0}
                      sx={{
                        width: 40,
                        height: 40,
                        alignSelf: 'center',
                      }}
                    >
                      <TrashIcon />
                    </IconButton>
                  </Card>
                );
              })}
              <Stack width="fit-content">
                <Button
                  size="small"
                  color="primary"
                  variant="text"
                  onClick={() =>
                    append({
                      name: '',
                      end: '0',
                    })
                  }
                  startIcon={<AddIcon sx={{ width: 24, height: 24 }} />}
                >
                  {t('assessments:addGrade')}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Card>
        <Stack direction="row" gap={2} justifyContent="flex-end">
          <Button
            variant="soft"
            size="large"
            color="primary"
            onClick={handleCancelForm}
          >
            {t('common:actions.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            size="large"
            type="submit"
            loading={isSubmitting}
          >
            {t('common:actions.save')}
          </LoadingButton>
        </Stack>
      </Stack>
      <ConfirmDialog
        open={isCancelModalOpen}
        title={t('common:cancelConfirmDialog.title')}
        description={t('common:cancelConfirmDialog.description')}
        onClose={onCancelModalClose}
        onConfirm={goBack}
      />
    </>
  );
}

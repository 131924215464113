import { useQuery } from '@tanstack/react-query';
import {
  type FormsAdmin_InformationRequestFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { formsKeys } from './keys';

const formRequests = graphql(/* GraphQL */ `
  query formsAdmin_informationRequest($filter: FormsAdmin_InformationRequestFilter) {
    formsAdmin_informationRequest(filter: $filter) {
      id {
        id
        provider
      }
      formId {
        id
        provider
      }
      name
      description
      requestAboutParties {
        partyId
        avatarUrl
        firstName
        lastName
        type
      }
      informationRequestType
      stats {
        responseExpected
        responseCount
        responseCompleteCount
        responseConfirmationCount
        responseRejectedCount
      }
      dueDate
      publishDate
      tags {
        id
        name
        systemTag
      }
      links {
        id
        type
      }
      currentlyVisibleForResponders
      metaConfigType
      metaConfig {
        type
        ... on Timetable_OnCallAssignerOptions {
          type
          timetableId
          periodsToChooseFrom
          maxPeriodsTeacherCanGet
          requiredTeachersPerPeriod
          dueDate
          publishDate
          excludeTeachers
        }
      }
    }
  }
`);

const formRequestsQuery = (filter: FormsAdmin_InformationRequestFilter) => ({
  queryKey: formsKeys.formRequests(filter),
  queryFn: () => gqlClient.request(formRequests, { filter }),
});

export function getFormRequests(filter: FormsAdmin_InformationRequestFilter) {
  return queryClient.fetchQuery(formRequestsQuery(filter));
}

export function useFormRequests(filter: FormsAdmin_InformationRequestFilter) {
  return useQuery({
    ...formRequestsQuery(filter),
    select: ({ formsAdmin_informationRequest }) =>
      formsAdmin_informationRequest,
  });
}

export type ReturnTypeFromUseFormRequests = UseQueryReturnType<
  typeof useFormRequests
>[number];

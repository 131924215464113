import {
  Box,
  Stack,
  Tooltip,
  type TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { AttendanceCodeType } from '@tyro/api';
import { getColourBasedOnAttendanceType } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import {
  CheckmarkCircleWithPencilIcon,
  CheckmarkIcon,
  ClockIcon,
  ClockWithPencilIcon,
  CloseCircleWithPencilIcon,
  CloseCircleWithWarningIcon,
  CloseIcon,
} from '@tyro/icons';
import { TinyPencilIcon } from './tiny-pencil-icon';

interface RollbookAttendanceValueProps {
  view: 'icons' | 'codes';
  attendanceCodeType: AttendanceCodeType;
  code: string;
  note?: string | null | undefined;
  customTooltip?: string | JSX.Element;
  includedInFilter: boolean;
}

export const iconBasedOnCodeType = {
  [AttendanceCodeType.Present]: <CheckmarkIcon />,
  [AttendanceCodeType.ExplainedAbsence]: <CloseIcon />,
  [AttendanceCodeType.Late]: <ClockIcon />,
  [AttendanceCodeType.UnexplainedAbsence]: <CloseCircleWithWarningIcon />,
} as const;

const iconWithNoteBasedOnCodeType = {
  [AttendanceCodeType.Present]: <CheckmarkCircleWithPencilIcon />,
  [AttendanceCodeType.ExplainedAbsence]: <CloseCircleWithPencilIcon />,
  [AttendanceCodeType.Late]: <ClockWithPencilIcon />,
  [AttendanceCodeType.UnexplainedAbsence]: <CloseCircleWithWarningIcon />,
} as const;

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.customShadows.card,
    color: theme.palette.text.primary,
    maxWidth: 'none',
  },
}));

export function RollbookAttendanceValue({
  attendanceCodeType,
  view,
  code,
  note,
  customTooltip,
  includedInFilter,
}: RollbookAttendanceValueProps) {
  const { t } = useTranslation(['attendance']);
  if (attendanceCodeType === AttendanceCodeType.NotTaken) return null;
  let TooltipComponent: typeof Tooltip | typeof LightTooltip = Tooltip;

  const hasNote = !!note;

  const { color } = getColourBasedOnAttendanceType(attendanceCodeType).soft;

  if (view === 'codes') {
    let tooltip: JSX.Element | string | undefined = hasNote ? (
      <Typography variant="caption" color="inherit">
        {t('attendance:note')}: {note}
      </Typography>
    ) : undefined;

    if (customTooltip) {
      tooltip = customTooltip;
      TooltipComponent =
        typeof customTooltip !== 'string' ? LightTooltip : Tooltip;
    }

    return (
      <TooltipComponent title={tooltip} followCursor enterDelay={1000}>
        <Box
          component="span"
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
            color: includedInFilter ? color : 'text.disabled',
            opacity: includedInFilter ? 1 : 0.2,
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {code}
          {hasNote && (
            <TinyPencilIcon
              sx={{
                width: 10,
                height: 10,
                position: 'absolute',
                right: -10,
                top: 'calc(50% - 15px)',
              }}
            />
          )}
        </Box>
      </TooltipComponent>
    );
  }

  const icons = hasNote ? iconWithNoteBasedOnCodeType : iconBasedOnCodeType;
  const icon = icons[attendanceCodeType];
  let tooltip: JSX.Element | string | undefined = (
    <Stack>
      <Box component="span" fontWeight="bold">
        {code}
      </Box>
      {hasNote && (
        <Box component="span">
          {t('attendance:note')}: {note}
        </Box>
      )}
    </Stack>
  );

  if (customTooltip) {
    tooltip = customTooltip;
    TooltipComponent =
      typeof customTooltip !== 'string' ? LightTooltip : Tooltip;
  }

  return (
    <TooltipComponent title={tooltip} followCursor enterDelay={1000}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          color: includedInFilter ? color : 'text.disabled',
          opacity: includedInFilter ? 1 : 0.2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {icon}
      </Box>
    </TooltipComponent>
  );
}

import { Box, type BoxProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export function Logo({ disabledLink = false, sx }: Props) {
  const logo = (
    <Box
      sx={{
        width: 80,
        height: 80,
        '& svg': {
          width: '100%',
          height: '100%',
        },
        ...sx,
      }}
    >
      <svg fill="none" viewBox="0 0 464 196" xmlns="http://www.w3.org/2000/svg">
        <title>Tyro</title>
        <g fill="#141442">
          <path d="m244.088 152.674v17.25h-13.879c-15.862 0-25.578-9.715-25.578-25.578v-30.534h-13.086v-4.164l28.354-30.1385h3.767v18.8364h19.828v15.6641h-19.432v27.56c0 6.94 3.966 10.906 11.104 10.906h8.922z" />
          <path d="m328.753 97.9495-31.328 70.9835c-8.526 19.431-16.258 26.767-32.319 26.767h-7.931v-17.448h6.741c9.319 0 11.699-2.578 16.259-13.285l.198-.595-30.534-66.4225h21.215l19.233 44.2155 17.845-44.2155z" />
          <path d="m380.504 97.553v18.242h-8.526c-10.906 0-15.268 4.758-15.268 16.655v37.276h-19.431v-71.7765h12.492l3.37 8.7245c5.155-6.345 11.699-9.121 20.819-9.121z" />
          <path d="m385.659 134.036c0-21.811 16.655-38.0694 38.862-38.0694s38.863 16.2584 38.863 38.0694c0 21.612-16.656 37.871-38.863 37.871s-38.862-16.259-38.862-37.871zm57.897 0c0-11.699-7.931-19.63-19.233-19.63-11.104 0-19.233 8.13-19.233 19.63 0 11.698 7.931 19.629 19.233 19.629s19.233-8.129 19.233-19.629z" />
        </g>
        <path
          d="m285.132 0c-46.199 0-89.622 22.6036-116.191 60.4747h84.268l42.432-60.4747z"
          fill="#ffba08"
        />
        <path d="m126.51 0-64.4404 60.4747h22.0088z" fill="#0404b2" />
        <path
          d="m43.4314 0-42.4314 60.4747h61.0695l64.4405-60.4747z"
          fill="#00f"
        />
        <path
          d="m168.743 60.4747-42.233-60.4747-42.4316 60.4747-42.2332 60.4743 42.2332 60.475 84.6646-120.7511z"
          fill="#0092ff"
        />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}

import { useMutation, useQuery } from '@tanstack/react-query';
import {
  type Catalogue_GroupTagFilter,
  type Catalogue_UpsertGroupTag,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { groupsKeys } from '../keys';

const catalogueGroupTags = graphql(/* GraphQL */ `
  query catalogue_groupTags($filter: Catalogue_GroupTagFilter!) {
    catalogue_groupTags(filter: $filter) {
      id
      type
      uniqueName
      systemTag
      name
      colour
      refId
    }
  }
`);

const upsertCatalogueGroupTags = graphql(/* GraphQL */ `
  mutation catalogue_upsertGroupTag($input: [Catalogue_UpsertGroupTag!]!) {
    catalogue_upsertGroupTag(input: $input) {
      success
      message
    }
  }
`);

const catalogueGroupTagsQuery = (filter: Catalogue_GroupTagFilter) => ({
  queryKey: groupsKeys.tags(filter),
  queryFn: async () => {
    const { catalogue_groupTags: groupTags } = await gqlClient.request(
      catalogueGroupTags,
      { filter },
    );
    return {
      catalogue_groupTags: groupTags.sort((a, b) =>
        a.name.localeCompare(b.name),
      ),
    };
  },
});

export function getCatalogueGroupTags(filter: Catalogue_GroupTagFilter) {
  return queryClient.fetchQuery(catalogueGroupTagsQuery(filter));
}

export function useCatalogueGroupTags(filter: Catalogue_GroupTagFilter) {
  return useQuery({
    ...catalogueGroupTagsQuery(filter),
    select: ({ catalogue_groupTags }) => catalogue_groupTags,
  });
}

export function useUpsertCatalogueGroupTags() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationFn: (input: Catalogue_UpsertGroupTag[]) =>
      gqlClient.request(upsertCatalogueGroupTags, { input }),
    onSuccess: async (_, input) => {
      await queryClient.invalidateQueries({ queryKey: groupsKeys.all });

      const isUpdate = input.some((i) => i.id);
      toast(
        t(
          isUpdate
            ? 'common:snackbarMessages.updateSuccess'
            : 'common:snackbarMessages.createSuccess',
        ),
      );
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), {
        variant: 'error',
      });
    },
  });
}

export type ReturnTypeOfUseCatalogueGroupTags = UseQueryReturnType<
  typeof useCatalogueGroupTags
>[number];

import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  Context,
  SearchSource,
  SearchType,
  type UseQueryReturnType,
  gqlClient,
  graphql,
} from '@tyro/api';
import { noticeBoardKeys } from './keys';

const noticeSearch = graphql(/* GraphQL */ `
  query searchQuery($filter: SearchFilter) {
    search_search(filter: $filter) {
      partyId
      type
      text
      avatarUrl
      meta {
        studentPartyId
      }
    }
  }
`);

export function useNoticeSearch(query: string) {
  const trimmedQuery = query.trim();

  return useQuery({
    queryKey: noticeBoardKeys.search(query),
    queryFn: async () =>
      gqlClient.request(noticeSearch, {
        filter: {
          text: trimmedQuery,
          context: [Context.Calendar],
          source: SearchSource.Dropdown,
          includeSearchType: [
            SearchType.Staff,
            SearchType.GeneralGroup,
            SearchType.SubjectGroup,
            SearchType.YearGroupEnrollment,
            SearchType.CustomGroup,
          ],
        },
      }),
    enabled: trimmedQuery.length > 0,
    placeholderData: keepPreviousData,
    select: ({ search_search }) => ({
      hasResults: Array.isArray(search_search) && search_search?.length > 0,
      people: search_search.filter(
        ({ type }) => type === SearchType.Student || type === SearchType.Staff,
      ),
      groups: search_search.filter(
        ({ type }) => type !== SearchType.Student && type !== SearchType.Staff,
      ),
    }),
  });
}

export type ReturnTypeFromUseNoticeSearch = UseQueryReturnType<
  typeof useNoticeSearch
>;

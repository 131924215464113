import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Context, type SearchFilter, gqlClient, graphql } from '@tyro/api';
import { peopleCommonKeys } from './keys';

const partySearch = graphql(/* GraphQL */ `
  query calendarSearchQuery($filter: SearchFilter) {
    search_search(filter: $filter) {
      partyId
      type
      text
      avatarUrl
    }
  }
`);

export function usePartySearch(query: string, filter?: SearchFilter) {
  const trimmedQuery = query.trim();

  return useQuery({
    queryKey: peopleCommonKeys.partySearch(trimmedQuery),
    queryFn: async () =>
      gqlClient.request(partySearch, {
        filter: { text: trimmedQuery, context: [Context.Party], ...filter },
      }),
    enabled: trimmedQuery.length > 0,
    placeholderData: keepPreviousData,
    select: ({ search_search }) => search_search,
  });
}

import { Stack } from '@mui/material';
import {
  IconButtonWithTooltip,
  getFileSizeLabel,
  useFileErrorMessage,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { AttachmentIcon } from '@tyro/icons';
import { useDropzone } from 'react-dropzone';
import type { AttachedFilesListProps } from './list';

type AttachFilesProps = Pick<AttachedFilesListProps, 'setAttachFiles'>;

const maxFileSizeInKiloBytes = 1024 * 10;

export function AttachFiles({ setAttachFiles }: AttachFilesProps) {
  const { t } = useTranslation(['common', 'mail']);

  const { showErrorMessage } = useFileErrorMessage();
  const maxFileSizeLabel = getFileSizeLabel(maxFileSizeInKiloBytes);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        ['.pptx'],
      'application/vnd.ms-powerpoint': ['.ppt'],
      'text/csv': ['.csv'],
      'text/plain': ['.txt'],
      'image/svg+xml': ['.svg'],
      'image/jpeg': ['.jpeg', '.jpg', '.png'],
    },
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        const { code } = rejectedFiles[0].errors[0];
        showErrorMessage(code, { size: maxFileSizeLabel });
        return;
      }

      for (const currentFile of acceptedFiles) {
        const fileUrl = URL.createObjectURL(currentFile);

        setAttachFiles((currentFiles) => {
          currentFiles.set(fileUrl, Object.assign(currentFile, { fileUrl }));
          return new Map(currentFiles);
        });
      }
    },
    multiple: true,
    maxSize: maxFileSizeInKiloBytes * 1024,
    onError: console.error,
  });

  return (
    <Stack {...getRootProps()}>
      <input {...getInputProps()} />
      <IconButtonWithTooltip
        title={t('mail:tooltipTitles.attachFiles')}
        placement="top"
        onClick={open}
      >
        <AttachmentIcon />
      </IconButtonWithTooltip>
    </Stack>
  );
}

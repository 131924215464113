import {
  Button,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from '@mui/material';
import { Chip } from '@mui/material';
import { getColorBasedOnIndex } from '@tyro/api';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  PlaceholderCard,
  SearchInput,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useEffect, useMemo, useState } from 'react';
import {
  type ReturnTypeFromUseFormDefinitions,
  useFormDefinitions,
} from '../../api/forms-definitions';

type AddFormModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (form: ReturnTypeFromUseFormDefinitions) => void;
};

export function AddFormModal({ isOpen, onSelect, onClose }: AddFormModalProps) {
  const { t } = useTranslation(['common', 'forms']);

  const [searchForm, setSearchForm] = useState('');

  const { data: formsData = [], isLoading } = useFormDefinitions({
    ids: [],
    isReusableTemplate: true,
  });

  const filteredForm = useMemo(() => {
    const lowerCaseSearch = searchForm.toLowerCase();
    return formsData.filter((form) =>
      form.title.toLowerCase().includes(lowerCaseSearch),
    );
  }, [searchForm, formsData]);

  useEffect(() => {
    if (isOpen) {
      setSearchForm('');
    }
  }, [isOpen]);

  const hasFormToAdd = formsData.length > 0;
  const hasFormFound = filteredForm.length > 0;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll="paper"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle onClose={onClose}>{t('forms:addForm')}</DialogTitle>
      <DialogContent>
        <Stack gap={2} mt={1}>
          <Typography variant="body1">
            {t('forms:addFormDescription')}
          </Typography>
          {hasFormToAdd && (
            <SearchInput
              value={searchForm}
              onChange={(e) => setSearchForm(e.target.value)}
              size="small"
              containerProps={{ width: '100%' }}
            />
          )}

          {!hasFormToAdd && (
            <PlaceholderCard
              cardProps={{
                sx: { boxShadow: 'none', p: 0 },
              }}
            >
              <Typography component="h4" variant="body1" color="primary">
                {t('forms:noFormsToShow')}
              </Typography>
            </PlaceholderCard>
          )}

          {hasFormToAdd && !hasFormFound && searchForm.length > 0 && (
            <PlaceholderCard
              cardProps={{
                sx: { boxShadow: 'none', p: 0 },
              }}
            >
              <Typography component="h4" variant="body1" color="primary">
                {t('forms:noFormsFound')}
              </Typography>
            </PlaceholderCard>
          )}

          {hasFormFound && (
            <List>
              {filteredForm.map((form) => {
                const formId = form.id.id ?? 0;

                return (
                  <ListItem
                    key={formId}
                    disablePadding
                    sx={{
                      '& .MuiButtonBase-root': {
                        borderRadius: 1,
                      },
                    }}
                  >
                    <ListItemButton onClick={() => onSelect(form)}>
                      <Stack>
                        <Stack direction={'row'} gap={1}>
                          <Typography>{form.title}</Typography>
                          {(form.tags || []).map((tag) => (
                            <Chip
                              key={tag.id}
                              size="small"
                              variant="soft"
                              color={getColorBasedOnIndex(tag.id)}
                              label={tag.name}
                            />
                          ))}
                        </Stack>
                        <Typography variant="caption">
                          {form.description}
                        </Typography>
                      </Stack>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="inherit"
          disabled={isLoading}
          onClick={onClose}
        >
          {t('common:actions.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { useQuery } from '@tanstack/react-query';
import {
  type QueryAdditionalOptions,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { adminKeys } from './keys';

const adminPartyPeopleByTenantId = graphql(/* GraphQL */ `
  query users_admin__party_people($tenant: Int!) {
    users_admin__party_people(tenant: $tenant) {
      account {
        tenant
        tenantName
        partyId
        firstName
        lastName
        type
        avatarUrl
        primaryEmail {
          email
        }
      }
      otherAccounts {
        tenant
        tenantName
        partyId
        firstName
        lastName
        type
        avatarUrl
        primaryEmail {
          email
        }
      }
    }
  }
`);

const adminPartyPeopleQuery = (tenantId: number) => ({
  queryKey: adminKeys.people(tenantId),
  queryFn: async () =>
    gqlClient.request(adminPartyPeopleByTenantId, { tenant: tenantId }),
});

export function getAdminPartyPeople(tenantId: number | undefined) {
  return queryClient.fetchQuery(adminPartyPeopleQuery(tenantId ?? 0));
}

export function useAdminPartyPeopleByTenantId(
  tenantId: number,
  { enabled = true }: Omit<QueryAdditionalOptions, 'academicNamespaceId'> = {},
) {
  return useQuery({
    ...adminPartyPeopleQuery(tenantId),
    enabled,
    select: ({ users_admin__party_people }) => users_admin__party_people,
  });
}

export type ReturnTypeFromUseAdminPartyPeopleByTenantId = UseQueryReturnType<
  typeof useAdminPartyPeopleByTenantId
>[number];

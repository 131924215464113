import { Box, Divider, Stack, Typography } from '@mui/material';
import { LineClampContainer, LinkRender } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { Fragment } from 'react';
import type { ReturnTypeFromUseStudentMedicalConditions } from '../../../api/student/medicals/student-medical-data';

interface NTKConditionProps {
  condition: ReturnTypeFromUseStudentMedicalConditions[number];
}

export function NTKCondition({
  condition: { name, description, emergencyPlan, equipment },
}: NTKConditionProps) {
  const { t } = useTranslation(['people']);
  return (
    <Stack
      sx={{
        mt: 2,
        px: 0.5,
      }}
    >
      {name && (
        <Typography variant="subtitle1" component="h4">
          {name}
        </Typography>
      )}
      {description && (
        <LineClampContainer numberOfLines={3} lineHeight={24} allowExpand>
          <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
            <LinkRender text={description} />
          </Typography>
        </LineClampContainer>
      )}
      {equipment.length > 0 && (
        <Stack
          component="dl"
          sx={{
            m: 0,
            '& > hr': {
              my: 1,
            },
            '& > span': {
              display: 'flex',
              dt: {
                mr: 0.5,
              },
            },
          }}
        >
          {equipment.map(({ name: equipmentName, location }) => (
            <Fragment key={`${equipmentName}-${location}`}>
              <Typography
                component="span"
                variant="body1"
                sx={{ wordBreak: 'break-word' }}
              >
                <Box component="dt">{t('people:equipment')}:</Box>{' '}
                <LinkRender text={equipmentName ?? '-'} />
              </Typography>
              <Typography
                component="span"
                variant="body1"
                sx={{ wordBreak: 'break-word' }}
              >
                <Box component="dt">{t('people:location')}:</Box>{' '}
                <LinkRender text={location ?? '-'} />
              </Typography>
            </Fragment>
          ))}
        </Stack>
      )}
    </Stack>
  );
}

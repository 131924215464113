import type {
  Rules_RulesViewFilter,
  Rules_TriggeredViewFilter,
} from '@tyro/api';

export const rulesKeys = {
  all: ['rules'] as const,
  rulesList: (filter: Rules_RulesViewFilter) => [
    ...rulesKeys.all,
    'rulesList',
    filter,
  ],
  ruleDetails: (filter: Rules_RulesViewFilter) => [
    ...rulesKeys.all,
    'ruleDetails',
    filter,
  ],
  rulesMeta: () => [...rulesKeys.all, 'rulesMeta'],
  actionMeta: () => [...rulesKeys.all, 'actionMeta'],
  viewTriggered: (filter: Rules_TriggeredViewFilter) => [
    ...rulesKeys.all,
    'viewTriggered',
    filter,
  ],
};

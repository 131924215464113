import { IconButton, Stack, Typography, alpha, useTheme } from '@mui/material';
import { Colour, FileTransferFeature, usePermissions } from '@tyro/api';
import {
  Dialog,
  DialogCloseButton,
  DialogContent,
  LoadingPlaceholder,
  mixHexColors,
  usePreferredNameLayout,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import {
  CameraIcon,
  FolderDotIcon,
  MedicalCardPatientIcon,
  NotebookIcon,
} from '@tyro/icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNotes } from '../../../api/note/list';
import { useStudentAenData } from '../../../api/student/aen/student-aen-data';
import { useStudentMedicalConditions } from '../../../api/student/medicals/student-medical-data';
import { useStudent } from '../../../api/student/students';
import { useStudentActions } from '../../../store/student-actions';
import { StudentAvatarPicture } from '../student-avatar/student-avatar-picture';
import { NeedToKnowActions } from './actions';
import { NTKAen } from './aen';
import { BottomTabs, type BottomTabsProps } from './bottom-tabs';
import { NTKCondition } from './condition';
import { NTKContainer } from './container';
import { NTKNote } from './notes';
import { PersonalInfoCard } from './personal-info';

type ResponseError = {
  response: Response;
} | null;

interface NeedToKnowModalProps {
  id: string;
  studentPartySettings: { partyId: number; groupId?: number } | undefined;
  open: boolean;
  onClose: () => void;
}

const needToKnowTabs = [
  {
    key: 'needToKnow',
    name: 'Need to know',
  },
  {
    key: 'personalInfo',
    name: 'Personal information',
  },
];

export function NeedToKnowModal({
  id,
  studentPartySettings,
  open,
  onClose,
}: NeedToKnowModalProps) {
  const { partyId: studentPartyId = 0, groupId } = studentPartySettings ?? {};
  const { t } = useTranslation(['common', 'people']);
  const { displayName } = usePreferredNameLayout();
  const { isStaffUserWithPermission } = usePermissions();
  const { palette, customShadows } = useTheme();
  const [currentTab, setCurrentTab] = useState<'needToKnow' | 'personalInfo'>(
    'needToKnow',
  );

  const { openNeedToKnowForStudent, getAvatarUploadButtonProps } =
    useStudentActions();
  const { data: student, isLoading: isStudentLoading } = useStudent(
    studentPartyId,
    open,
  );
  const {
    data: priorityNotes = [],
    error: notesError,
    isLoading: isNotesLoading,
  } = useNotes(
    {
      partyIds: [studentPartyId],
      priority: true,
    },
    open,
  );
  const {
    data: aenData = [],
    error: aenError,
    isLoading: isAenLoading,
  } = useStudentAenData(
    { studentPartyIds: [studentPartyId], active: true },
    open,
  );

  const {
    data: medicalConditions = [],
    error: medicalError,
    isLoading: isMedicalLoading,
  } = useStudentMedicalConditions(studentPartyId, { enabled: open });
  const aenEntries = aenData?.[0]?.entries ?? [];

  const name = displayName(student?.person);
  const isLoading =
    isStudentLoading || isNotesLoading || isAenLoading || isMedicalLoading;
  const hasPriorityNotesAccess =
    (notesError as ResponseError)?.response?.status !== 403;
  const hasAenAccess = (aenError as ResponseError)?.response?.status !== 403;
  const hasMedicalAccess =
    (medicalError as ResponseError)?.response?.status !== 403;

  const tabs = useMemo(() => {
    const values: BottomTabsProps['tabs'] = [];

    if (hasPriorityNotesAccess || hasAenAccess || hasMedicalAccess) {
      values.push({
        key: 'needToKnow',
        name: t('people:needToKnow'),
      });
    }

    values.push({
      key: 'personalInfo',
      name: t('people:personalDetails'),
    });

    return values;
  }, [hasPriorityNotesAccess, hasAenAccess, hasMedicalAccess]);

  const reopenNeedToKnow = useCallback(() => {
    openNeedToKnowForStudent(studentPartyId, groupId);
  }, [studentPartyId, groupId]);

  useEffect(() => {
    const hasTab = tabs.some(({ key }) => key === currentTab);

    if (!hasTab && tabs[0]) {
      setCurrentTab(tabs[0].key as 'needToKnow' | 'personalInfo');
    }
  }, [tabs]);

  useEffect(() => {
    if (studentPartyId === 0) {
      setCurrentTab(tabs[0].key as 'needToKnow' | 'personalInfo');
    }
  }, [studentPartyId]);

  const canUploadStudentAvatar = isStaffUserWithPermission(
    'ps:1:documents:write_student_photos',
  );

  return (
    <Dialog
      id={id}
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          height: 'calc(100vh - 32px)',
          maxHeight: 920,
        },
      }}
    >
      {isLoading ? (
        <LoadingPlaceholder sx={{ height: '100%' }} />
      ) : (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={3}
            sx={{
              position: 'sticky',
              top: 0,
              p: 2,
              backgroundColor: alpha(palette.indigo[100], 0.1),
              boxShadow: customShadows.z1,
            }}
          >
            <Stack direction="row" alignItems="center" spacing={1.5}>
              <IconButton
                disabled={!canUploadStudentAvatar}
                aria-label={t('common:changeAvatarForName', {
                  name,
                })}
                sx={{
                  padding: 0,
                  '& .image-upload-icon': {
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: alpha(palette.slate['800'], 0.4),
                    backdropFilter: 'blur(1px)',
                    zIndex: 1,
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    opacity: 0,
                    transition: 'opacity 0.15s ease-in-out',

                    '& svg': {
                      color: palette.common.white,
                      width: 32,
                      height: 32,
                    },
                  },
                  '&:hover, &:focus': {
                    backgroundColor: 'transparent',
                    '& .image-upload-icon': {
                      opacity: 1,
                    },
                  },
                }}
                {...getAvatarUploadButtonProps(
                  {
                    partyId: studentPartyId,
                    uploadType: FileTransferFeature.StudentPhotos,
                  },
                  {
                    onClick: onClose,
                    onClose: reopenNeedToKnow,
                  },
                )}
              >
                {canUploadStudentAvatar && (
                  <div className="image-upload-icon">
                    <CameraIcon />
                  </div>
                )}
                <StudentAvatarPicture
                  name={name}
                  src={student?.person?.avatarUrl}
                  isPriorityStudent={!!student?.extensions?.priority}
                  hasSupportPlan={!!student?.extensions?.aen}
                  hasMedical={!!student?.extensions?.medical}
                  size={68}
                  person={student?.person}
                />
              </IconButton>
              <Stack>
                <Typography component="h2" variant="h4">
                  {name}
                </Typography>
                <Typography variant="body1">
                  {student?.classGroup?.name}
                </Typography>
              </Stack>
            </Stack>
            <DialogCloseButton
              onClick={onClose}
              sx={{ mt: '6px !important' }}
            />
          </Stack>
          <DialogContent
            sx={{
              pt: 1,
              px: 2,
              pb: 6,
              backgroundColor: mixHexColors(palette.indigo[50], '#fff', 0.3),
            }}
          >
            <div
              role="tabpanel"
              id={`${id}-needToKnow-panel`}
              aria-labelledby={`${id}-needToKnow-tab`}
              hidden={currentTab !== 'needToKnow'}
              style={{
                display: currentTab === 'needToKnow' ? 'block' : 'none',
              }}
            >
              {hasPriorityNotesAccess && (
                <NTKContainer
                  heading={t('common:notes')}
                  icon={<NotebookIcon />}
                  headerLink={`/people/students/${studentPartyId}/notes`}
                  hasItems={priorityNotes.length > 0}
                  noItemsText={t('people:noPriorityNotes')}
                  onClose={onClose}
                >
                  {priorityNotes.map((note) => (
                    <NTKNote key={note.id} note={note} />
                  ))}
                </NTKContainer>
              )}
              {hasAenAccess && (
                <NTKContainer
                  heading="AEN"
                  icon={<FolderDotIcon />}
                  colour={Colour.Blue}
                  headerLink={`/people/students/${studentPartyId}/aen`}
                  hasItems={aenEntries.length > 0}
                  noItemsText={t('people:noAenRecords')}
                  onClose={onClose}
                >
                  {aenEntries.map((aen) => (
                    <NTKAen key={aen.id} aen={aen} />
                  ))}
                </NTKContainer>
              )}
              {hasMedicalAccess && (
                <NTKContainer
                  heading={t('people:medicalConditions')}
                  icon={<MedicalCardPatientIcon />}
                  colour={Colour.Emerald}
                  headerLink={`/people/students/${studentPartyId}/medical`}
                  hasItems={medicalConditions.length > 0}
                  noItemsText={t('people:noMedicalRecords')}
                  onClose={onClose}
                >
                  {medicalConditions.map((medicalCondition) => (
                    <NTKCondition
                      key={medicalCondition.id}
                      condition={medicalCondition}
                    />
                  ))}
                </NTKContainer>
              )}
            </div>
            <div
              role="tabpanel"
              id={`${id}-personalInfo-panel`}
              aria-labelledby={`${id}-personalInfo-tab`}
              hidden={currentTab !== 'personalInfo'}
              style={{
                display: currentTab === 'personalInfo' ? 'block' : 'none',
              }}
            >
              <PersonalInfoCard studentPartyId={studentPartyId} open={open} />
            </div>
            <BottomTabs
              componentId={id}
              tabs={needToKnowTabs}
              value={currentTab}
              setTab={setCurrentTab as (value: string) => void}
            />
          </DialogContent>
          <NeedToKnowActions
            studentPartySettings={studentPartySettings}
            open={open}
            onActionClick={onClose}
            onActionClose={reopenNeedToKnow}
          />
        </>
      )}
    </Dialog>
  );
}

import { Stack, Typography } from '@mui/material';
import type { QueryAdditionalOptions } from '@tyro/api';
import {
  Autocomplete,
  type AutocompleteProps,
  type CustomCellEditorProps,
  PreferredNameFormat,
  RHFAutocomplete,
  type RHFAutocompleteProps,
  TableAutocomplete,
  normaliseForeignCharacters,
  usePreferredNameLayout,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useMemo } from 'react';
import type { FieldValues } from 'react-hook-form';
import {
  type ReturnTypeFromUseClassGroups,
  useClassGroups,
} from '../../api/class-groups';
import { useSubjectGroups } from '../../api/subject-groups';

export interface SubjectGroupSelect {
  partyId: number;
  name: string;
  staffNames?: string;
}

type RHFSubjectGroupAutocompleteProps<TField extends FieldValues> = Omit<
  RHFAutocompleteProps<TField, SubjectGroupSelect>,
  'options'
> & { requestOptions?: QueryAdditionalOptions };

type SubjectGroupAutocompleteProps = Omit<
  AutocompleteProps<SubjectGroupSelect>,
  | 'optionIdKey'
  | 'optionTextKey'
  | 'getOptionLabel'
  | 'filterOptions'
  | 'renderAvatarTags'
  | 'renderAvatarOption'
  | 'renderAvatarAdornment'
> & { requestOptions?: QueryAdditionalOptions };

export const RHFSubjectGroupAutocomplete = <TField extends FieldValues>({
  requestOptions,
  ...props
}: RHFSubjectGroupAutocompleteProps<TField>) => {
  const { t } = useTranslation(['groups']);
  const { data: subjectGroupData, isLoading } =
    useSubjectGroups(requestOptions);
  const { displayNames } = usePreferredNameLayout();

  const subjectGroupOptions = useMemo(
    () =>
      subjectGroupData?.map((subjectGroup) => ({
        partyId: subjectGroup.partyId,
        name: subjectGroup.name,
        staffNames: displayNames(subjectGroup.staff, {
          format: PreferredNameFormat.FirstnameSurname,
        }),
      })),
    [subjectGroupData],
  );

  return (
    <RHFAutocomplete<TField, SubjectGroupSelect>
      label={t('groups:subjectGroup')}
      renderOption={(props, option) => (
        <Stack
          component="li"
          {...props}
          sx={{ alignItems: 'flex-start !important' }}
        >
          <Typography variant="subtitle2">{option.name}</Typography>
          <Typography variant="caption">{option.staffNames}</Typography>
        </Stack>
      )}
      filterOptions={(options, { inputValue }) => {
        if (!inputValue) {
          return options;
        }

        return options.filter((option) => {
          const lowerCaseValue = inputValue.toLowerCase();
          return (
            normaliseForeignCharacters(option.name.toLowerCase()).includes(
              lowerCaseValue,
            ) ||
            normaliseForeignCharacters(
              option?.staffNames?.toLowerCase() ?? '',
            ).includes(lowerCaseValue)
          );
        });
      }}
      {...props}
      fullWidth
      optionIdKey="partyId"
      optionTextKey="name"
      loading={isLoading}
      options={subjectGroupOptions ?? []}
    />
  );
};

export const SubjectGroupAutocomplete = ({
  requestOptions,
  ...props
}: SubjectGroupAutocompleteProps) => {
  const { t } = useTranslation(['groups']);
  const { data: subjectGroupData, isLoading } =
    useSubjectGroups(requestOptions);
  const { displayNames } = usePreferredNameLayout();

  const subjectGroupOptions = useMemo(
    () =>
      subjectGroupData?.map((subjectGroup) => ({
        partyId: subjectGroup.partyId,
        name: subjectGroup.name,
        staffNames: displayNames(subjectGroup.staff, {
          format: PreferredNameFormat.FirstnameSurname,
        }),
      })),
    [subjectGroupData],
  );

  return (
    <Autocomplete
      label={t('groups:subjectGroup')}
      fullWidth
      optionIdKey="partyId"
      optionTextKey="name"
      renderOption={(props, option) => (
        <Stack
          component="li"
          {...props}
          sx={{ alignItems: 'flex-start !important' }}
        >
          <Typography variant="subtitle2">{option.name}</Typography>
          <Typography variant="caption">{option.staffNames}</Typography>
        </Stack>
      )}
      filterOptions={(options, { inputValue }) => {
        if (!inputValue) {
          return options;
        }

        return options.filter((option) => {
          const lowerCaseValue = inputValue.toLowerCase();
          return (
            normaliseForeignCharacters(option.name.toLowerCase()).includes(
              lowerCaseValue,
            ) ||
            normaliseForeignCharacters(
              option?.staffNames?.toLowerCase() ?? '',
            ).includes(lowerCaseValue)
          );
        });
      }}
      {...props}
      loading={isLoading}
      options={subjectGroupOptions ?? []}
    />
  );
};

type TableSubjectGroupAutocompleteValue = Pick<
  ReturnTypeFromUseClassGroups,
  'partyId' | 'name'
>;

export function TableSubjectGroupAutocomplete(
  props: CustomCellEditorProps<unknown, TableSubjectGroupAutocompleteValue>,
) {
  const { t } = useTranslation(['common']);
  const { data: classGroupData, isLoading } = useClassGroups();

  return (
    // @ts-expect-error
    <TableAutocomplete
      {...props}
      options={classGroupData ?? []}
      getOptionLabel={(option) => option?.name ?? ''}
      optionIdKey="partyId"
      AutocompleteProps={{
        autoHighlight: true,
        loading: isLoading,
        loadingText: t('common:loading'),
      }}
    />
  );
}

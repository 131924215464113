import { useQuery } from '@tanstack/react-query';

import {
  type TtSolve_SubjectCompetenciesFilter,
  type Ttsolve_SubjectCompetenciesQuery,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { usePreferredNameLayout } from '@tyro/core';
import { useCallback } from 'react';
import { timetableKeys } from '../keys';

const subjectCompetencies = graphql(/* GraphQL */ `
  query ttsolve_subjectCompetencies(
    $filter: TtSolve_SubjectCompetenciesFilter!
  ) {
    ttsolve_subjectCompetencies(filter: $filter) {
      groups {
        groupId
        subjects {
          subjectId
          subject {
            id
            nationalCode
            name
          }
          preference1Staff {
            partyId
            person {
              partyId
              title {
                id
                name
                nameTextId
              }
              firstName
              lastName
              avatarUrl
              type
            }
            displayCode
          }
          preference2Staff {
            partyId
            person {
              partyId
              title {
                id
                name
                nameTextId
              }
              firstName
              lastName
              avatarUrl
              type
            }
            displayCode
          }
          preference3Staff {
            partyId
            person {
              partyId
              title {
                id
                name
                nameTextId
              }
              firstName
              lastName
              avatarUrl
              type
            }
            displayCode
          }
        }
      }
    }
  }
`);

const subjectCompetenciesQuery = (
  filter: TtSolve_SubjectCompetenciesFilter,
) => ({
  queryKey: timetableKeys.subjectCompetencies(filter),
  queryFn: async () => gqlClient.request(subjectCompetencies, { filter }),
});

export async function getSubjectCompetencies(
  filter: TtSolve_SubjectCompetenciesFilter,
) {
  return queryClient.fetchQuery(subjectCompetenciesQuery(filter));
}

export function useSubjectCompetencies(
  filter: TtSolve_SubjectCompetenciesFilter,
) {
  const { sortByDisplayName } = usePreferredNameLayout();

  return useQuery({
    ...subjectCompetenciesQuery(filter),
    enabled: Boolean(filter.competencyGroupId),
    select: useCallback(
      ({ ttsolve_subjectCompetencies }: Ttsolve_SubjectCompetenciesQuery) =>
        ttsolve_subjectCompetencies?.groups?.map(
          ({ subjects, ...groupProps }) => ({
            ...groupProps,
            subjects: subjects.map((subject) => ({
              ...subject,
              preference1Staff: subject.preference1Staff.sort((a, b) =>
                sortByDisplayName(a.person, b.person),
              ),
              preference2Staff: subject.preference2Staff.sort((a, b) =>
                sortByDisplayName(a.person, b.person),
              ),
              preference3Staff: subject.preference3Staff.sort((a, b) =>
                sortByDisplayName(a.person, b.person),
              ),
            })),
          }),
        ) || [],
      [sortByDisplayName],
    ),
  });
}

export type ReturnTypeFromUseSubjectCompetencies = UseQueryReturnType<
  typeof useSubjectCompetencies
>[number];

import { Autocomplete, type CustomCellEditorProps } from '@tyro/core';

import { Box } from '@mui/material';
import type { UseQueryReturnType } from '@tyro/api';
import { type ForwardedRef, forwardRef, useEffect, useRef } from 'react';
import { useStaffForSelect } from '../../api/staff';
import { usePeopleAutocompleteProps } from './use-people-autocomplete-props';

type ReturnTypeFromUseStaffForSelect = UseQueryReturnType<
  typeof useStaffForSelect
>[number];

type TableStaffMultipleAutocompleteProps = CustomCellEditorProps<
  unknown,
  ReturnTypeFromUseStaffForSelect[]
>;
type AutocompleteValue = NonNullable<
  TableStaffMultipleAutocompleteProps['value']
>;

export const TableStaffMultipleAutocomplete = forwardRef(
  (props: TableStaffMultipleAutocompleteProps, ref: ForwardedRef<unknown>) => {
    const { data: staffData = [], isLoading } = useStaffForSelect({});

    const inputRef = useRef<HTMLInputElement>(null);
    const { value = [], onValueChange, eGridCell } = props;

    const peopleAutocompleteProps =
      usePeopleAutocompleteProps<AutocompleteValue[number]>();

    useEffect(() => {
      const gridCellHeight = inputRef.current?.offsetHeight ?? 0;
      eGridCell.style.height = `${gridCellHeight}px`;
    }, [value]);

    return (
      <Box position="absolute" top={0} left={0} width="100%" height="100%">
        <Autocomplete
          {...peopleAutocompleteProps}
          disableCloseOnSelect
          loading={isLoading}
          disablePortal
          multiple
          customRef={ref}
          fullWidth
          sx={{
            width: '100%',
            height: '100%',
            '& fieldset': {
              width: '100%',
              height: '100%',
              backgroundColor: 'transparent',
              outline: 'none',
              border: 'none',
              fontFamily: 'inherit',
              fontSize: 'inherit',
              color: 'inherit',
              paddingX: 1,
            },
          }}
          componentsProps={{
            popper: {
              placement: 'bottom-start',
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 4],
                  },
                },
              ],
            },
          }}
          value={value}
          onChange={(_, value) => {
            eGridCell.style.height = '100%';
            onValueChange(value as AutocompleteValue);
          }}
          inputProps={{ autoFocus: true, ref: inputRef }}
          openOnFocus
          options={staffData}
        />
      </Box>
    );
  },
);

if (process.env.NODE_ENV !== 'production') {
  TableStaffMultipleAutocomplete.displayName = 'TableStaffMultipleAutocomplete';
}

import {
  Backdrop,
  Box,
  Divider,
  IconButton,
  Portal,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { RecipientType, usePermissions } from '@tyro/api';
import {
  RHFSelect,
  useDisclosure,
  useFormValidator,
  useResponsive,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import {
  CloseIcon,
  CollapseIcon,
  ExpandIcon,
  MegaphoneIcon,
  UserGroupIcon,
} from '@tyro/icons';
import { useCallback, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import type { ReturnTypeUseMailSearch } from '../../api/mail-search';
import { type ReturnTypeUseMail, useSendMail } from '../../api/mails';
import useAttachFiles from '../../hooks/use-attach-files';
import { useMailEditor } from '../../hooks/use-mail-editor';
import { MailEditor } from '../editor';
import { AttachedFilesList } from '../editor/attach-files/list';
import type { SendButtonProps } from '../editor/send-button';
import { MailEditorToolbar } from '../editor/toolbar';
import { RHFMailSearch } from '../fields/rhf-mail-search';
import { EditorRHFTextfield } from '../fields/rhf-textfield';
import { useMailInboxOptions } from './school-inbox-switcher';

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  outline: 'none',
  display: 'flex',
  position: 'fixed',
  overflowX: 'hidden',
  overflowY: 'auto',
  flexDirection: 'column',
  margin: theme.spacing(3),
  boxShadow: theme.customShadows.z20,
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundColor: theme.palette.background.paper,
}));

export enum MailTypeMessage {
  BROADCAST = 'BROADCAST',
  GROUP = 'GROUP',
}

export interface ComposeMailFormValues {
  subject: string;
  canReply: boolean;
  typeMessage: MailTypeMessage;
  toRecipients: ReturnTypeUseMailSearch[];
  partyId: number;
}

export type MailComposeProps = {
  onCloseCompose: () => void;
  defaultValues: Partial<
    ComposeMailFormValues & {
      body: string;
      attachments?: ReturnTypeUseMail['attachments'];
    }
  >;
  activeProfileId: number;
  hasPermissionForSchoolInbox: boolean;
};

export const IconByTypeMessage = {
  [MailTypeMessage.BROADCAST]: <MegaphoneIcon />,
  [MailTypeMessage.GROUP]: <UserGroupIcon />,
};

export default function MailCompose({
  onCloseCompose,
  defaultValues,
  activeProfileId,
  hasPermissionForSchoolInbox,
}: MailComposeProps) {
  const { t } = useTranslation(['mail', 'common']);
  const { isStaffUser } = usePermissions();
  const isDesktop = useResponsive('up', 'sm');
  const editor = useMailEditor({
    defaultContent: defaultValues.body,
  });

  const { isUploading, attachFiles, setAttachFiles, uploadFiles } =
    useAttachFiles();

  const { isOpen: isFullScreen, onToggle: onToggleFullScreen } =
    useDisclosure();
  const mailboxOptions = useMailInboxOptions();

  const { resolver, rules } = useFormValidator<ComposeMailFormValues>();

  const { control, handleSubmit, reset } = useForm<ComposeMailFormValues>({
    defaultValues: {
      toRecipients: [],
      typeMessage: isStaffUser
        ? MailTypeMessage.BROADCAST
        : MailTypeMessage.GROUP,
      canReply: !isStaffUser,
      partyId: activeProfileId,
      ...defaultValues,
    },
    resolver: resolver({
      subject: rules.required(),
      toRecipients: rules.validate(
        (value: ReturnTypeUseMailSearch[], throwError) => {
          if (!value.length) {
            return throwError(t('mail:errorMessages.recipientsRequired'));
          }
        },
      ),
    }),
  });
  const {
    field: { value: canReplyValue, onChange: onChangeCanReply },
  } = useController({
    name: 'canReply',
    control,
  });

  const { mutateAsync: sendMail, isPending: isSending } = useSendMail();

  const handleClose = () => {
    setAttachFiles(new Map());
    onCloseCompose();
  };

  const onSend: SendButtonProps['onSend'] = ({ scheduleFor, onSuccess } = {}) =>
    handleSubmit(
      async ({
        toRecipients = [],
        typeMessage,
        partyId: senderPartyId,
        ...rest
      }) => {
        const recipients = toRecipients.map(({ partyId, type }) => ({
          recipientPartyId: partyId,
          recipientPartyType: type,
          recipientType:
            typeMessage === MailTypeMessage.BROADCAST
              ? RecipientType.Bcc
              : RecipientType.To,
        }));

        const body = editor?.getHTML() ?? '';
        const attachments = await uploadFiles();

        sendMail(
          {
            ...rest,
            recipients,
            attachments,
            body,
            schoolMail: senderPartyId === -1,
            scheduleFor: scheduleFor
              ? scheduleFor.format('YYYY-MM-DDTHH:mm:ss')
              : undefined,
          },
          {
            onSuccess: () => {
              onSuccess?.();
              handleClose();
            },
          },
        );
      },
    )();

  useEffect(() => {
    const { body, attachments, ...formValues } = defaultValues;
    reset(formValues);
    editor?.commands.setContent(body ?? '');
    setAttachFiles(
      new Map(
        (attachments || []).map((attachment) => {
          const { href, pathname } = new URL(attachment?.url || '');

          return [
            href,
            {
              fileUrl: href,
              name: decodeURI(pathname.split('%2F').pop() ?? ''),
            },
          ];
        }),
      ),
    );
  }, [defaultValues]);

  const getTypeMessageOption = useCallback(
    (option: MailTypeMessage) => (
      <Stack direction="row" gap={1} alignItems="center">
        {IconByTypeMessage[option]}
        <Stack>
          <Typography variant="subtitle2" component="span">
            {t(`mail:typeMessage.${option}.name`)}
          </Typography>
          <Typography variant="caption">
            {t(`mail:typeMessage.${option}.description`)}
          </Typography>
        </Stack>
      </Stack>
    ),
    [],
  );

  const renderTypeMessageValue = useCallback(
    (option: MailTypeMessage) => (
      <Stack direction="row" gap={1} alignItems="center">
        {IconByTypeMessage[option]}
        <Typography variant="subtitle2" component="span">
          {t(`mail:typeMessage.${option}.name`)}
        </Typography>
      </Stack>
    ),
    [],
  );

  return (
    <Portal>
      <Backdrop
        open={isFullScreen || !isDesktop}
        sx={({ zIndex }) => ({ zIndex: zIndex.drawer })}
      />
      <RootStyle
        sx={({ zIndex }) => ({
          zIndex: zIndex.drawer,
          width: {
            xs: 'calc(100vw - 24px)',
            md: 'calc(100vw - 80px)',
          },
          height: {
            xs: 'calc(100vh - 24px)',
            md: 'calc(100vh - 80px)',
          },
          ...(isFullScreen || !isDesktop
            ? {
                top: 0,
                left: 0,
                margin: 'auto',
              }
            : {
                maxHeight: {
                  xs: 'min(calc(100vh - 24px), 480px)',
                  md: 'min(calc(100vh - 80px), 480px)',
                },
                maxWidth: {
                  xs: 'min(calc(100vw - 24px), 480px)',
                  sm: 'min(calc(100vw - 80px), 480px)',
                },
              }),
        })}
      >
        <Stack
          direction="row"
          pl={3}
          pr={1}
          height={60}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography component="h2" variant="h6">
            {t('mail:newMessage')}
          </Typography>
          <Box>
            <IconButton onClick={onToggleFullScreen}>
              {isFullScreen ? (
                <CollapseIcon
                  aria-label={t('mail:actions.collapse')}
                  sx={{ height: 20, width: 20 }}
                />
              ) : (
                <ExpandIcon
                  aria-label={t('mail:actions.expand')}
                  sx={{ height: 20, width: 20 }}
                />
              )}
            </IconButton>

            <IconButton
              aria-label={t('common:actions.close')}
              onClick={handleClose}
            >
              <CloseIcon sx={{ height: 20, width: 20 }} />
            </IconButton>
          </Box>
        </Stack>

        <Divider />

        {hasPermissionForSchoolInbox && (
          <>
            <Box pl={3} pr={1}>
              <RHFSelect
                variant="white-filled"
                options={mailboxOptions}
                fullWidth
                optionIdKey="value"
                renderAvatarOption={(option, renderOption) =>
                  renderOption(
                    {
                      name: option.name,
                      src: option.avatarUrl ?? undefined,
                      size: 24,
                    },
                    {
                      gap: 1,
                    },
                  )
                }
                controlProps={{
                  control,
                  name: 'partyId',
                }}
                sx={{
                  '.MuiSelect-select.MuiInputBase-input': {
                    display: 'flex',
                    alignItems: 'center',
                    height: '41px',
                    p: 0,
                  },
                }}
              />
            </Box>
            <Divider />
          </>
        )}

        {isStaffUser && (
          <>
            <Box pl={3} pr={1}>
              <RHFSelect
                variant="white-filled"
                options={Object.values(MailTypeMessage)}
                fullWidth
                getOptionLabel={getTypeMessageOption}
                renderValue={renderTypeMessageValue}
                controlProps={{
                  control,
                  name: 'typeMessage',
                }}
                sx={{
                  '.MuiSelect-select.MuiInputBase-input': {
                    display: 'flex',
                    alignItems: 'center',
                    height: '41px',
                    p: 0,
                  },
                }}
              />
            </Box>
            <Divider />
          </>
        )}

        <RHFMailSearch
          controlProps={{
            control,
            name: 'toRecipients',
          }}
          label={t('mail:placeholders.to')}
        />

        <EditorRHFTextfield
          label={t('mail:placeholders.subject')}
          controlProps={{
            control,
            name: 'subject',
          }}
        />

        <MailEditor editor={editor} />

        {attachFiles.size > 0 && (
          <AttachedFilesList
            attachFiles={attachFiles}
            setAttachFiles={setAttachFiles}
          />
        )}

        <Divider />

        <MailEditorToolbar
          editor={editor}
          onSend={onSend}
          isSending={isUploading || isSending}
          setAttachFiles={setAttachFiles}
          canReplyValue={canReplyValue}
          onCanReplyChange={isStaffUser ? onChangeCanReply : undefined}
        />
      </RootStyle>
    </Portal>
  );
}

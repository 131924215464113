import { LoadingButton } from '@mui/lab';
import {
  Button,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RHFDateTimePicker,
  useDisclosure,
  useFormValidator,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { ChevronDownIcon } from '@tyro/icons';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { useEffect, useId, useRef } from 'react';
import { useForm } from 'react-hook-form';

type CustomTimeFormValues = {
  dateTime: Dayjs;
};

export interface SendButtonProps {
  onSend: (options?: {
    scheduleFor?: Dayjs;
    onSuccess?: () => void;
  }) => Promise<void>;
  isSending: boolean;
}

export function SendButton({ onSend, isSending }: SendButtonProps) {
  const id = useId();
  const anchorEl = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation(['common', 'mail']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isCustomTimeOpen,
    onOpen: openCustomTime,
    onClose: closeCustomTime,
  } = useDisclosure();

  const { resolver, rules } = useFormValidator<CustomTimeFormValues>();
  const { handleSubmit, control, reset } = useForm<CustomTimeFormValues>({
    resolver: resolver({
      dateTime: [
        rules.required(),
        rules.validate<Dayjs>((value, throwError) => {
          if (value.isBefore(dayjs())) {
            throwError(t('mail:needsToBeATimeInTheFuture'));
          }
        }),
      ],
    }),
    defaultValues: {
      dateTime: dayjs().add(1, 'hour').startOf('hour'),
    },
  });

  const closeAllPopups = () => {
    onClose();
    closeCustomTime();
  };

  const sendCustomTime = handleSubmit(async ({ dateTime }) => {
    onSend({
      scheduleFor: dateTime,
      onSuccess: closeAllPopups,
    });
  });

  useEffect(() => {
    if (isCustomTimeOpen) {
      reset();
    }
  }, [isCustomTimeOpen]);

  const now = dayjs();
  const tomorrow = now.add(1, 'day').startOf('day');
  let scheduleTimes = [
    tomorrow.hour(9).minute(0).second(0).millisecond(0),
    tomorrow.hour(12).minute(0).second(0).millisecond(0),
  ];

  if (now.hour() < 12) {
    scheduleTimes = [
      now.hour(12).minute(0).second(0).millisecond(0),
      tomorrow.hour(9).minute(0).second(0).millisecond(0),
    ];
  }

  return (
    <>
      <Stack
        direction="row"
        sx={{
          '& button:first-of-type': {
            borderTopRightRadius: '0',
            borderBottomRightRadius: '0',
          },
          '& button:last-of-type': {
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
            borderLeft: '1px solid',
            borderLeftColor: 'indigo.300',
            p: 0.75,
            minWidth: 'auto',
          },
        }}
      >
        <LoadingButton
          variant="contained"
          onClick={() => onSend()}
          loading={isSending}
        >
          {t('common:actions.send')}
        </LoadingButton>
        <Button
          ref={anchorEl}
          id={`${id}-button`}
          aria-controls={isOpen ? id : undefined}
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : undefined}
          variant="contained"
          aria-label={t('mail:scheduleMail')}
          onClick={onOpen}
        >
          <ChevronDownIcon />
        </Button>
      </Stack>
      <Menu
        id={id}
        aria-labelledby={`${id}-button`}
        anchorEl={anchorEl.current}
        open={isOpen}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        MenuListProps={{
          dense: true,
        }}
      >
        <Typography
          component="h2"
          variant="caption"
          sx={{
            px: 2.25,
            py: 0.5,
          }}
        >
          {t('mail:scheduleMail')}
        </Typography>
        <section>
          {scheduleTimes.map((time) => (
            <MenuItem
              key={time.toString()}
              onClick={() =>
                onSend({ scheduleFor: time, onSuccess: closeAllPopups })
              }
            >
              {time.isSame(dayjs(), 'day')
                ? t('mail:todayAtTime', { time: time.format('LT') })
                : t('mail:tomorrowAtTime', { time: time.format('LT') })}
            </MenuItem>
          ))}
          <Divider />
          <MenuItem onClick={openCustomTime}>{t('mail:customTime')}</MenuItem>
        </section>
      </Menu>
      <Dialog open={isCustomTimeOpen} onClose={onClose}>
        <DialogTitle onClose={closeCustomTime}>
          {t('mail:scheduleMail')}
        </DialogTitle>
        <DialogContent>
          <Stack mt={0.75}>
            <RHFDateTimePicker
              label={t('common:dateAndTime')}
              controlProps={{
                name: 'dateTime',
                control,
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="soft"
            color="inherit"
            onClick={closeCustomTime}
          >
            {t('common:actions.cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            onClick={sendCustomTime}
            loading={isSending}
          >
            {t('mail:schedule')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

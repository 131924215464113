import {
  Button,
  Chip,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from '@mui/material';
import {
  EmulationMode,
  addEmulationHeaders,
  checkEmulationMode,
  useUser,
} from '@tyro/api';
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useToast,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useMemo } from 'react';
import {
  useSetDefaultProfile,
  useSwitchProfile,
} from '../../../api/switch-profile';

interface ProfileSwitchModalProps {
  open: boolean;
  onClose: () => void;
}

export function ProfileSwitchModal({ open, onClose }: ProfileSwitchModalProps) {
  const { t } = useTranslation(['common']);
  const { user, activeProfile } = useUser();
  const { toast } = useToast();

  const { mutateAsync: setDefaultProfile } = useSetDefaultProfile();
  const { mutateAsync: switchProfile } = useSwitchProfile();
  const hasDifferentProfileTypes = useMemo(() => {
    const profileTypeSet = new Set(
      user?.profiles
        ?.map((profile) => profile.profileType?.name ?? '')
        .filter(Boolean) ?? [],
    );
    return profileTypeSet.size > 1;
  }, [user?.profiles]);

  const requestSwapProfile = (
    requestedProfile: NonNullable<NonNullable<typeof user>['profiles']>[number],
  ) => {
    if (requestedProfile.id === activeProfile?.id) {
      onClose();
      return;
    }

    switchProfile(
      {
        globalUserId: user!.id!,
        requestedProfileId: requestedProfile.id,
        partyId: requestedProfile.partyId!,
        tenant: requestedProfile.tenantId!,
      },
      {
        onSuccess: () => {
          if (checkEmulationMode() === EmulationMode.User) {
            addEmulationHeaders(
              requestedProfile.tenantId!,
              requestedProfile.partyId!,
            );
          }
          window.location.href = '/';
        },
        onError: () => {
          toast(t('common:failedToSwitchProfile'), { variant: 'error' });
        },
      },
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle onClose={onClose}>{t('common:switchProfile')}</DialogTitle>
      <DialogContent>
        <MenuList
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            li: {
              '& .set-as-default': {
                opacity: 0,
                transition: 'opacity 0.1s ease-in-out',
              },

              '&:hover, &:focus-within': {
                '& .set-as-default': {
                  opacity: 1,
                },
              },
            },
          }}
        >
          {user?.profiles?.map((profile) => (
            <MenuItem
              key={profile.id}
              sx={{ borderRadius: 1.5 }}
              onClick={() => requestSwapProfile(profile)}
              selected={profile.id === activeProfile?.id}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                flex={1}
              >
                <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Avatar
                    src={profile.tenant?.imgUrl}
                    name={profile.tenant?.name}
                    sx={{
                      my: 1,
                      mr: 0.5,
                    }}
                  />
                  <Stack alignItems="flex-start" flex="1">
                    <Typography
                      component="h3"
                      variant="subtitle2"
                      color="text.primary"
                    >
                      {profile.tenant?.name}
                    </Typography>
                    {hasDifferentProfileTypes && (
                      <Typography
                        component="span"
                        variant="body2"
                        color="text.secondary"
                      >
                        {profile.profileType?.name}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
                {profile.id === user?.defaultProfileId ? (
                  <Chip
                    label={t('common:default')}
                    color="info"
                    variant="soft"
                    size="small"
                  />
                ) : (
                  <Chip
                    label={t('common:setAsDefault')}
                    color="primary"
                    size="small"
                    className="set-as-default"
                    onClick={(event) => {
                      event.stopPropagation();
                      setDefaultProfile({
                        profileId: profile.id,
                      });
                    }}
                  />
                )}
              </Stack>
            </MenuItem>
          ))}
        </MenuList>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="soft" color="inherit">
          {t('common:actions.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

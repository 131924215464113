import { useQuery } from '@tanstack/react-query';

import {
  type TtSolve_SolveParams,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { timetableKeys } from '../keys';

const restrictions = graphql(/* GraphQL */ `
  query ttsolve_restrictions($filter: TtSolve_SolveParams!) {
    ttsolve_restrictions(filter: $filter) {
      resctriction {
        restrictionIdx
        type
        ruleId
        description
        enabled
        arguments {
          type
          value
          rawValue
        }
        createdBy {
          userId
          partyId
          person {
            title {
              id
              name
              nameTextId
            }
            firstName
            lastName
            avatarUrl
          }
        }
        hasError
      }
    }
  }
`);

const restrictionsQuery = (filter: TtSolve_SolveParams) => ({
  queryKey: timetableKeys.restrictions(filter),
  queryFn: async () => gqlClient.request(restrictions, { filter }),
});

export async function getRestrictions(filter: TtSolve_SolveParams) {
  return queryClient.fetchQuery(restrictionsQuery(filter));
}

export function useRestrictions(filter: TtSolve_SolveParams) {
  return useQuery({
    ...restrictionsQuery(filter),
    select: ({ ttsolve_restrictions }) =>
      ttsolve_restrictions?.resctriction || [],
  });
}

export type ReturnTypeFromUseRestrictions = UseQueryReturnType<
  typeof useRestrictions
>[number];

import { useQuery } from '@tanstack/react-query';
import {
  type CalendarEventFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import dayjs from 'dayjs';
import { attendanceKeys } from './keys';

const calendarLessonTimes = graphql(/* GraphQL */ `
  query calendar_calendarLessonTimes($filter: CalendarEventFilter) {
    calendar_calendarEvents(filter: $filter) {
       resources {
        events {
          name
          eventId
          startTime
          endTime
        }
      }
    }
  }
`);

const lessonTimesQuery = (filter: CalendarEventFilter) => ({
  queryKey: attendanceKeys.calendarLessonTimes(filter),
  queryFn: async () => {
    const { calendar_calendarEvents: lessonTimesData } =
      await gqlClient.request(calendarLessonTimes, { filter });

    return (lessonTimesData?.resources?.[0].events || []).sort(
      (a, b) => dayjs(a.startTime).unix() - dayjs(b.startTime).unix(),
    );
  },
});

export function getLessonTimes(filter: CalendarEventFilter) {
  return queryClient.fetchQuery(lessonTimesQuery(filter));
}

export function useLessonTimes(filter: CalendarEventFilter) {
  return useQuery({
    ...lessonTimesQuery(filter),
    select: (lessonTimesData) => lessonTimesData,
  });
}

export type ReturnTypeFromUseCalendarLessonTimes = UseQueryReturnType<
  typeof useLessonTimes
>;

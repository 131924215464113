import {
  Box,
  TextField,
  type TextFieldProps,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from '@tyro/i18n';
import { forwardRef } from 'react';
import {
  type FieldValues,
  type UseControllerProps,
  useController,
} from 'react-hook-form';
import { analyzeSmsTextString } from '../../utils/analyze-sms-text-string';

export interface SmsMessageFieldProps extends Omit<TextFieldProps, 'variant'> {
  variant?:
    | TextFieldProps['variant']
    | 'white-filled'
    | 'white-filled-outlined';
}

export const SmsMessageField = forwardRef<JSX.Element, SmsMessageFieldProps>(
  ({ variant, ...props }, ref) => {
    const { value, helperText, InputProps } = props;
    const { t } = useTranslation(['sms']);

    const textCountObject = analyzeSmsTextString(value as string | undefined);

    const theme = useTheme();
    const { spacing, palette } = theme;
    const isWhiteFilledVariant =
      variant === 'white-filled' || variant === 'white-filled-outlined';

    return (
      <Box position="relative">
        <TextField
          multiline
          fullWidth
          minRows={4}
          {...props}
          sx={{
            ...(typeof props?.sx === 'function' ? props.sx(theme) : props?.sx),
            ...(isWhiteFilledVariant && {
              '& .MuiInputBase-root, & .MuiInputBase-root:focus, & .MuiInputBase-root.Mui-focused, & .MuiInputBase-root:hover, & .MuiInputBase-root, & .MuiInputBase-input:focus, & .MuiInputBase-input.Mui-focused, & .MuiInputBase-input:hover':
                {
                  backgroundColor: palette.background.default,
                  borderRadius: spacing(1),
                },
              ...(variant === 'white-filled-outlined' && {
                '& .MuiInputBase-root': {
                  border: '1px solid',
                  borderColor: 'divider',
                },
              }),
            }),
          }}
          variant={isWhiteFilledVariant ? 'filled' : variant}
          inputRef={ref}
          InputProps={{
            ...InputProps,
            sx: {
              ...InputProps?.sx,
              pb: 4,
            },
          }}
        />
        <Typography
          variant="caption"
          sx={{
            position: 'absolute',
            bottom: helperText ? 34 : 8,
            left: 12,
            fontWeight: 600,
            color: 'text.secondary',
          }}
        >
          {t('sms:numberOfCharactersWithCharLimitPerSms', textCountObject)}
        </Typography>
      </Box>
    );
  },
);

if (process.env.NODE_ENV !== 'production') {
  SmsMessageField.displayName = 'SmsMessageField';
}

export type RHFSmsMessageFieldProps<TField extends FieldValues> = {
  label?: string;
  textFieldProps?: SmsMessageFieldProps;
  controlProps: UseControllerProps<TField>;
};

export const RHFSmsMessageField = <TField extends FieldValues>({
  label,
  textFieldProps,
  controlProps,
}: RHFSmsMessageFieldProps<TField>) => {
  const {
    field,
    fieldState: { error },
  } = useController(controlProps);

  return (
    <SmsMessageField
      {...textFieldProps}
      {...field}
      value={field.value ?? ''}
      label={label}
      error={!!error}
      helperText={error?.message}
    />
  );
};

import { useState } from 'react';

import type { AttachmentInput } from '@tyro/api';
import { useUploadMailFile } from '../api/attachments/upload';

export default function useAttachFiles() {
  const { mutateAsync: uploadFile } = useUploadMailFile();
  const [isUploading, setIsUploading] = useState(false);
  const [attachFiles, setAttachFiles] = useState<
    Map<string, Partial<File> & { fileUrl: string }>
  >(new Map());

  const uploadFiles = async () => {
    setIsUploading(true);
    const attachments: AttachmentInput[] = [];
    for (const file of Array.from(attachFiles.values())) {
      try {
        const response = (await uploadFile([file as File])) as Response;
        const data = (await response.json()) as { id: number; path: string };

        attachments.push({ id: data.id, url: data.path });
      } catch (error) {
        console.error(error);
      }
    }
    setIsUploading(false);

    return attachments;
  };

  return {
    attachFiles,
    setAttachFiles,
    isUploading,
    uploadFiles,
  };
}

import { Chip, type Color, type Palette, Stack, useTheme } from '@mui/material';
import {
  type PartyGroup,
  type PartyGroupType,
  PartyPersonType,
  type Person,
  type PhoneNumber,
  Reporting_MetaChipSize,
  Reporting_MetaChipVariant,
  Reporting_ReportCellType,
  type Reporting_TableReportField,
  type SubjectGroup,
  getGroupProfileLink,
  getPersonProfileLink,
} from '@tyro/api';
import {
  type IRowNode,
  LinkRender,
  PreferredNameFormat,
  RouterLink,
  TableAvatar,
  TableBooleanValue,
  TablePersonAvatar,
  formatPhoneNumber,
  getDateFilterTableSettings,
  getHoursAndMinutes,
  usePreferredNameLayout,
} from '@tyro/core';
import { useFormatNumber, useTranslation } from '@tyro/i18n';
import { StudentTableAvatar } from '@tyro/people';
import dayjs, { type Dayjs } from 'dayjs';
import type { HTMLAttributeAnchorTarget } from 'react';
import type { ReturnTypeFromUseRunReports } from '../api/run-report';
import type {
  ExtendedReportData,
  ReportChipValue,
  ReportStudentPerson,
} from '../components/types';
import { Report, getReportUrl } from '../utils/get-report-url';

const getLinkWithQueryParams = (
  basePath: string | null,
  queryParams?: Record<string, unknown>,
) => {
  if (!basePath) return null;

  try {
    const url = new URL(basePath, new URL(window.location.origin));

    for (const [param, paramValue] of Object.entries(queryParams || {})) {
      url.searchParams.append(param, String(paramValue ?? ''));
    }

    return url.toString();
  } catch (_error) {
    return null;
  }
};

const getCustomLink = (
  value: ExtendedReportData[number],
  column: Reporting_TableReportField,
  internalUrl?: string | null,
) => {
  if (!column.meta?.enableLink) return null;

  const { report, reportFilters, reportTab } = value.link || {};

  // for report links
  if (report) {
    switch (report) {
      case Report.OCTOBER_RETURNS: {
        return getReportUrl({ report, tab: reportTab });
      }
      case Report.STUDENT_BEHAVIOUR: {
        return getReportUrl({
          report,
          filters: {
            ...reportFilters,
            to_date: dayjs(reportFilters?.to_date as string),
            from_date: dayjs(reportFilters?.from_date as string),
          },
        });
      }
      default:
        return getReportUrl({ report });
    }
  }

  const { profileTab, queryParams, externalUrl } = value.link || {};

  // for internal profile links
  if (internalUrl) {
    const basePath = [internalUrl, profileTab].filter(Boolean).join('/');
    return getLinkWithQueryParams(basePath, queryParams);
  }

  return externalUrl || null;
};

export type UseReportFormatValuesArg = {
  settings?: {
    linkTarget?: HTMLAttributeAnchorTarget;
  };
};

export const useReportFormatValues = ({
  settings,
}: UseReportFormatValuesArg) => {
  const { t } = useTranslation(['common']);
  const { palette } = useTheme();

  const { displayName, displayNames } = usePreferredNameLayout();
  const { formatCurrency } = useFormatNumber();

  const getRawValue = (value: ExtendedReportData[number]) =>
    value?.value as string;

  const renderRawValue = (
    value: ExtendedReportData[number],
    column: Reporting_TableReportField,
  ) => {
    const valueRaw = getRawValue(value);
    const toLink = getCustomLink(value, column);

    const linkTarget = settings?.linkTarget || value.link?.target || '_blank';

    if (toLink) {
      return (
        <RouterLink sx={{ fontWeight: 600 }} to={toLink} target={linkTarget}>
          {valueRaw}
        </RouterLink>
      );
    }

    return <LinkRender text={valueRaw} />;
  };

  const getPersonValue = (value: ExtendedReportData[number]) => {
    const valueAsPerson = value.value as Person | Person[];

    return Array.isArray(valueAsPerson)
      ? displayNames(valueAsPerson)
      : displayName(valueAsPerson, {
          format: PreferredNameFormat.FirstnameSurname,
        });
  };

  const renderPersonAvatar = (
    value: ExtendedReportData[number],
    column: Reporting_TableReportField,
  ) => {
    const valueAsPerson = value.value as
      | ReportStudentPerson
      | ReportStudentPerson[];

    if (Array.isArray(valueAsPerson)) {
      return displayNames(valueAsPerson);
    }

    const baseUrl = getPersonProfileLink(valueAsPerson, value.link?.profileTab);
    const toLink = getLinkWithQueryParams(baseUrl, value.link?.queryParams);
    const linkTarget = settings?.linkTarget || value.link?.target || '_blank';

    if (valueAsPerson.type === PartyPersonType.Student) {
      return (
        <StudentTableAvatar
          person={valueAsPerson}
          isPriorityStudent={!!valueAsPerson.priorityFlag}
          hasSupportPlan={!!valueAsPerson.aenFlag}
          hasMedical={!!valueAsPerson.medicalFlag}
          size={column.meta?.avatarSize ?? undefined}
          hideAvatar={!column.meta?.showAvatar}
          to={toLink}
          target={linkTarget}
        />
      );
    }

    return (
      <TablePersonAvatar
        person={valueAsPerson}
        hideAvatar={!column.meta?.showAvatar}
        to={toLink}
        target={linkTarget}
        AvatarProps={{
          size: column.meta?.avatarSize ?? undefined,
        }}
      />
    );
  };

  const getPartyGroupValue = (value: ExtendedReportData[number]) => {
    const valueAsPartyGroup = value.value as PartyGroup;

    return valueAsPartyGroup.name;
  };

  const renderPartyGroupAvatar = (
    value: ExtendedReportData[number],
    column: Reporting_TableReportField,
  ) => {
    const valueAsPartyGroup = value.value as PartyGroup & {
      type: PartyGroupType;
    };

    const profileLink = getGroupProfileLink(valueAsPartyGroup);
    const toLink = getCustomLink(value, column, profileLink);

    const subject = (valueAsPartyGroup as unknown as SubjectGroup)
      ?.subjects?.[0];
    const bgColorStyle = subject?.colour
      ? { bgcolor: `${subject.colour}.500` }
      : {};
    const linkTarget = settings?.linkTarget || value.link?.target || '_blank';

    return (
      <TableAvatar
        name={valueAsPartyGroup.name}
        avatarUrl={valueAsPartyGroup.avatarUrl}
        hideAvatar={!column.meta?.showAvatar}
        to={toLink}
        target={linkTarget}
        AvatarProps={{
          size: column.meta?.avatarSize ?? undefined,
          sx: {
            borderRadius: 1,
            ...bgColorStyle,
          },
        }}
      />
    );
  };

  const getDateValue = (
    value: ExtendedReportData[number],
    column: Reporting_TableReportField,
  ) => {
    if (!value.value) return undefined;

    const valueAsDate = dayjs(value.value as string);
    return valueAsDate;
  };

  const getFormattedDateValue = (
    value: ExtendedReportData[number],
    column: Reporting_TableReportField,
  ) => {
    if (!value.value) return undefined;

    const valueAsDate = dayjs(value.value as string);

    return valueAsDate.format(column.meta?.dateFormat || 'L');
  };

  const formatCurrencyValue = (
    value: ExtendedReportData[number],
    column: Reporting_TableReportField,
  ) => {
    const valueAsCurrency = Number(value.value as number);

    return formatCurrency(valueAsCurrency, {
      currency: column.meta?.currency || 'EUR',
    });
  };

  const getBooleanValue = (value: ExtendedReportData[number]) => {
    const valueAsBoolean = Boolean(value.value);

    return valueAsBoolean ? t('common:yes') : t('common:no');
  };

  const renderBooleanValue = (value: ExtendedReportData[number]) => (
    <Stack alignItems="center">
      <TableBooleanValue value={Boolean(value.value)} />
    </Stack>
  );

  const getPhoneNumberValue = (value: ExtendedReportData[number]) =>
    formatPhoneNumber(value.value as PhoneNumber);

  const getChipValue = (value: ExtendedReportData[number]) => {
    const valueAsChip = value.value as ReportChipValue | ReportChipValue[];

    return [valueAsChip]
      .flat()
      .map((chip) => chip.name)
      .join(', ');
  };

  const formatMinutesToHours = (value: ExtendedReportData[number]) => {
    const minutes = value.value as string;

    return getHoursAndMinutes(Number(minutes), t);
  };

  const renderChipValue = (
    value: ExtendedReportData[number],
    column: Reporting_TableReportField,
  ) => {
    const valueAsChip = value.value as ReportChipValue | ReportChipValue[];

    const chips = [valueAsChip].flat();

    if (chips.length === 0) return undefined;

    return (
      <Stack gap={1} direction="row" flexWrap="wrap">
        {chips.map(({ name, color }) => (
          <Chip
            key={name}
            label={name}
            color={color || 'slate'}
            size={column.meta?.chipSize || Reporting_MetaChipSize.Small}
            variant={column.meta?.chipVariant || Reporting_MetaChipVariant.Soft}
          />
        ))}
      </Stack>
    );
  };

  const getValue = (
    column: Reporting_TableReportField,
    value: ExtendedReportData[number] | undefined,
  ): string | number | Dayjs | undefined => {
    if (!value) return undefined;

    switch (column.cellType) {
      case Reporting_ReportCellType.Person: {
        return getPersonValue(value);
      }
      case Reporting_ReportCellType.PartyGroup: {
        return getPartyGroupValue(value);
      }
      case Reporting_ReportCellType.Date: {
        return getDateValue(value, column);
      }
      case Reporting_ReportCellType.Currency: {
        return Number(value.value as number);
      }
      case Reporting_ReportCellType.Boolean: {
        return getBooleanValue(value);
      }
      case Reporting_ReportCellType.PhoneNumber: {
        return getPhoneNumberValue(value);
      }
      case Reporting_ReportCellType.Chip: {
        return getChipValue(value);
      }
      case Reporting_ReportCellType.HourMinutes: {
        return formatMinutesToHours(value);
      }
      default: {
        if (typeof value.value === 'boolean') return getBooleanValue(value);

        return getRawValue(value);
      }
    }
  };

  const formatValue = (
    column: Reporting_TableReportField,
    value:
      | ExtendedReportData[number]
      | ExtendedReportData[number]['value']
      | undefined,
  ) => {
    if (!value) return '';

    const valueObject = (
      Object.hasOwn(value, 'value') ? value : { value }
    ) as ExtendedReportData[number];

    switch (column.cellType) {
      case Reporting_ReportCellType.Person: {
        return getPersonValue(valueObject);
      }
      case Reporting_ReportCellType.PartyGroup: {
        return getPartyGroupValue(valueObject);
      }
      case Reporting_ReportCellType.Date: {
        return getFormattedDateValue(valueObject, column);
      }
      case Reporting_ReportCellType.Currency: {
        return formatCurrencyValue(valueObject, column);
      }
      case Reporting_ReportCellType.Boolean: {
        return getBooleanValue(valueObject);
      }
      case Reporting_ReportCellType.PhoneNumber: {
        return getPhoneNumberValue(valueObject);
      }
      case Reporting_ReportCellType.Chip: {
        return getChipValue(valueObject);
      }
      case Reporting_ReportCellType.HourMinutes: {
        return formatMinutesToHours(valueObject);
      }
      default: {
        return getRawValue(valueObject);
      }
    }
  };

  const renderValue = (
    column: Reporting_TableReportField,
    value: ExtendedReportData[number] | undefined,
  ) => {
    if (!value) return undefined;

    switch (column.cellType) {
      case Reporting_ReportCellType.Person: {
        return renderPersonAvatar(value, column);
      }
      case Reporting_ReportCellType.PartyGroup: {
        return renderPartyGroupAvatar(value, column);
      }
      case Reporting_ReportCellType.Date: {
        return getFormattedDateValue(value, column);
      }
      case Reporting_ReportCellType.Currency: {
        return formatCurrencyValue(value, column);
      }
      case Reporting_ReportCellType.Boolean: {
        return renderBooleanValue(value);
      }
      case Reporting_ReportCellType.PhoneNumber: {
        return getPhoneNumberValue(value);
      }
      case Reporting_ReportCellType.Chip: {
        return renderChipValue(value, column);
      }
      case Reporting_ReportCellType.HourMinutes: {
        return formatMinutesToHours(value);
      }
      default: {
        if (typeof value.value === 'boolean') return renderBooleanValue(value);

        return renderRawValue(value, column);
      }
    }
  };

  const valueComparator = (column: Reporting_TableReportField) => {
    switch (column.cellType) {
      case Reporting_ReportCellType.Date: {
        return (
          dateA: string,
          dateB: string,
          nodeA: IRowNode<ExtendedReportData>,
          nodeB: IRowNode<ExtendedReportData>,
        ) =>
          dayjs((nodeA?.data?.[column.id]?.value ?? dateA) as string).unix() -
          dayjs((nodeB?.data?.[column.id]?.value ?? dateB) as string).unix();
      }
      case Reporting_ReportCellType.HourMinutes: {
        return (
          valueA: string,
          valueB: string,
          nodeA: IRowNode<ExtendedReportData>,
          nodeB: IRowNode<ExtendedReportData>,
        ) => {
          return (
            Number(nodeA?.data?.[column.id]?.value ?? valueA) -
            Number(nodeB?.data?.[column.id]?.value ?? valueB)
          );
        };
      }
      default: {
        return undefined;
      }
    }
  };

  const buildCellClass = (
    column: Reporting_TableReportField,
    value: ExtendedReportData[number] | undefined,
  ) => {
    const cellClass: string[] = [];

    switch (column.cellType) {
      case Reporting_ReportCellType.Person: {
        const valueAsStudent = value?.value as ReportStudentPerson;
        if (valueAsStudent?.aenFlag) {
          cellClass.push('cell-value-visible');
        }
        break;
      }
      case Reporting_ReportCellType.Number: {
        cellClass.push('ag-right-aligned-cell');
        break;
      }
      default: {
        break;
      }
    }

    return cellClass;
  };

  const buildCellStyle = (
    column: Reporting_TableReportField,
    value: ExtendedReportData[number] | undefined,
  ) => {
    const { colour } = value || {};

    const baseColorKey = colour?.colour as keyof Palette;
    const shadeColorKey = colour?.shade as keyof Color;
    const baseColor = palette?.[baseColorKey] as Color;

    const commonStyle = {
      backgroundColor: baseColor?.[shadeColorKey] ?? '',
    };

    switch (column.cellType) {
      case Reporting_ReportCellType.Raw:
        return {
          ...commonStyle,
          lineHeight: 2,
          paddingTop: 12,
          paddingBottom: 12,
          wordBreak: 'break-word',
        };
      default:
        return commonStyle;
    }
  };

  const buildHeaderClass = (column: Reporting_TableReportField) => {
    const headerClass: string[] = [];

    switch (column.cellType) {
      case Reporting_ReportCellType.Number: {
        headerClass.push('ag-right-aligned-cell');
        break;
      }
      default: {
        break;
      }
    }

    return headerClass;
  };

  const getColumnFilterSettings = (column: Reporting_TableReportField) => {
    switch (column.cellType) {
      case Reporting_ReportCellType.Date: {
        return getDateFilterTableSettings();
      }
      default: {
        return {
          filter: true,
        };
      }
    }
  };

  const aggFunc = (column: Reporting_TableReportField) => {
    switch (column.cellType) {
      case Reporting_ReportCellType.Number:
      case Reporting_ReportCellType.Currency: {
        return 'sum';
      }
      default: {
        return undefined;
      }
    }
  };

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  type ReportFieldMapping<T extends Record<string, any>> = {
    [K in keyof T]?: {
      textValue: string;
      typedValue: T[K];
      renderedValue: ReturnType<typeof renderValue>;
    };
  };

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  const mapField = <T extends Record<string, any>>(
    reportData: ReturnTypeFromUseRunReports | null | undefined,
  ): ReportFieldMapping<T>[] => {
    if (!reportData) return [];

    type ColumnsByKeys = Record<keyof T, Reporting_TableReportField>;

    const columnsByKeys = (reportData?.fields || []).reduce<ColumnsByKeys>(
      (keys, field) => {
        keys[field.id as keyof T] = field;
        return keys;
      },
      {} as ColumnsByKeys,
    );

    const typedData = (reportData.data || []) as Record<
      keyof T,
      ExtendedReportData[number]
    >[];

    return typedData.map((data) =>
      Object.keys(columnsByKeys).reduce(
        (mappedField, key) => {
          const typedKey = key as keyof T;
          const currentData = data[typedKey];
          const currentColumn = columnsByKeys[typedKey];

          mappedField[typedKey] = {
            typedValue: currentData?.value as T[keyof T],
            textValue: String(getValue(currentColumn, currentData) ?? ''),
            renderedValue: renderValue(currentColumn, currentData),
          };

          return mappedField;
        },
        {} as ReportFieldMapping<T>,
      ),
    );
  };

  return {
    getValue,
    formatValue,
    renderValue,
    valueComparator,
    getColumnFilterSettings,
    aggFunc,
    buildHeaderClass,
    buildCellClass,
    buildCellStyle,
    mapField,
  };
};

import { Chip, Stack } from '@mui/material';
import { useMailSettings } from '../../store/mail-settings';

export function MailCountLabel() {
  const { partyUnreadCount, schoolUnreadCount } = useMailSettings();

  return (
    <Stack direction="row" spacing={0.5}>
      {schoolUnreadCount > 0 && (
        <Chip
          label={schoolUnreadCount}
          size="small"
          variant="soft"
          color="primary"
          sx={{
            borderRadius: 0.75,
          }}
        />
      )}
      {partyUnreadCount > 0 && (
        <Chip
          label={partyUnreadCount}
          size="small"
          variant="soft"
          color="error"
          sx={{
            borderRadius: 0.75,
          }}
        />
      )}
    </Stack>
  );
}

import { Stack, Typography } from '@mui/material';
import type { UseQueryReturnType } from '@tyro/api';
import {
  Avatar,
  type CustomCellEditorProps,
  TableAutocomplete,
  usePreferredNameLayout,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useStaffForSelect } from '../../api/staff';
import { usePeopleAutocompleteProps } from './use-people-autocomplete-props';

type ReturnTypeFromUseStaffForSelect = UseQueryReturnType<
  typeof useStaffForSelect
>[number];

export function TableStaffAutocomplete(
  props: CustomCellEditorProps<
    unknown,
    ReturnTypeFromUseStaffForSelect[] | null
  >,
) {
  const { t } = useTranslation(['common']);
  const { data, isLoading } = useStaffForSelect({});
  const { displayName } = usePreferredNameLayout();

  const { getOptionLabel, optionIdKey, filterOptions } =
    usePeopleAutocompleteProps<ReturnTypeFromUseStaffForSelect>();

  return (
    <TableAutocomplete<ReturnTypeFromUseStaffForSelect | null>
      {...props}
      value={
        Array.isArray(props.value) && props.value.length > 0
          ? props.value[0]
          : null
      }
      onValueChange={(newValue) => {
        const typedValue = newValue as ReturnTypeFromUseStaffForSelect;
        props.onValueChange(typedValue ? [typedValue] : []);
      }}
      options={data ?? []}
      getOptionLabel={(option) =>
        option && getOptionLabel ? getOptionLabel(option) : ''
      }
      optionIdKey={optionIdKey}
      AutocompleteProps={{
        // @ts-expect-error
        filterOptions,
        autoHighlight: true,
        loading: isLoading,
        loadingText: t('common:loading'),
        renderOption: (optionProps, option) => {
          const name = displayName(option);
          return (
            <Stack component="li" direction="row" spacing={1} {...optionProps}>
              <Avatar
                name={name}
                src={option?.avatarUrl}
                person={option}
                sx={{ width: 32, height: 32, fontSize: '0.75rem' }}
              />
              <Stack>
                <Typography variant="subtitle2">{name}</Typography>
              </Stack>
            </Stack>
          );
        },
      }}
    />
  );
}

import { CalendarGridPeriodType, type UseQueryReturnType } from '@tyro/api';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { useMemo } from 'react';
import { type usePartyTimetable, useTimetableDayInfo } from '../api/timetable';

dayjs.extend(isBetween);

type TimetableData = UseQueryReturnType<typeof usePartyTimetable>;

function hasOverlap(
  eventA: {
    startTime: string;
    endTime: string;
  },
  eventB: {
    startTime: string;
    endTime: string;
  },
) {
  const aStartTime = dayjs(eventA.startTime);
  const aEndTime = dayjs(eventA.endTime);
  const bStartTime = dayjs(eventB.startTime);
  const bEndTime = dayjs(eventB.endTime);

  // Check if one event starts before the other ends
  // and ends after the other starts
  return (
    (aStartTime.isBefore(bEndTime) && aEndTime.isAfter(bStartTime)) ||
    (bStartTime.isBefore(aEndTime) && bEndTime.isAfter(aStartTime))
  );
}

export function useTimetableInPeriods(
  date: dayjs.Dayjs,
  timetableData: TimetableData | undefined,
) {
  const { data: timetableDayInfo } = useTimetableDayInfo(date);

  return useMemo(() => {
    const { events, gridIds } = timetableData ?? { events: [], gridIds: [] };
    const hasNoGridIdsSet = gridIds.length === 0;
    const periods =
      timetableDayInfo?.gridPeriods
        .flatMap((grid) =>
          gridIds.includes(grid?.gridIdx ?? -1) || hasNoGridIdsSet
            ? grid?.periods
            : [],
        )
        .sort((a, b) => dayjs(a.startTime).diff(dayjs(b.startTime))) ?? [];

    const blankPeriods = periods
      .filter((period, index, periods) => {
        const previousPeriod = periods[index - 1] ?? null;
        const previousPeriodCheck =
          previousPeriod === null
            ? false
            : dayjs(previousPeriod.startTime).isSame(dayjs(period.startTime)) &&
              previousPeriod.type === period.type;
        return (
          events.every((event) => hasOverlap(period, event) === false) &&
          !previousPeriodCheck
        );
      })
      .map((period) => ({
        type: period?.type,
        startTime: period?.startTime,
        endTime: period?.endTime,
        event: undefined,
      }));

    const dayStartTime = periods[0]?.startTime;
    const dayEndTime = periods[periods.length - 1]?.endTime;

    const eventsInSchoolTime =
      dayStartTime && dayEndTime
        ? events.filter((event) =>
            hasOverlap(event, { startTime: dayStartTime, endTime: dayEndTime }),
          )
        : [];

    const eventsBeforeSchool =
      dayStartTime && dayEndTime
        ? events.filter(
            (event) =>
              !hasOverlap(event, {
                startTime: dayStartTime,
                endTime: dayEndTime,
              }) && dayjs(event.startTime).isBefore(dayStartTime),
          )
        : [];

    const eventsAfterSchool =
      dayStartTime && dayEndTime
        ? events.filter(
            (event) =>
              !hasOverlap(event, {
                startTime: dayStartTime,
                endTime: dayEndTime,
              }) && dayjs(event.endTime).isAfter(dayEndTime),
          )
        : [];

    const mappedEvents = [
      ...eventsBeforeSchool,
      ...eventsInSchoolTime,
      ...eventsAfterSchool,
    ].map((event) => ({
      type: CalendarGridPeriodType.Class,
      startTime: event.startTime,
      endTime: event.endTime,
      event,
    }));

    return {
      ...timetableDayInfo,
      periods: [...mappedEvents, ...blankPeriods].sort((a, b) =>
        dayjs(a.startTime).diff(dayjs(b.startTime)),
      ),
      numberOfEventsBeforeSchool: eventsBeforeSchool.length,
      numberOfEventsAfterSchool: eventsAfterSchool.length,
    };
  }, [timetableDayInfo, timetableData]);
}

import type { Catalogue_GroupTagFilter } from '@tyro/api';
import {
  Autocomplete,
  type AutocompleteProps,
  RHFAutocomplete,
  type RHFAutocompleteProps,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useEffect } from 'react';
import type { FieldValues } from 'react-hook-form';
import {
  type ReturnTypeOfUseCatalogueGroupTags,
  useCatalogueGroupTags,
} from '../../api/common/group-tags';

export type GroupTagOption = ReturnTypeOfUseCatalogueGroupTags;

type RHFGroupTagsAutocompleteProps<TField extends FieldValues> = Omit<
  RHFAutocompleteProps<TField, GroupTagOption>,
  'options'
> & { filter?: Catalogue_GroupTagFilter };

export const RHFGroupTagsAutocomplete = <TField extends FieldValues>({
  filter,
  ...props
}: RHFGroupTagsAutocompleteProps<TField>) => {
  const { t } = useTranslation(['groups']);
  const { data: groupTagData, isLoading } = useCatalogueGroupTags(filter ?? {});

  return (
    <RHFAutocomplete<TField, GroupTagOption>
      label={t('groups:groupTags')}
      {...props}
      fullWidth
      optionIdKey="id"
      optionTextKey="name"
      loading={isLoading}
      options={groupTagData ?? []}
    />
  );
};

export type GroupTagsAutocompleteProps = Omit<
  AutocompleteProps<GroupTagOption>,
  | 'optionIdKey'
  | 'optionTextKey'
  | 'getOptionLabel'
  | 'filterOptions'
  | 'renderAvatarTags'
  | 'renderAvatarOption'
  | 'renderAvatarAdornment'
  | 'options'
  | 'onChange'
> & {
  filter?: Catalogue_GroupTagFilter;
  autoPopulate?: boolean;
  onChange: (value: AutocompleteProps<GroupTagOption>['value']) => void;
};

export const GroupTagsAutocomplete = ({
  filter,
  autoPopulate,
  value,
  onChange,
  ...props
}: GroupTagsAutocompleteProps) => {
  const { t } = useTranslation(['groups']);
  const { data: groupTagData = [], isLoading } = useCatalogueGroupTags(
    filter ?? {},
  );

  useEffect(() => {
    if (
      autoPopulate &&
      groupTagData.length > 0 &&
      (!value || (Array.isArray(value) && value.length === 0))
    ) {
      const newValue = groupTagData[0];
      onChange(props.multiple ? [newValue] : newValue);
    }
  }, [autoPopulate, groupTagData]);

  return (
    <Autocomplete<ReturnTypeOfUseCatalogueGroupTags>
      label={t('groups:groupTags')}
      value={value}
      onChange={(_, newValue) => onChange(newValue)}
      fullWidth
      options={groupTagData}
      loading={isLoading}
      optionIdKey="id"
      optionTextKey="name"
      {...props}
    />
  );
};

import { ConfirmDialog } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useDeleteFormSubmission } from '../api/delete-form-submission';
import type { ReturnTypeFromUseSearchFormsSubmissions } from '../api/search-forms-submissions';

export interface DeleteFormSubmissionConfirmModalProps {
  open: boolean;
  formToDelete: Pick<
    ReturnTypeFromUseSearchFormsSubmissions,
    'id' | 'title'
  > | null;
  onClose: () => void;
}

export function DeleteFormSubmissionConfirmModal({
  open,
  formToDelete,
  onClose,
}: DeleteFormSubmissionConfirmModalProps) {
  const { t } = useTranslation(['common', 'forms']);

  const { mutate: deleteFormSubmission } = useDeleteFormSubmission();

  const onSubmit = () => {
    if (formToDelete?.id?.objectId) {
      const { objectId, provider, forPartyId } = formToDelete.id;

      deleteFormSubmission(
        { objectId, provider, forPartyId },
        { onSuccess: onClose },
      );
    }
  };

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      onConfirm={onSubmit}
      title={t('forms:deleteForm')}
      description={t('forms:deleteFormConfirmation', {
        name: formToDelete?.title,
      })}
      confirmText={t('common:actions.delete')}
    />
  );
}

import { useQuery } from '@tanstack/react-query';
import {
  type Notes_BehaviourFilter,
  type QueryAdditionalOptions,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { peopleKeys } from '../keys';

const individualStudentBehaviour = graphql(/* GraphQL */ `
  query notes_behaviour($filter: Notes_BehaviourFilter) {
    notes_behaviour(filter: $filter) {
      behaviours {
        noteId
        incidentDate
        referencedParties {
          partyId
          firstName
          lastName
          avatarUrl
          type
        }
        associatedParties {
          __typename
          partyId
          ... on SubjectGroup {
            name
            subjects {
              name
              colour
            }
          }
          ... on Student {
            person {
              partyId
              firstName
              lastName
              avatarUrl
              type
            }
          }
        }
        associatedPartyIds
        category
        details
        takenByPartyId
        takenBy {
          partyId
          firstName
          lastName
          avatarUrl
          type
        }
        tags {
          id
          name
          description
          behaviourType
          points
        }
        tagIds
        points
      }
      categories {
        behaviourCategoryId
        name
        count
        behaviourType
      }
    }
  }
`);

const studentBehaviourQuery = (filter: Notes_BehaviourFilter) => ({
  queryKey: peopleKeys.students.individualStudentBehaviours(filter),
  queryFn: async () =>
    gqlClient.request(individualStudentBehaviour, { filter }),
});

export function getStudentBehaviour(filter: Notes_BehaviourFilter) {
  return queryClient.fetchQuery(studentBehaviourQuery(filter));
}

export function useStudentBehaviour(
  filter: Notes_BehaviourFilter,
  additionalOptions?: Omit<QueryAdditionalOptions, 'academicNamespaceId'>,
) {
  return useQuery({
    ...studentBehaviourQuery(filter),
    ...additionalOptions,
    select: ({ notes_behaviour }) =>
      notes_behaviour ?? { behaviours: [], categories: [] },
  });
}

export type ReturnTypeFromUseStudentBehaviour = UseQueryReturnType<
  typeof useStudentBehaviour
>;

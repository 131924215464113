import { useQuery } from '@tanstack/react-query';
import {
  type Rules_TriggeredViewFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { rulesKeys } from './keys';

const rulesViewTriggered = graphql(/* GraphQL */ `
  query rules_viewTriggered($filter: Rules_TriggeredViewFilter!) {
    rules_viewTriggered(input: $filter) {
      ruleTriggerId
      ruleId
      ruleType
      entityId
      context
      triggeredOn
      createdByUserId
      party {
        ... on Student {
          __typename
          person {
            partyId
            firstName
            lastName
            avatarUrl
          }
        }
        ... on Staff {
          __typename
          person {
            partyId
            firstName
            lastName
            avatarUrl
          }
        }
        ... on StudentContact {
          __typename
          person {
            partyId
            firstName
            lastName
            avatarUrl
          }
        }
        ... on SubjectGroup {
          __typename
          partyId
          name
          avatarUrl
        }
        ... on GeneralGroup {
          __typename
          partyId
          name
        }
        ... on YearGroupEnrollment {
          __typename
          partyId
          name
        }
        ... on ProgrammeStageEnrollment {
          __typename
          partyId
          name
        }
      }
    }
  }
`);

const rulesViewTriggeredQuery = (filter: Rules_TriggeredViewFilter) => ({
  queryKey: rulesKeys.viewTriggered(filter),
  queryFn: () => gqlClient.request(rulesViewTriggered, { filter }),
});

export function getRulesViewTriggered(filter: Rules_TriggeredViewFilter) {
  return queryClient.fetchQuery(rulesViewTriggeredQuery(filter));
}

export function useRulesViewTriggered(filter: Rules_TriggeredViewFilter) {
  return useQuery({
    ...rulesViewTriggeredQuery(filter),
    select: ({ rules_viewTriggered }) => rules_viewTriggered,
  });
}

export type ReturnTypeFromUseRulesViewTriggered = UseQueryReturnType<
  typeof useRulesViewTriggered
>[number];

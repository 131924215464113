import { useMutation } from '@tanstack/react-query';

import {
  type Notes_BehaviourResetPoints,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { reportsKeys } from '@tyro/reporting';
import { peopleKeys } from '../keys';

const resetPoints = graphql(/* GraphQL */ `
mutation notes_resetBehaviourPoints($input: Notes_BehaviourResetPoints) {
  notes_resetBehaviourPoints(input: $input) {
    success
  }
}
`);

export function useResetPoints() {
  const { t } = useTranslation(['common']);
  const { toast } = useToast();

  return useMutation({
    mutationFn: async (input: Notes_BehaviourResetPoints) =>
      gqlClient.request(resetPoints, { input }),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: peopleKeys.students.all() }),
        queryClient.invalidateQueries({ queryKey: reportsKeys.all }),
      ]);

      toast(t('common:snackbarMessages.resetSuccess'));
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

import { Box, List, Stack } from '@mui/material';
import { useTranslation } from '@tyro/i18n';
import type { NavSectionProps } from '../types';
import NavList from './NavList';
import { StyledSubheader } from './styles';

export default function NavSectionVertical({
  data,
  sx,
  ...other
}: NavSectionProps) {
  const { t } = useTranslation(['navigation']);
  return (
    <Stack sx={sx} {...other}>
      <Box
        component="a"
        href="#main-content"
        sx={{
          textDecoration: 'none',
          color: 'indigo.600',
          fontWeight: 600,
          fontSize: '0.875rem',
          backgroundColor: 'indigo.100',
          px: 2,
          py: 1.5,
          mx: 2,
          borderRadius: 1,
          outlineColor: 'indigo.600',
          position: 'absolute',
          transform: 'translateX(-100vw)',

          '&:focus': {
            position: 'relative',
            transform: 'translateX(0)',
          },
        }}
      >
        {t('navigation:skipToMainContent')}
      </Box>
      {data.map((group) => {
        const key = group.subheader;

        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {group.subheader && (
              <StyledSubheader disableSticky>{group.subheader}</StyledSubheader>
            )}

            {group.children.map((list) => (
              <NavList
                key={`${list.title}${list.path ?? ''}`}
                data={list}
                depth={1}
              />
            ))}
          </List>
        );
      })}
    </Stack>
  );
}

import { LabelType } from '@tyro/api';
import type { MailLabel } from '../@types/label';

export const SystemLabelMapping = {
  [LabelType.Inbox]: 'inbox',
  [LabelType.Outbox]: 'sent',
  [LabelType.Archive]: 'archive',
  Scheduled: 'scheduled',
} as const;

export type SystemLabels =
  (typeof SystemLabelMapping)[keyof typeof SystemLabelMapping];

export function getLabelId(label: Pick<MailLabel, 'type' | 'id'>) {
  return label.type === LabelType.Custom
    ? label.id
    : SystemLabelMapping[label.type];
}

export function getLabelById(
  labelId: SystemLabels | string,
  labels: MailLabel[] = [],
): MailLabel | undefined {
  const matchedSystemLabel = Object.entries(SystemLabelMapping).find(
    ([, value]) => value === labelId,
  );

  return labels.find(({ type, originalId }) =>
    matchedSystemLabel
      ? type === matchedSystemLabel[0]
      : originalId === Number(labelId),
  );
}

import type {
  FormsAdminTagsFilter,
  FormsAdmin_FormDefinitionViewFilter,
  FormsAdmin_FormDefinitionsFilter,
  FormsAdmin_InformationRequestFilter,
  FormsAdmin_InformationRequestTableFilter,
  Forms_SearchSubmissionFilter,
  Forms_ViewSubmissionFilter,
} from '@tyro/api';

export const formsKeys = {
  all: ['forms'] as const,
  tags: (filter: FormsAdminTagsFilter) =>
    [...formsKeys.all, 'tags', filter] as const,
  definitions: (filter: FormsAdmin_FormDefinitionsFilter) =>
    [...formsKeys.all, 'definitions', filter] as const,
  previewFormDefinition: (filter: FormsAdmin_FormDefinitionViewFilter) =>
    [...formsKeys.all, 'previewFormDefinition', filter] as const,
  searchFormsSubmissions: (filter: Forms_SearchSubmissionFilter) =>
    [...formsKeys.all, 'searchFormsSubmissions', filter] as const,
  viewSubmissions: (filter: Forms_ViewSubmissionFilter) =>
    [...formsKeys.all, 'viewSubmissions', filter] as const,
  infoRequestTableView: (filter: FormsAdmin_InformationRequestTableFilter) =>
    [...formsKeys.all, 'infoRequestTableView', filter] as const,
  formRequests: (filter: FormsAdmin_InformationRequestFilter) =>
    [...formsKeys.all, 'formRequests', filter] as const,
};

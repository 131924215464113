import {
  Box,
  Chip,
  type ChipProps,
  Stack,
  type SxProps,
  type TextFieldProps,
  type Theme,
  Typography,
} from '@mui/material';
import type { AttendanceCodeType } from '@tyro/api';
import { Autocomplete, getColourBasedOnAttendanceType } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { type ForwardedRef, forwardRef, useMemo } from 'react';
import {
  type FieldValues,
  type UseControllerProps,
  useController,
} from 'react-hook-form';
import {
  type ReturnTypeFromUseAttendanceCodes,
  useAttendanceCodes,
} from '../api/attendance-codes';
import { iconBasedOnCodeType } from './role-book/attendance-value';

type AttendanceCodesWithoutNotTaken = Exclude<
  AttendanceCodeType,
  AttendanceCodeType.NotTaken
>;

interface AttendanceCodeFilterProps {
  value: ReturnTypeFromUseAttendanceCodes[];
  onChange: (value: ReturnTypeFromUseAttendanceCodes[]) => void;
  sx?: SxProps<Theme>;
  variant?: TextFieldProps['variant'] | 'white-filled';
}

export const AttendanceCodeFilter = forwardRef(
  (
    { value, onChange, sx, variant }: AttendanceCodeFilterProps,
    ref: ForwardedRef<unknown>,
  ) => {
    const { t } = useTranslation(['attendance']);
    const { data, isLoading } = useAttendanceCodes({ active: true });

    return (
      <Autocomplete<ReturnTypeFromUseAttendanceCodes>
        customRef={ref}
        value={value}
        onChange={(_, newValue) => {
          onChange((newValue as ReturnTypeFromUseAttendanceCodes[]) ?? []);
        }}
        label={t('attendance:attendanceCodes')}
        fullWidth
        options={data ?? []}
        loading={isLoading}
        multiple
        sx={{
          maxWidth: 300,
          ...sx,
        }}
        inputProps={{
          variant,
        }}
        optionIdKey="id"
        optionTextKey="name"
        filterOptions={(options, { inputValue }) => {
          if (!inputValue) return options;

          const searchValue = inputValue.toLowerCase();
          return options.filter((option) => {
            const code = option?.name;
            const description = option?.description;
            return (
              code?.toLowerCase().includes(searchValue) ||
              description?.toLowerCase().includes(searchValue)
            );
          });
        }}
        renderTags={(tags, getTagProps) =>
          tags.map((tag, index) => {
            const chipColor = getColourBasedOnAttendanceType(
              tag.sessionCodeType,
            ).base;
            const { key, ...tagProps } = getTagProps({ index });

            return (
              <Chip
                key={key}
                {...tagProps}
                size="small"
                variant="soft"
                color={chipColor as ChipProps['color']}
                label={tag.name}
              />
            );
          })
        }
        renderOption={(props, option) => {
          const color = getColourBasedOnAttendanceType(
            option.sessionCodeType,
          ).base;

          const icon =
            iconBasedOnCodeType[
              option.sessionCodeType as AttendanceCodesWithoutNotTaken
            ];
          return (
            <Stack
              component="li"
              direction="row"
              spacing={1}
              justifyContent="space-between"
              {...props}
              key={option.id}
            >
              <Stack direction="row" spacing={1} alignItems="center" flex="1">
                <Box display="flex" alignItems="center" color={`${color}.main`}>
                  {icon}
                </Box>
                <Typography component="span" variant="subtitle2">
                  {option.description}
                </Typography>
              </Stack>
              <Typography
                component="span"
                color={`${color}.main`}
                variant="subtitle2"
              >
                {option.name}
              </Typography>
            </Stack>
          );
        }}
      />
    );
  },
);

if (process.env.NODE_ENV !== 'production') {
  AttendanceCodeFilter.displayName = 'AttendanceCodeFilter';
}

type RHFAttendanceCodeFilterProps<TField extends FieldValues> = Omit<
  AttendanceCodeFilterProps,
  'value' | 'onChange'
> & {
  controlProps: UseControllerProps<TField>;
};

export function RHFAttendanceCodeFilter<TField extends FieldValues>({
  controlProps,
  ...restProps
}: RHFAttendanceCodeFilterProps<TField>) {
  const {
    field: { value, ...restField },
  } = useController(controlProps);

  return (
    <AttendanceCodeFilter
      {...restProps}
      {...restField}
      value={Array.isArray(value) ? value : []}
    />
  );
}

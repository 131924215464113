import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import type { CalendarParty } from '@tyro/calendar';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useDebouncedValue,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import {
  EditCalendarIcon,
  LabelsIcon,
  NewTemplateIcon,
  UserGroupIcon,
} from '@tyro/icons';
import type { RoomSelect } from '@tyro/settings';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';

type PrintUserNavigationState =
  | {
      areas: ('user-data' | 'labels' | 'letter-templates' | 'timetable')[];
      contextType: 'students' | 'staff' | 'years' | 'class';
      parties: CalendarParty[];
    }
  | {
      areas: 'timetable';
      contextType: 'rooms';
      rooms: RoomSelect[];
    }
  | {
      areas: 'assessment';
      academicNamespaceId: number;
      assessmentId: number;
    };

type TemplatePrintConfigs = Extract<
  PrintUserNavigationState,
  { areas: ('user-data' | 'labels' | 'letter-templates' | 'timetable')[] }
>;

type SingleStringArea<Area extends string> = Omit<
  Extract<PrintUserNavigationState, { areas: Area }>,
  'areas'
> & {
  area: Area;
};

type NavigationRequestState =
  | (Omit<TemplatePrintConfigs, 'areas'> & {
      area: TemplatePrintConfigs['areas'][number];
    })
  | SingleStringArea<'timetable'>
  | SingleStringArea<'assessment'>;

const iconByPrintArea = new Map([
  [
    'user-data',
    {
      textKey: 'navigation:management.printing.userData',
      icon: UserGroupIcon,
    } as const,
  ],
  [
    'labels',
    {
      textKey: 'navigation:management.printing.labels',
      icon: LabelsIcon,
    } as const,
  ],
  [
    'letter-templates',
    {
      textKey: 'navigation:management.printing.letterTemplates',
      icon: NewTemplateIcon,
    } as const,
  ],
  [
    'timetable',
    {
      textKey: 'navigation:management.printing.timetable',
      icon: EditCalendarIcon,
    } as const,
  ],
]);

export function PrintNavigationModal({
  open,
  onClose,
  redirectSettings: {
    areas,
    ...redirectSettings
  } = {} as PrintUserNavigationState,
  navigateToPrint,
}: PrintNavigationModalProps & {
  navigateToPrint: (state: NavigationRequestState) => void;
}) {
  const { t } = useTranslation(['navigation', 'printing']);
  const sanitizedAreas =
    areas && typeof areas === 'string' ? [areas] : areas ?? [];

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle onClose={onClose}>
        {t('printing:whatWouldYouLikeToPrint')}
      </DialogTitle>
      <DialogContent sx={{ pb: 4 }}>
        <List sx={{ display: 'flex', justifyContent: 'flex-start', mt: 1 }}>
          {sanitizedAreas.map((area) => {
            const { textKey, icon: Icon } = iconByPrintArea.get(area)!;
            return (
              <ListItem
                key={area}
                disablePadding
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 'auto',
                  marginRight: '24px',
                }}
              >
                <ListItemButton
                  onClick={() =>
                    navigateToPrint({
                      ...redirectSettings!,
                      area,
                    } as NavigationRequestState)
                  }
                  sx={({ palette }) => ({
                    justifyContent: 'center',
                    width: '74px',
                    height: '80px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: palette.indigo[100],
                    borderRadius: 1,
                    color: palette.indigo.main,
                  })}
                >
                  <Icon />
                </ListItemButton>
                <ListItemText
                  sx={{
                    fontSize: '14px',
                    marginTop: 1,
                    '& >span': {
                      fontSize: '14px',
                      color: 'slate.800',
                    },
                  }}
                >
                  {t(textKey)}
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
}

export interface PrintNavigationModalProps {
  open: boolean;
  onClose: () => void;
  redirectSettings?: PrintUserNavigationState;
}

export function useNavigateToPrintArea() {
  const navigate = useNavigate();
  const { value, debouncedValue, setValue } = useDebouncedValue<
    PrintUserNavigationState | undefined
  >({});

  const navigateToPrint = useCallback((state: NavigationRequestState) => {
    if (state.area === 'assessment') {
      navigate('/printing/assessment', {
        state,
      });
    } else if (state.area === 'timetable' && state.contextType === 'rooms') {
      navigate(`/printing/timetable/${state.contextType}`, {
        state,
      });
    } else {
      const checkedContext =
        state.area !== 'timetable' &&
        ['years', 'class'].includes(state.contextType)
          ? 'students'
          : state.contextType;

      navigate(`/printing/${state.area}/${checkedContext}`, {
        state,
      });
    }
  }, []);

  return useMemo(
    () => ({
      navigateToPrint: setValue,
      printNavigationModal: (
        <PrintNavigationModal
          open={!!value}
          onClose={() => setValue(undefined)}
          redirectSettings={value ?? debouncedValue}
          navigateToPrint={navigateToPrint}
        />
      ),
    }),
    [navigateToPrint, value, debouncedValue, setValue],
  );
}

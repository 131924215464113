import { useQuery } from '@tanstack/react-query';
import {
  type Notes_BehaviourCategoryFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { peopleKeys } from '../keys';

const behaviourCategories = graphql(/* GraphQL */ `
  query notes_behaviourCategories($filter: Notes_BehaviourCategoryFilter) {
    notes_behaviourCategories(filter: $filter) {
      behaviourCategoryId
      name
      description
      behaviourType
    }
  }
`);

const behaviourCategoriesQuery = (filter: Notes_BehaviourCategoryFilter) => ({
  queryKey: peopleKeys.notes.behaviourCategories(),
  queryFn: async () => {
    const { notes_behaviourCategories: categories } = await gqlClient.request(
      behaviourCategories,
      {
        filter,
      },
    );

    return categories.sort((prev, next) => prev.name.localeCompare(next.name));
  },
});

export function getBehaviourCategories(filter: Notes_BehaviourCategoryFilter) {
  return queryClient.fetchQuery(behaviourCategoriesQuery(filter));
}

export function useBehaviourCategory(filter: Notes_BehaviourCategoryFilter) {
  return useQuery({
    ...behaviourCategoriesQuery(filter),
    select: (categories) => categories,
  });
}

export type ReturnTypeFromUseBehaviourCategory = UseQueryReturnType<
  typeof useBehaviourCategory
>[number];

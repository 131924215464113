import { useMutation, useQuery } from '@tanstack/react-query';

import {
  TtSolve_Operation,
  type TtSolve_RunSolverParams,
  type TtSolve_SolveParams,
  Tt_SolverStatus,
  type Ttsolve_StatusQuery,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useCallback, useEffect, useRef } from 'react';
import { timetableKeys } from '../keys';

const solveStatus = graphql(/* GraphQL */ `
  query ttsolve_status($filter: TtSolve_SolveParams!) {
    ttsolve_status(filter: $filter) {
      score
      stats {
        lessonsTotal
        lessonsScheduled
        lessonsWithRoom
        lessonsWithTeacher
        spreadsExpected
        spreadsAchieved
      }
      solverStatus
      groupTagStats {
        groupTagId
        stats {
          lessonsTotal
          lessonsScheduled
          lessonsWithRoom
          lessonsWithTeacher
        }
      }
      solverRunType
    }
  }
`);

const solveTimetable = /* GraphQL */ `
  mutation ttsolve_solve($input: TtSolve_RunSolverParams) {
    ttsolve_solve(input: $input) {
      success
    }
  }
`;

const solveStatusQuery = (filter: TtSolve_SolveParams) => ({
  queryKey: timetableKeys.solveStatus(filter),
  queryFn: async () =>
    gqlClient.request(solveStatus, {
      filter,
    }),
});

export async function getTtSolveStatus(filter: TtSolve_SolveParams) {
  return queryClient.fetchQuery(solveStatusQuery(filter));
}

export function useTtSolveStatus(filter: TtSolve_SolveParams, enabled = true) {
  const previousStatusRef = useRef<Tt_SolverStatus | null>(null);
  const query = useQuery({
    ...solveStatusQuery(filter),
    select: useCallback(
      ({ ttsolve_status }: Ttsolve_StatusQuery) => ({
        ...ttsolve_status,
        groupTagStats: new Map(
          ttsolve_status.groupTagStats.map(({ groupTagId, stats }) => [
            groupTagId,
            stats,
          ]),
        ),
      }),
      [],
    ),
    enabled,
    refetchInterval: (query) => {
      const solverStatus = query.state.data?.ttsolve_status.solverStatus;
      if (solverStatus === Tt_SolverStatus.SolvingScheduled) return 1500;
      if (solverStatus === Tt_SolverStatus.SolvingActive) return 5000;

      return false;
    },
  });

  useEffect(() => {
    if (
      previousStatusRef.current !== null &&
      previousStatusRef.current === Tt_SolverStatus.SolvingActive &&
      query?.data?.solverStatus !== Tt_SolverStatus.SolvingScheduled &&
      query?.data?.solverStatus !== Tt_SolverStatus.SolvingActive
    ) {
      queryClient.invalidateQueries({ queryKey: timetableKeys.all });
    }
    previousStatusRef.current = query?.data?.solverStatus ?? null;
  }, [query?.data?.solverStatus]);

  return query;
}

export function useSolveTimetable() {
  const { toast } = useToast();
  const { t } = useTranslation(['common', 'timetable']);

  return useMutation({
    mutationFn: async (input: TtSolve_RunSolverParams) =>
      gqlClient.request(solveTimetable, { input }),
    onSuccess: (_, { operation }) => {
      toast(
        operation === TtSolve_Operation.Stop
          ? t('timetable:stoppedSuccessfully')
          : t('timetable:queuedToSchedule'),
      );
      queryClient.invalidateQueries({
        queryKey: timetableKeys.allSolveStatuses(),
      });
      queryClient.invalidateQueries({
        queryKey: timetableKeys.allScheduleViews(),
      });
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

export type ReturnTypeFromUseTtSolveStatus = UseQueryReturnType<
  typeof useTtSolveStatus
>;

import { useMutation } from '@tanstack/react-query';

import {
  type SaveSchoolActivityAttendanceInput,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { groupsKeys } from '@tyro/groups';
import { useTranslation } from '@tyro/i18n';

import { attendanceKeys } from './keys';

const saveSchoolActivityAttendance = graphql(/* GraphQL */ `
  mutation attendance_saveSchoolActivityEventAttendance(
    $input: [SaveSchoolActivityAttendanceInput!]!
  ) {
    attendance_saveSchoolActivityEventAttendance(input: $input) {
      success
    }
  }
`);

export function useSaveSchoolActivityAttendance() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationFn: async (input: SaveSchoolActivityAttendanceInput[]) =>
      gqlClient.request(saveSchoolActivityAttendance, { input }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: attendanceKeys.all });
      await queryClient.invalidateQueries({ queryKey: groupsKeys.all });

      toast(t('common:snackbarMessages.updateSuccess'));
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

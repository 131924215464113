import { useMutation } from '@tanstack/react-query';
import {
  type SaveGradeSetInput,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { assessmentsKeys } from '../keys';

const saveGradeSet = graphql(/* GraphQL */ `
  mutation assessment_saveGradeSet($input: SaveGradeSetInput) {
    assessment_saveGradeSet(input: $input) {
      id
    }
  }
`);

export function useSaveGradeSet() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationFn: (input: SaveGradeSetInput) =>
      gqlClient.request(saveGradeSet, { input }),
    onSuccess: async (_, { id }) => {
      await queryClient.invalidateQueries({ queryKey: assessmentsKeys.all });
      toast(
        id
          ? t('common:snackbarMessages.updateSuccess')
          : t('common:snackbarMessages.createSuccess'),
      );
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

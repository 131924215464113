import { Box } from '@mui/material';
import { BaseMenuToolBar, useFormValidator, useTipTapEditor } from '@tyro/core';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSaveTemplate } from '../../api/save-templates';
import { ConfirmChangesModal } from '../modals/confirm-changes-modal';
import TemplateEditorInputField from './template-editor-input-field';
import { TemplateVariableSelector } from './template-variable-selector';
import type { TemplateFormState } from './types';

type TemplateEditorContainerProps = {
  initialState: TemplateFormState;
  onSuccess: () => void;
};

export function TemplateEditorContainer({
  onSuccess,
  initialState,
}: TemplateEditorContainerProps) {
  const {
    mutateAsync: saveTemplate,
    isPending: isSavingTemplate,
    isSuccess: hasTemplateBeenSaved,
  } = useSaveTemplate();

  const editor = useTipTapEditor({});

  const { resolver, rules } = useFormValidator<TemplateFormState>();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
    setValue,
  } = useForm<TemplateFormState>({
    resolver: resolver({
      name: rules.required(),
      context: rules.required(),
    }),
    defaultValues: initialState,
  });

  const onSubmit = handleSubmit(
    async ({ id, name, context }: TemplateFormState) => {
      if (!editor) return;

      let htmlContent = editor.getHTML();
      htmlContent = `<div class="template-wrapper">${htmlContent}</div>`;

      await saveTemplate(
        { id, template: htmlContent, name, context },
        {
          onSuccess,
        },
      );
    },
  );

  useEffect(() => {
    if (editor && initialState) {
      reset(initialState);
      setTimeout(
        () => editor.commands.setContent(initialState.template || ''),
        0,
      );
    }
  }, [editor, initialState]);

  useEffect(() => {
    const checkChanges = () => {
      setValue('template', editor?.getHTML() || '', { shouldDirty: true });
    };

    editor?.on('update', checkChanges);

    return () => {
      editor?.off('update', checkChanges);
    };
  }, [editor]);

  const [contextValue] = watch(['context']);

  if (!editor) return null;

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: 2,
        maxWidth: 'fit-content',
      }}
    >
      <BaseMenuToolBar
        editor={editor}
        customTool={
          <TemplateVariableSelector
            contextValue={contextValue}
            editor={editor}
          />
        }
      />

      <TemplateEditorInputField
        control={control}
        editor={editor}
        isSavingTemplate={isSavingTemplate}
      />

      {!hasTemplateBeenSaved && !isSavingTemplate && (
        <ConfirmChangesModal isDirty={isDirty} />
      )}
    </Box>
  );
}

import { Box, Stack, alpha, useTheme } from '@mui/material';
import { mixHexColors } from '@tyro/core';

export type BottomTabsProps = {
  componentId: string;
  tabs: {
    key: string;
    name: string;
  }[];
  value: string;
  setTab: (key: string) => void;
};

export function BottomTabs({
  componentId,
  tabs,
  value,
  setTab,
}: BottomTabsProps) {
  const { palette } = useTheme();

  const bgColor = mixHexColors(palette.indigo[50], '#fff', 0.3);

  return (
    <Stack
      role="tablist"
      direction="row"
      justifyContent="center"
      alignItems="center"
      position="absolute"
      bottom={0}
      left={0}
      width="100%"
      p={3}
      pb={1}
      sx={{
        background: `linear-gradient(180deg, ${alpha(bgColor, 0)} 0%, ${bgColor} 50%)`,
      }}
    >
      {tabs.map(({ key, name }) => (
        <Box
          component="button"
          key={key}
          role="tab"
          type="button"
          id={`${componentId}-${key}-tab`}
          aria-label={name}
          aria-selected={key === value}
          aria-controls={`${componentId}-${key}-panel`}
          onClick={() => setTab(key)}
          sx={{
            flex: 1,
            maxWidth: 86,
            height: 8,
            borderRadius: 4,
            border: '3px solid',
            borderColor: bgColor,
            backgroundColor: 'slate.300',
            boxSizing: 'content-box',
            cursor: 'pointer',
            transition: 'background-color 0.1s ease-in-out',
            '&:hover': {
              backgroundColor: 'slate.400',
            },
            '&:active': {
              backgroundColor: 'slate.500',
            },
            '&[aria-selected="true"]': {
              backgroundColor: 'slate.800',
            },
          }}
        />
      ))}
    </Stack>
  );
}

import { useQuery } from '@tanstack/react-query';
import {
  type CalendarEventRawFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { calendarKeys } from './keys';

const calendarEventsForEdit = graphql(/* GraphQL */ `
  query calendar_calendarEventsForEdit($filter: CalendarEventRawFilter!) {
    calendar_calendarEventsForEdit(filter: $filter) {
      eventId
      calendarIds
      type
      editable
      schedule {
        scheduleId
        startTime
        endTime
        startDate
        endDate
        recurrenceRule
        recurrenceEnum
        allDayEvent
      }
      name
      colour
      description
      source
      sourceId
      tags {
        label
        context
      }
      attendees {
        partyId
        type
        partyInfo {
          partyId
          __typename
          ... on GeneralGroup {
            generalGroupType
          }
          ... on PartyGroup {
            name
            avatarUrl
          }
          ... on PartyPerson {
            person {
              title {
                id
                name
                nameTextId
              }
              firstName
              lastName
              avatarUrl
            }
          }
          ... on SubjectGroup {
            subjectGroupType
          }
        }
      }
      rooms {
        room {
          roomId
          name
        }
      }
      createdBy {
        userId
        partyId
        person {
          partyId
          firstName
          lastName
          avatarUrl
        }
      }
    }
  }
`);

const eventsForEditQuery = (filter: CalendarEventRawFilter) => {
  return {
    queryKey: calendarKeys.eventsForEdit(filter),
    queryFn: async () => {
      const { calendar_calendarEventsForEdit: calendarEventsData } =
        await gqlClient.request(calendarEventsForEdit, { filter });

      return calendarEventsData.map((event) => ({
        ...event,
        rooms: event.rooms?.flatMap((room) => (room?.room ? room?.room : [])),
      }));
    },
  };
};

export function getCalendarEventsForEdit(filter: CalendarEventRawFilter) {
  return queryClient.fetchQuery(eventsForEditQuery(filter));
}

export function useCalendarEventsForEdit(
  filter: CalendarEventRawFilter,
  enabled = true,
) {
  return useQuery({
    ...eventsForEditQuery(filter),
    select: (calendarEvents) => calendarEvents,
    enabled,
  });
}

export type ReturnTypeFromUseEventForEdit = UseQueryReturnType<
  typeof useCalendarEventsForEdit
>[number];

import type {
  AccountingCodeFilter,
  CategoryFilter,
  ChargesFilter,
  DiscountFilter,
  FeeFilter,
  PaymentFilter,
  Print_FeeOptions,
  StudentFeeFilter,
} from '@tyro/api';

export const feeKeys = {
  all: ['fees'] as const,
  studentFees: (filter: StudentFeeFilter) =>
    [...feeKeys.all, 'studentFees', filter] as const,
  discounts: (filter: DiscountFilter) =>
    [...feeKeys.all, 'discounts', filter] as const,
  stripeAccount: () => [...feeKeys.all, 'stripeAccount'] as const,
  fees: (filter: FeeFilter) => [...feeKeys.all, 'fees', filter] as const,
  feesCategories: (filter: CategoryFilter) =>
    [...feeKeys.all, 'feesCategories', filter] as const,
  debtors: (filter: FeeFilter) => [...feeKeys.all, 'debtors', filter] as const,
  serviceCharge: (filter: ChargesFilter) =>
    [...feeKeys.all, 'serviceCharge', filter] as const,
  accountingCodes: (filter: AccountingCodeFilter) =>
    [...feeKeys.all, 'accountingCodes', filter] as const,
  payments: (filter: PaymentFilter) =>
    [...feeKeys.all, 'payments', filter] as const,
  printFees: (filter: Print_FeeOptions) =>
    [...feeKeys.all, 'printFees', filter] as const,
};

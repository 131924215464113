import { CalendarEventType, getUser } from '@tyro/api';
import { getCalendarEventsForEdit } from '@tyro/calendar';
import {
  type NavObjectFunction,
  NavObjectType,
  lazyWithRetry,
} from '@tyro/core';
import { RoomClockIcon } from '@tyro/icons';
import { getStaffForSelect } from '@tyro/people';

const RoomBookingsPage = lazyWithRetry(() => import('./pages/index'));
const CalendarPage = lazyWithRetry(() => import('./pages/calendar'));

export const getRoutes: NavObjectFunction = (t) => [
  {
    type: NavObjectType.Category,
    title: t('navigation:general.title'),
    children: [
      {
        type: NavObjectType.RootGroup,
        path: 'room-booking',
        title: t('navigation:general.roomBooking.title'),
        icon: <RoomClockIcon />,
        hasAccess: ({ isStaffUserHasWithAtLeastOnePermission }) =>
          isStaffUserHasWithAtLeastOnePermission([
            'ps:1:calendar:room_booking_user',
            'ps:1:calendar:room_booking_admin',
          ]),
        children: [
          {
            type: NavObjectType.MenuLink,
            title: t('navigation:general.roomBooking.calendar'),
            path: 'calendar',
            element: <CalendarPage />,
          },
          {
            type: NavObjectType.MenuLink,
            title: t('navigation:general.roomBooking.roomBookings'),
            path: 'room-bookings',
            loader: async () => {
              const { activeProfile } = await getUser();

              return Promise.all([
                getStaffForSelect({ partyIds: [activeProfile?.partyId ?? 0] }),
                getCalendarEventsForEdit({
                  eventTypes: [CalendarEventType.RoomBooking],
                }),
              ]);
            },
            element: <RoomBookingsPage />,
          },
        ],
      },
    ],
  },
];

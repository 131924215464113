import { Stack, Typography } from '@mui/material';
import type { QueryAdditionalOptions } from '@tyro/api';
import {
  Autocomplete,
  type AutocompleteProps,
  type CustomCellEditorProps,
  type ICellEditorParams,
  PreferredNameFormat,
  RHFAutocomplete,
  type RHFAutocompleteProps,
  TableAutocomplete,
  normaliseForeignCharacters,
  usePreferredNameLayout,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useMemo } from 'react';
import type { FieldValues } from 'react-hook-form';
import {
  type ReturnTypeFromUseClassGroups,
  useClassGroups,
} from '../../api/class-groups';

export interface ClassGroupSelect {
  partyId: number;
  name: string;
  tutorNames?: string;
}

type RHFClassGroupAutocompleteProps<TField extends FieldValues> = Omit<
  RHFAutocompleteProps<TField, ClassGroupSelect>,
  'options'
> & { requestOptions?: QueryAdditionalOptions };

type ClassGroupAutocompleteProps = Omit<
  AutocompleteProps<ClassGroupSelect>,
  | 'optionIdKey'
  | 'optionTextKey'
  | 'getOptionLabel'
  | 'filterOptions'
  | 'renderAvatarTags'
  | 'renderAvatarOption'
  | 'renderAvatarAdornment'
> & { requestOptions?: QueryAdditionalOptions };

export const RHFClassGroupAutocomplete = <TField extends FieldValues>({
  requestOptions,
  ...props
}: RHFClassGroupAutocompleteProps<TField>) => {
  const { t } = useTranslation(['common']);
  const { data: classGroupData, isLoading } = useClassGroups(requestOptions);
  const { displayNames } = usePreferredNameLayout();

  const classGroupOptions = useMemo(
    () =>
      classGroupData?.map((classGroup) => ({
        partyId: classGroup.partyId,
        name: classGroup.name,
        tutorNames: displayNames(classGroup.tutors, {
          format: PreferredNameFormat.FirstnameSurname,
        }),
      })),
    [classGroupData],
  );

  return (
    <RHFAutocomplete<TField, ClassGroupSelect>
      label={t('common:class')}
      renderOption={(props, option) => (
        <Stack
          component="li"
          {...props}
          sx={{ alignItems: 'flex-start !important' }}
        >
          <Typography variant="subtitle2">{option.name}</Typography>
          <Typography variant="caption">{option.tutorNames}</Typography>
        </Stack>
      )}
      filterOptions={(options, { inputValue }) => {
        if (!inputValue) {
          return options;
        }

        return options.filter((option) => {
          const lowerCaseValue = inputValue.toLowerCase();
          return (
            normaliseForeignCharacters(option.name.toLowerCase()).includes(
              lowerCaseValue,
            ) ||
            normaliseForeignCharacters(
              option?.tutorNames?.toLowerCase() ?? '',
            ).includes(lowerCaseValue)
          );
        });
      }}
      {...props}
      fullWidth
      optionIdKey="partyId"
      optionTextKey="name"
      loading={isLoading}
      options={classGroupOptions ?? []}
    />
  );
};

export const ClassGroupAutocomplete = ({
  requestOptions,
  ...props
}: ClassGroupAutocompleteProps) => {
  const { t } = useTranslation(['common']);
  const { data: classGroupData, isLoading } = useClassGroups(requestOptions);
  const { displayNames } = usePreferredNameLayout();

  const classGroupOptions = useMemo(
    () =>
      classGroupData?.map((classGroup) => ({
        partyId: classGroup.partyId,
        name: classGroup.name,
        tutorNames: displayNames(classGroup.tutors, {
          format: PreferredNameFormat.FirstnameSurname,
        }),
      })),
    [classGroupData],
  );

  return (
    <Autocomplete
      label={t('common:class')}
      fullWidth
      optionIdKey="partyId"
      optionTextKey="name"
      renderOption={(props, option) => (
        <Stack
          component="li"
          {...props}
          sx={{ alignItems: 'flex-start !important' }}
        >
          <Typography variant="subtitle2">{option.name}</Typography>
          <Typography variant="caption">{option.tutorNames}</Typography>
        </Stack>
      )}
      filterOptions={(options, { inputValue }) => {
        if (!inputValue) {
          return options;
        }

        return options.filter((option) => {
          const lowerCaseValue = inputValue.toLowerCase();
          return (
            normaliseForeignCharacters(option.name.toLowerCase()).includes(
              lowerCaseValue,
            ) ||
            normaliseForeignCharacters(
              option?.tutorNames?.toLowerCase() ?? '',
            ).includes(lowerCaseValue)
          );
        });
      }}
      {...props}
      loading={isLoading}
      options={classGroupOptions ?? []}
    />
  );
};

type TableClassGroupAutocompleteValue = Pick<
  ReturnTypeFromUseClassGroups,
  'partyId' | 'name'
>;

export function TableClassGroupAutocomplete(
  props: CustomCellEditorProps<unknown, TableClassGroupAutocompleteValue>,
) {
  const { t } = useTranslation(['common']);
  const { data: classGroupData, isLoading } = useClassGroups();

  return (
    // @ts-expect-error
    <TableAutocomplete
      {...props}
      options={classGroupData ?? []}
      getOptionLabel={(option) => option?.name ?? ''}
      optionIdKey="partyId"
      AutocompleteProps={{
        autoHighlight: true,
        loading: isLoading,
        loadingText: t('common:loading'),
      }}
    />
  );
}

if (process.env.NODE_ENV !== 'production') {
  TableClassGroupAutocomplete.displayName = 'TableClassGroupAutocomplete';
}

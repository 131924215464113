import { useMutation } from '@tanstack/react-query';

import {
  type SaveNoticeInput,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { noticeBoardKeys } from './keys';

const saveNotice = graphql(/* GraphQL */ `
  mutation communications_saveNotice($input:  [SaveNoticeInput!]!) {
    communications_saveNotice(input: $input) {
      id
      title
      notice
    }
  }
`);

export function useSaveNotice() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationFn: async (input: SaveNoticeInput[]) =>
      gqlClient.request(saveNotice, { input }),
    onSuccess: async (_, notice) => {
      await queryClient.invalidateQueries({ queryKey: noticeBoardKeys.all });
      toast(
        notice
          ? t('common:snackbarMessages.updateSuccess')
          : t('common:snackbarMessages.createSuccess'),
      );
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

import { useQuery } from '@tanstack/react-query';
import {
  type NotificationFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { shellKeys } from '../keys';

const notifications = graphql(/* GraphQL */ `
  query communications_notifications($filter: NotificationFilter) {
    communications_notifications(filter: $filter) {
      id
      title
      text
      notificationType
      sentOn
      sender {
        firstName
        lastName
        avatarUrl
      }
      recipients {
        recipientPartyId
        readOn
        status
        metaData {
          partyId
          feeId
          mailId
          notificationType
          optionId
          studentPartyId
        }
      }
    }
  }
`);

const notificationsQuery = (filter: NotificationFilter) => ({
  queryKey: shellKeys.notifications.list(filter),
  queryFn: () => gqlClient.request(notifications, { filter }),
});

export function useNotifications(filter: NotificationFilter) {
  return useQuery({
    ...notificationsQuery(filter),
    select: ({ communications_notifications }) => communications_notifications,
  });
}

export function getNotifications(filter: NotificationFilter) {
  return queryClient.fetchQuery(notificationsQuery(filter));
}

export type ReturnTypeFromNotifications = UseQueryReturnType<
  typeof useNotifications
>[number];

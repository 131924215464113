import { getCoreAcademicNamespace } from '@tyro/api';
import {
  type NavObjectFunction,
  NavObjectType,
  getNumber,
  lazyWithRetry,
  throw404Error,
} from '@tyro/core';
import { SchoolExamACircleIcon } from '@tyro/icons';
import { redirect } from 'react-router-dom';
import { getAssessmentResults } from './api/assessment-results';
import { getAssessmentSubjectGroups } from './api/assessment-subject-groups';
import { getAssessmentById } from './api/assessments';
import { AssessmentPaths } from './pages/assessments';

const AssessmentsPage = lazyWithRetry(() => import('./pages/assessments'));
const ViewTermAssessment = lazyWithRetry(
  () => import('./pages/term-assessment/view'),
);
const CreateTermAssessmentPage = lazyWithRetry(
  () => import('./pages/term-assessment/create'),
);
const CreateStateCbaPage = lazyWithRetry(
  () => import('./pages/state-cba/create'),
);
const ViewInClassAssessment = lazyWithRetry(
  () => import('./pages/in-class/view'),
);
const EditInClassAssessmentPage = lazyWithRetry(
  () => import('./pages/in-class/edit'),
);
const CreateInClassAssessmentPage = lazyWithRetry(
  () => import('./pages/in-class/create'),
);
const ViewStateCba = lazyWithRetry(() => import('./pages/state-cba/view'));
const EditStateCba = lazyWithRetry(() => import('./pages/state-cba/edit'));
const EditTermAssessmentResults = lazyWithRetry(
  () => import('./pages/term-assessment/subject-group/edit-results'),
);
const EditStateCbaResults = lazyWithRetry(
  () => import('./pages/state-cba/edit-results'),
);
const EditInClassAssessmentResults = lazyWithRetry(
  () => import('./pages/in-class/edit-results'),
);
const OverallCommentsTermAssessmentPage = lazyWithRetry(
  () => import('./pages/term-assessment/overall-comments'),
);
const EditTermAssessmentPage = lazyWithRetry(
  () => import('./pages/term-assessment/edit'),
);

export const getRoutes: NavObjectFunction = (t) => [
  {
    type: NavObjectType.Category,
    title: t('navigation:general.title'),
    children: [
      {
        type: NavObjectType.RootLink,
        path: 'assessments',
        icon: <SchoolExamACircleIcon />,
        title: t('navigation:general.assessments.title'),
        hasAccess: (permissions) =>
          permissions.isStaffUserWithPermission(
            'ps:1:assessment:read_assessments',
          ),
        children: [
          {
            type: NavObjectType.NonMenuLink,
            index: true,
            loader: async () => {
              const data = await getCoreAcademicNamespace();

              const activeAcademicNamespace =
                data.core_academicNamespaces?.find(
                  (academicNamespace) =>
                    academicNamespace?.isActiveDefaultNamespace,
                );

              if (!activeAcademicNamespace) {
                return;
              }

              return redirect('./term-assessments');
            },
          },
          {
            type: NavObjectType.NonMenuLink,
            path: ':typeId',
            element: <AssessmentsPage />,
            loader: ({ params }) => {
              const { typeId } = params;

              const matchesAcceptedPaths =
                typeId &&
                Object.values(AssessmentPaths).includes(
                  typeId as AssessmentPaths,
                );

              if (matchesAcceptedPaths) {
                return null;
              }
              return throw404Error();
            },
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'term-assessments/create',
            element: <CreateTermAssessmentPage />,
            loader: async ({ request }) => {
              const from = new URL(request.url).searchParams.get('from');
              const fromId = Number(from);

              if (!Number.isNaN(fromId)) {
                const data = await getCoreAcademicNamespace();

                const activeAcademicNamespace =
                  data.core_academicNamespaces?.find(
                    (academicNamespace) =>
                      academicNamespace?.isActiveDefaultNamespace,
                  );

                if (!activeAcademicNamespace) {
                  return;
                }

                return getAssessmentById({
                  academicNameSpaceId:
                    activeAcademicNamespace.academicNamespaceId,
                  ids: [fromId],
                });
              }

              return null;
            },
            hasAccess: ({ hasPermission }) =>
              hasPermission('ps:1:assessment:write_assessments'),
          },
          {
            type: NavObjectType.NonMenuLink,
            path: ':academicNamespaceId/term-assessments',
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => redirect('./..'),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: ':assessmentId',
                loader: ({ params }) => {
                  const academicNameSpaceId = getNumber(
                    params.academicNamespaceId,
                  );
                  const assessmentId = getNumber(params.assessmentId);

                  if (!academicNameSpaceId || !assessmentId) {
                    throw404Error();
                  }

                  return getAssessmentById({
                    academicNameSpaceId,
                    ids: [assessmentId],
                  });
                },
                children: [
                  {
                    type: NavObjectType.NonMenuLink,
                    index: true,
                    loader: ({ params }) => {
                      const academicNameSpaceId = getNumber(
                        params.academicNamespaceId,
                      );
                      const assessmentId = getNumber(params.assessmentId);

                      if (!academicNameSpaceId || !assessmentId) {
                        throw404Error();
                      }

                      return getAssessmentSubjectGroups(academicNameSpaceId, {
                        assessmentId,
                      });
                    },
                    element: <ViewTermAssessment />,
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'edit',
                    element: <EditTermAssessmentPage />,
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'overall-comments',
                    element: <OverallCommentsTermAssessmentPage />,
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'subject-group/:subjectGroupId',
                    loader: ({ params }) => {
                      const academicNameSpaceId = getNumber(
                        params.academicNamespaceId,
                      );
                      const assessmentId = getNumber(params.assessmentId);
                      const subjectGroupId = getNumber(params.subjectGroupId);

                      if (
                        !academicNameSpaceId ||
                        !assessmentId ||
                        !subjectGroupId
                      ) {
                        throw404Error();
                      }

                      return Promise.all([
                        getAssessmentById({
                          academicNameSpaceId,
                          ids: [assessmentId],
                        }),
                        getAssessmentResults(academicNameSpaceId, {
                          assessmentId,
                          subjectGroupIds: [subjectGroupId],
                        }),
                      ]);
                    },
                    element: <EditTermAssessmentResults />,
                  },
                ],
              },
            ],
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'state-cba/create',
            element: <CreateStateCbaPage />,
            hasAccess: ({ hasPermission }) =>
              hasPermission('ps:1:assessment:write_assessments'),
            loader: async ({ request }) => {
              const from = new URL(request.url).searchParams.get('from');
              const fromId = Number(from);

              if (!Number.isNaN(fromId)) {
                const data = await getCoreAcademicNamespace();

                const activeAcademicNamespace =
                  data.core_academicNamespaces?.find(
                    (academicNamespace) =>
                      academicNamespace?.isActiveDefaultNamespace,
                  );

                if (!activeAcademicNamespace) {
                  return;
                }

                return getAssessmentById({
                  academicNameSpaceId:
                    activeAcademicNamespace.academicNamespaceId,
                  ids: [fromId],
                });
              }

              return null;
            },
          },
          {
            type: NavObjectType.NonMenuLink,
            path: ':academicNamespaceId/state-cba',
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => redirect('./..'),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: ':assessmentId',
                loader: ({ params }) => {
                  const academicNameSpaceId = getNumber(
                    params.academicNamespaceId,
                  );
                  const assessmentId = getNumber(params.assessmentId);

                  if (!academicNameSpaceId || !assessmentId) {
                    throw404Error();
                  }

                  return getAssessmentById({
                    academicNameSpaceId,
                    ids: [assessmentId],
                  });
                },
                children: [
                  {
                    type: NavObjectType.NonMenuLink,
                    index: true,
                    loader: ({ params }) => {
                      const academicNameSpaceId = getNumber(
                        params.academicNamespaceId,
                      );
                      const assessmentId = getNumber(params.assessmentId);

                      if (!academicNameSpaceId || !assessmentId) {
                        throw404Error();
                      }

                      return getAssessmentSubjectGroups(academicNameSpaceId, {
                        assessmentId,
                      });
                    },
                    element: <ViewStateCba />,
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'edit',
                    element: <EditStateCba />,
                    loader: ({ params }) => {
                      const academicNameSpaceId = getNumber(
                        params.academicNamespaceId,
                      );
                      const assessmentId = getNumber(params.assessmentId);

                      if (!academicNameSpaceId || !assessmentId) {
                        throw404Error();
                      }

                      return getAssessmentSubjectGroups(academicNameSpaceId, {
                        assessmentId,
                      });
                    },
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'subject-group/:subjectGroupId',
                    loader: ({ params }) => {
                      const academicNameSpaceId = getNumber(
                        params.academicNamespaceId,
                      );
                      const assessmentId = getNumber(params.assessmentId);
                      const subjectGroupId = getNumber(params.subjectGroupId);

                      if (
                        !academicNameSpaceId ||
                        !assessmentId ||
                        !subjectGroupId
                      ) {
                        throw404Error();
                      }

                      return Promise.all([
                        getAssessmentById({
                          academicNameSpaceId,
                          ids: [assessmentId],
                        }),
                        getAssessmentResults(academicNameSpaceId, {
                          assessmentId,
                          subjectGroupIds: [subjectGroupId],
                        }),
                      ]);
                    },
                    element: <EditStateCbaResults />,
                  },
                ],
              },
            ],
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'class-assessments/create',
            element: <CreateInClassAssessmentPage />,
            hasAccess: ({ hasPermission }) =>
              hasPermission('ps:1:assessment:write_class_assessments'),
            loader: async ({ request }) => {
              const from = new URL(request.url).searchParams.get('from');
              const fromId = Number(from);

              if (!Number.isNaN(fromId)) {
                const data = await getCoreAcademicNamespace();

                const activeAcademicNamespace =
                  data.core_academicNamespaces?.find(
                    (academicNamespace) =>
                      academicNamespace?.isActiveDefaultNamespace,
                  );

                if (!activeAcademicNamespace) {
                  return;
                }

                return getAssessmentById({
                  academicNameSpaceId:
                    activeAcademicNamespace.academicNamespaceId,
                  ids: [fromId],
                });
              }

              return null;
            },
          },
          {
            type: NavObjectType.NonMenuLink,
            path: ':academicNamespaceId/class-assessments',
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => redirect('./..'),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: ':assessmentId',
                loader: ({ params }) => {
                  const academicNameSpaceId = getNumber(
                    params.academicNamespaceId,
                  );
                  const assessmentId = getNumber(params.assessmentId);

                  if (!academicNameSpaceId || !assessmentId) {
                    throw404Error();
                  }

                  return getAssessmentById({
                    academicNameSpaceId,
                    ids: [assessmentId],
                  });
                },
                children: [
                  {
                    type: NavObjectType.NonMenuLink,
                    index: true,
                    loader: ({ params }) => {
                      const academicNameSpaceId = getNumber(
                        params.academicNamespaceId,
                      );
                      const assessmentId = getNumber(params.assessmentId);

                      if (!academicNameSpaceId || !assessmentId) {
                        throw404Error();
                      }

                      return getAssessmentSubjectGroups(academicNameSpaceId, {
                        assessmentId,
                      });
                    },
                    element: <ViewInClassAssessment />,
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'edit',
                    element: <EditInClassAssessmentPage />,
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'subject-group/:subjectGroupId',
                    loader: ({ params }) => {
                      const academicNameSpaceId = getNumber(
                        params.academicNamespaceId,
                      );
                      const assessmentId = getNumber(params.assessmentId);
                      const subjectGroupId = getNumber(params.subjectGroupId);

                      if (
                        !academicNameSpaceId ||
                        !assessmentId ||
                        !subjectGroupId
                      ) {
                        throw404Error();
                      }

                      return Promise.all([
                        getAssessmentById({
                          academicNameSpaceId,
                          ids: [assessmentId],
                        }),
                        getAssessmentResults(academicNameSpaceId, {
                          assessmentId,
                          subjectGroupIds: [subjectGroupId],
                        }),
                      ]);
                    },
                    element: <EditInClassAssessmentResults />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

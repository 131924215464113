import { useMutation } from '@tanstack/react-query';
import {
  type Notes_DeleteBehaviourNote,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { reportsKeys } from '@tyro/reporting';
import { peopleKeys } from '../keys';

const deleteBehaviour = graphql(/* GraphQL */ `
  mutation notes_deleteBehaviourNote($input: Notes_DeleteBehaviourNote!) {
    notes_deleteBehaviourNote(input: $input) {
      success
    }
  }
`);

export function useDeleteBehaviour() {
  const { t } = useTranslation(['common', 'people']);
  const { toast } = useToast();

  return useMutation({
    mutationFn: (input: Notes_DeleteBehaviourNote) =>
      gqlClient.request(deleteBehaviour, { input }),
    onSuccess: async (_, { referencedPartyIds }) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: peopleKeys.students.all() }),
        queryClient.invalidateQueries({ queryKey: reportsKeys.all }),
      ]);

      const partyIdsLength = referencedPartyIds ? referencedPartyIds.length : 0;

      toast(
        partyIdsLength > 0
          ? t('people:behaviourRemovedForStudent', {
              count: partyIdsLength,
            })
          : t('common:snackbarMessages.deleteSuccess'),
      );
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

import { useQuery } from '@tanstack/react-query';
import {
  type FormsAdmin_FormDefinitionsFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { formsKeys } from './keys';

const definitions = graphql(/* GraphQL */ `
  query formsAdmin_formDefinitions($filter: FormsAdmin_FormDefinitionsFilter) {
    formsAdmin_formDefinitions(filter: $filter) {
      id {
        id
        provider
      }
      title
      description
      tags {
        id
        name
      }
      isReusableTemplate
      allowPartialCompletion
      disabled
      tenantCanEdit
      usageCount
      fields {
        id
        header
        sortOrder
        fields {
          id {
            formId
            fieldGroupId
            fieldId
          }
          sortOrder
          label
          type
          selectInfo {
            options {
              id
              value
            }
          }
          defaultValue
          required
        }
      }
    }
  }
`);

const definitionsQuery = (filter: FormsAdmin_FormDefinitionsFilter) => ({
  queryKey: formsKeys.definitions(filter),
  queryFn: () => gqlClient.request(definitions, { filter }),
});

export function getFormDefinitions(filter: FormsAdmin_FormDefinitionsFilter) {
  return queryClient.fetchQuery(definitionsQuery(filter));
}

export function useFormDefinitions(filter: FormsAdmin_FormDefinitionsFilter) {
  return useQuery({
    ...definitionsQuery(filter),
    select: ({ formsAdmin_formDefinitions }) => formsAdmin_formDefinitions,
  });
}

export type ReturnTypeFromUseFormDefinitions = UseQueryReturnType<
  typeof useFormDefinitions
>[number];

import { useQuery } from '@tanstack/react-query';
import {
  type QueryAdditionalOptions,
  type Rules_RulesViewFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { rulesKeys } from './keys';

const rulesList = graphql(/* GraphQL */ `
  query rules_rules($filter: Rules_RulesViewFilter!) {
    rules_rules(filter: $filter) {
      ruleId
      name
      description
      ruleTriggeredCount
      actionTakenCount
      createdOn
      updatedOn
    }
  }
`);

const ruleDetails = graphql(/* GraphQL */ `
  query rule_details($filter: Rules_RulesViewFilter!) {
    rules_rules(filter: $filter) {
      ruleId
      name
      ruleType
      description
      partyIds
      parties {
        ... on Student {
          __typename
          person {
            partyId
            firstName
            lastName
            avatarUrl
          }
        }
        ... on Staff {
          __typename
          person {
            partyId
            firstName
            lastName
            avatarUrl
          }
        }
        ... on StudentContact {
          __typename
          person {
            partyId
            firstName
            lastName
            avatarUrl
          }
        }
        ... on SubjectGroup {
          __typename
          partyId
          name
          avatarUrl
        }
        ... on GeneralGroup {
          __typename
          partyId
          name
        }
        ... on YearGroupEnrollment {
          __typename
          partyId
          name
        }
        ... on ProgrammeStageEnrollment {
          __typename
          partyId
          name
        }
      }
      criteria {
        ruleType
        ... on Rules_Criteria_AggregateMatch {
          ruleId
          criteriaLogicalCombinator
          criteria {
            criteriaType
            name
            filterCondition
            timeframe {
              type
              ... on Rules_TimeFrame_Rolling {
                weeks
              }
            }
            aggregateMatch {
              type
              field
              operator
              value
            }
          }
        }
      }
      actions {
        ruleActionId
        type
        action {
          type
        }
      }
    }
  }
`);

const rulesListQuery = (filter: Rules_RulesViewFilter) => ({
  queryKey: rulesKeys.rulesList(filter),
  queryFn: () => gqlClient.request(rulesList, { filter }),
});

export function getRulesList(filter: Rules_RulesViewFilter) {
  return queryClient.fetchQuery(rulesListQuery(filter));
}

export function useRulesList(filter: Rules_RulesViewFilter) {
  return useQuery({
    ...rulesListQuery(filter),
    select: ({ rules_rules }) => rules_rules,
  });
}

const ruleDetailsQuery = (filter: Rules_RulesViewFilter) => ({
  queryKey: rulesKeys.ruleDetails(filter),
  queryFn: () => gqlClient.request(ruleDetails, { filter }),
});

export function getRuleDetails(filter: Rules_RulesViewFilter) {
  return queryClient.fetchQuery(ruleDetailsQuery(filter));
}

export function useRuleDetails(
  filter: Rules_RulesViewFilter,
  options: Omit<QueryAdditionalOptions, 'academicNamespaceId'>,
) {
  return useQuery({
    ...ruleDetailsQuery(filter),
    ...options,
    select: ({ rules_rules }) => rules_rules[0],
  });
}

export type ReturnTypeFromUseRulesList = UseQueryReturnType<
  typeof useRulesList
>[number];

export type ReturnTypeFromUseRuleDetails = UseQueryReturnType<
  typeof useRuleDetails
>;

import { useQuery } from '@tanstack/react-query';
import {
  type Templating_ResolveTemplateInput,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { templatesKeys } from './keys';

const resolveTemplates = graphql(/* GraphQL */ `
  query resolveTemplate($filter: Templating_ResolveTemplateInput) {
    templating_resolveTemplate(filter: $filter) {
      temporaryUrl
      html
    }
  }
`);

const resolveTemplateQuery = (filter: Templating_ResolveTemplateInput) => ({
  queryKey: templatesKeys.resolveTemplates(filter),
  queryFn: async () =>
    gqlClient.request(resolveTemplates, {
      filter,
    }),
});

export function getResolveTemplate(filter: Templating_ResolveTemplateInput) {
  return queryClient.fetchQuery(resolveTemplateQuery(filter));
}

export function useResolveTemplate(filter: Templating_ResolveTemplateInput) {
  return useQuery({
    ...resolveTemplateQuery(filter),
    select: ({ templating_resolveTemplate }) => templating_resolveTemplate,
  });
}

import { useMutation } from '@tanstack/react-query';

import {
  type Notes_UpsertNote,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { reportsKeys } from '@tyro/reporting';
import { peopleKeys } from '../keys';

const upsertNote = graphql(/* GraphQL */ `
  mutation notes_upsertBehaviourNote($input: [Notes_UpsertNote]) {
    notes_upsertNotes(input: $input) {
      id
      tags {
        behaviourType
      }
    }
  }
`);

export function useUpsertStudentBehaviour() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationFn: async (input: Notes_UpsertNote[]) =>
      gqlClient.request(upsertNote, { input }),
    onSuccess: async (_, [note]) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: peopleKeys.students.all() }),
        queryClient.invalidateQueries({ queryKey: reportsKeys.all }),
      ]);

      toast(
        note.id
          ? t('common:snackbarMessages.updateSuccess')
          : t('common:snackbarMessages.createSuccess'),
      );
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

import { Forms_LinkEntityType } from '@tyro/api';
import {
  type NavObjectFunction,
  NavObjectType,
  getNumber,
  lazyWithRetry,
  throw404Error,
} from '@tyro/core';
import { getSearchFormsSubmissions } from '@tyro/forms';
import { getCustomGroupById } from '@tyro/groups';
import { SchoolBagIcon } from '@tyro/icons';
import { redirect } from 'react-router-dom';
import { getClassAway } from './api/class-away';
import { getSchoolActivityById } from './api/get-school-activities';
import { getSaStudentList } from './api/get-school-activity-students';
import { getLessonsNeedingCover } from './api/lessons-needed-cover';

const SchoolActivityPage = lazyWithRetry(() => import('./pages'));
const CalendarPage = lazyWithRetry(() => import('./pages/calendar'));
const CreateSchoolActivityPage = lazyWithRetry(() => import('./pages/create'));
const EditSchoolActivityPage = lazyWithRetry(() => import('./pages/edit'));
const SchoolActivitiesContainer = lazyWithRetry(
  () => import('./components/school-activities-container'),
);
const CoverRequired = lazyWithRetry(() => import('./pages/cover-required'));
const ClassAway = lazyWithRetry(() => import('./pages/class-away'));
const SchoolActivityAttendancePage = lazyWithRetry(
  () => import('./pages/attendance'),
);
const StudentsList = lazyWithRetry(() => import('./pages/students'));
const StaffList = lazyWithRetry(() => import('./pages/staff'));

const Forms = lazyWithRetry(() => import('./pages/forms'));

export const getRoutes: NavObjectFunction = (t) => [
  {
    type: NavObjectType.Category,
    title: t('navigation:general.title'),
    children: [
      {
        type: NavObjectType.RootGroup,
        path: 'school-activity',
        title: t('navigation:general.schoolActivities.title'),
        hasAccess: ({ isStaffUserWithPermission }) =>
          isStaffUserWithPermission(
            'ps:1:school_activity:read_school_activity',
          ),
        icon: <SchoolBagIcon />,
        children: [
          {
            type: NavObjectType.MenuLink,
            title: t('navigation:general.schoolActivities.calendar'),
            path: 'calendar',
            element: <CalendarPage />,
          },
          {
            type: NavObjectType.MenuLink,
            title: t('navigation:general.schoolActivities.schoolActivities'),
            path: 'school-activity',
            element: <SchoolActivityPage />,
          },

          {
            type: NavObjectType.NonMenuLink,
            path: 'school-activity/create',
            element: <CreateSchoolActivityPage />,
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'school-activity/:activityId',
            element: <SchoolActivitiesContainer />,
            loader: ({ params }) => {
              const schoolActivityId = getNumber(params.activityId);

              if (!schoolActivityId) {
                throw404Error();
              }

              return getSchoolActivityById({
                schoolActivityIds: [schoolActivityId],
              });
            },
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => redirect('./students'),
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'edit',
                element: <EditSchoolActivityPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'cover-required',
                element: <CoverRequired />,
                loader: ({ params }) => {
                  const schoolActivityId = getNumber(params.activityId);

                  if (!schoolActivityId) {
                    throw404Error();
                  }

                  return getLessonsNeedingCover({ schoolActivityId });
                },
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'class-away',
                element: <ClassAway />,
                loader: ({ params }) => {
                  const schoolActivityId = getNumber(params.activityId);

                  if (!schoolActivityId) {
                    throw404Error();
                  }

                  return getClassAway({ schoolActivityId });
                },
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'forms',
                element: <Forms />,
                hasAccess: ({ isStaffUserWithPermission }) =>
                  isStaffUserWithPermission('ps:1:forms:forms_users'),
                loader: ({ params }) => {
                  const { activityId } = params;

                  if (!activityId) {
                    throw404Error();
                  }

                  return getSearchFormsSubmissions({
                    links: [
                      {
                        id: activityId ?? '',
                        type: Forms_LinkEntityType.SchoolActivity,
                      },
                    ],
                  });
                },
              },
              // {
              //   type: NavObjectType.NonMenuLink,
              //   path: 'forms/add',
              //   element: <AddForm />,
              //   loader: ({ request }) => {
              //     const url = new URL(request.url);
              //     const provider = url.searchParams.get('provider');
              //     const id = url.searchParams.get('id');

              //     if (!id || !provider) {
              //       throw404Error();
              //     }

              //     return getPreviewFormDefinition({
              //       id: {
              //         id: getNumber(id),
              //         provider,
              //       },
              //     });
              //   },
              // },
              // {
              //   type: NavObjectType.NonMenuLink,
              //   path: 'forms/edit',
              //   element: <EditForm />,
              //   loader: ({ request }) => {
              //     const url = new URL(request.url);

              //     const id = getNumber(url.searchParams.get('id'));
              //     const forPartyId = getNumber(
              //       url.searchParams.get('forPartyId'),
              //     );
              //     const provider = url.searchParams.get('provider');

              //     if (!forPartyId || !id || !provider) {
              //       throw404Error();
              //     }

              //     return getViewSubmissions({
              //       id: {
              //         forPartyId,
              //         objectId: id,
              //         provider,
              //       },
              //     });
              //   },
              // },
              // {
              //   type: NavObjectType.NonMenuLink,
              //   path: 'forms/view',
              //   element: <FormView />,
              //   loader: ({ request }) => {
              //     const url = new URL(request.url);

              //     const id = getNumber(url.searchParams.get('id'));
              //     const forPartyId = getNumber(
              //       url.searchParams.get('forPartyId'),
              //     );
              //     const provider = url.searchParams.get('provider');

              //     if (!forPartyId || !id || !provider) {
              //       throw404Error();
              //     }

              //     return getViewSubmissions({
              //       id: {
              //         forPartyId,
              //         objectId: id,
              //         provider,
              //       },
              //     });
              //   },
              // },
              // {
              //   type: NavObjectType.NonMenuLink,
              //   path: 'forms/permission-slip',
              //   element: <PermissionSlipForm />,
              // },
              // {
              //   type: NavObjectType.NonMenuLink,
              //   path: 'forms/permission-slip/view',
              //   element: <ViewInfoRequest />,
              //   loader: ({ request }) => {
              //     const url = new URL(request.url);

              //     const id = getNumber(url.searchParams.get('id'));
              //     const provider = url.searchParams.get('provider');

              //     if (!id || !provider) {
              //       throw404Error();
              //     }

              //     return getInfoRequestTableView({
              //       id: { id, provider },
              //     });
              //   },
              // },
              {
                type: NavObjectType.NonMenuLink,
                path: 'students',
                element: <StudentsList />,
                loader: ({ params }) => {
                  const schoolActivityId = getNumber(params.activityId);

                  if (!schoolActivityId) {
                    throw404Error();
                  }

                  return getSaStudentList({
                    schoolActivityIds: [schoolActivityId],
                  });
                },
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'staff',
                element: <StaffList />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'attendance',
                element: <SchoolActivityAttendancePage />,
                loader: async ({ params }) => {
                  const schoolActivityId = getNumber(params.activityId);

                  if (!schoolActivityId) {
                    throw404Error();
                  }

                  const { sa_activities: schoolActivity } =
                    await getSchoolActivityById({
                      schoolActivityIds: [schoolActivityId],
                    });

                  return getCustomGroupById(
                    schoolActivity?.[0]?.customGroupId ?? 0,
                  );
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

import { useMutation, useQuery } from '@tanstack/react-query';
import {
  type Templating_DemoLabelTemplateInput,
  type Templating_LabelTemplateFilter,
  type Templating_UpsertLabelTemplateInput,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { printingKeys } from '../keys';

const templatingLabelTemplates = graphql(/* GraphQL */ `
  query templating_getLabelTemplates($filter: Templating_LabelTemplateFilter!) {
    templating_getLabelTemplates(filter: $filter) {
      id
      name
      paddingTop
      paddingBottom
      paddingLeft
      paddingRight
      paddingColumn
      paddingRow
      fontSize
      labelType
    }
  }
`);

const demoLabelTemplate = graphql(/* GraphQL */ `
  query templating_getDemoLabelTemplate(
    $filter: Templating_DemoLabelTemplateInput!
  ) {
    templating_getDemoLabelTemplate(filter: $filter) {
      html
    }
  }
`);

const saveLabelTemplate = graphql(/* GraphQL */ `
  mutation templating_upsertLabelTemplate(
    $input: Templating_UpsertLabelTemplateInput!
  ) {
    templating_upsertLabelTemplate(input: $input) {
      id
    }
  }
`);

const templatingLabelTemplatesQuery = (
  filter: Templating_LabelTemplateFilter,
) => ({
  queryKey: printingKeys.labels.templates(filter),
  queryFn: async () =>
    gqlClient.request(templatingLabelTemplates, {
      filter,
    }),
});

const demoLabelTemplateQuery = (filter: Templating_DemoLabelTemplateInput) => ({
  queryKey: printingKeys.labels.demoTemplate(filter),
  queryFn: async () =>
    gqlClient.request(demoLabelTemplate, {
      filter,
    }),
});

export function getTemplatingLabelTemplates(
  filter: Templating_LabelTemplateFilter,
) {
  return queryClient.fetchQuery(templatingLabelTemplatesQuery(filter));
}

export function useTemplatingLabelTemplates(
  filter: Templating_LabelTemplateFilter,
) {
  return useQuery({
    ...templatingLabelTemplatesQuery(filter),
    select: ({ templating_getLabelTemplates }) => templating_getLabelTemplates,
  });
}

export function useDemoLabelTemplate(
  filter: Templating_DemoLabelTemplateInput,
  enabled = true,
) {
  return useQuery({
    ...demoLabelTemplateQuery(filter),
    select: ({ templating_getDemoLabelTemplate }) =>
      templating_getDemoLabelTemplate,
    enabled,
  });
}

export function useSaveLabelTemplate() {
  const { t } = useTranslation(['common']);
  const { toast } = useToast();

  return useMutation({
    mutationFn: (input: Templating_UpsertLabelTemplateInput) =>
      gqlClient.request(saveLabelTemplate, { input }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: printingKeys.labels.all(),
      });
      toast(t('common:snackbarMessages.savedSuccess'));
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

export type ReturnTypeFromUseTemplatingLabelTemplates = UseQueryReturnType<
  typeof useTemplatingLabelTemplates
>;

import { useQuery } from '@tanstack/react-query';
import {
  type FeeFilter,
  FeeStatus,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { feeKeys } from './keys';

const feeDebtors = graphql(/* GraphQL */ `
  query fees_debtors($filter: FeeFilter) {
    fees_fees(filter: $filter) {
      debtors {
        id
        feeId
        person {
          partyId
          firstName
          lastName
          avatarUrl
          type
        }
        classGroup {
          name
        }
        feeStatus
        amount
        amountPaid
        amountDue
        amountDiscounted
        discounts {
          id
          name
          discountType
          value
        }
        writeOffNote
        amountWrittenOff
      }
    }
  }
`);

const feeDebtorsQuery = (filter: FeeFilter) => ({
  queryKey: feeKeys.debtors(filter),
  queryFn: async () => {
    const data = await gqlClient.request(feeDebtors, { filter });

    return {
      ...data,
      fees_fees: data.fees_fees.map((fee) => ({
        ...fee,
        debtors: (fee.debtors || []).map((debtor) => ({
          ...debtor,
          feeStatus:
            debtor.amountDue <= 0 ? FeeStatus.Complete : debtor.feeStatus,
        })),
      })),
    };
  },
});

export function getFeeDebtors(filter: FeeFilter) {
  return queryClient.fetchQuery(feeDebtorsQuery(filter));
}

export function useFeeDebtors(filter: FeeFilter) {
  return useQuery({
    ...feeDebtorsQuery(filter),
    select: ({ fees_fees }) => (fees_fees.length ? fees_fees[0].debtors : []),
  });
}

export type ReturnTypeFromUseFeeDebtors = UseQueryReturnType<
  typeof useFeeDebtors
>[number];

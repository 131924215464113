import { useQuery } from '@tanstack/react-query';
import {
  type TtGridFilter,
  type Tt_GridsQuery,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useCallback } from 'react';
import { timetableKeys } from '../keys';

const ttGrids = graphql(/* GraphQL */ `
  query tt_grids($filter: TTGridFilter!) {
    tt_grids(filter: $filter) {
      idx
      name
      timetableId
      description
      appliesToGroupTagIds
      appliesToGroupTags {
        id
        name
      }
      days {
        dayOfWeek
        idx
        halfDayAfterPeriod
        periods {
          idx
          name
          startTime
          endTime
          type
        }
      }
    }
  }
`);

const ttGridList = graphql(/* GraphQL */ `
  query tt_gridList($filter: TTGridFilter!) {
    tt_grids(filter: $filter) {
      idx
      name
    }
  }
`);

const ttGridsQuery = (filter: TtGridFilter) => ({
  queryKey: timetableKeys.grids(filter),
  queryFn: () => gqlClient.request(ttGrids, { filter }),
});

const ttGridListQuery = (filter: TtGridFilter) => ({
  queryKey: timetableKeys.gridList(filter),
  queryFn: () => gqlClient.request(ttGridList, { filter }),
});

export function getTimetableGrids(filter: TtGridFilter) {
  return queryClient.fetchQuery(ttGridsQuery(filter));
}

export function useTimetableGrids(filter: TtGridFilter) {
  return useQuery({
    ...ttGridsQuery(filter),
    enabled: filter.timetableId !== 0,
    select: useCallback(
      ({ tt_grids }: Tt_GridsQuery) =>
        tt_grids.map(({ days, ...rest }) => ({
          ...rest,
          days: days.sort((a, b) => a.dayOfWeek - b.dayOfWeek),
        })),
      [],
    ),
  });
}

export function getTimetableGridList(filter: TtGridFilter) {
  return queryClient.fetchQuery(ttGridListQuery(filter));
}

export function useTimetableGridList(filter: TtGridFilter) {
  return useQuery({
    ...ttGridListQuery(filter),
    enabled: filter.timetableId !== 0,
    select: ({ tt_grids }) => tt_grids,
  });
}

export type ReturnTypeFromUseTimetableGrids = UseQueryReturnType<
  typeof useTimetableGrids
>[number];

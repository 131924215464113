import { Box, InputAdornment, Stack } from '@mui/material';
import { RecurrenceEnum } from '@tyro/api';
import {
  RHFDatePicker,
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
  RHFTimePicker,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import dayjs from 'dayjs';
import { type Control, useWatch } from 'react-hook-form';
import { MINIMUM_EVENT_DURATION } from './constants';
import type { FormState } from './types';

const recurrenceOptions: RecurrenceEnum[] = [
  RecurrenceEnum.NoRecurrence,
  RecurrenceEnum.Daily,
  RecurrenceEnum.Weekly,
  RecurrenceEnum.Biweekly,
  RecurrenceEnum.Monthly,
];

type ScheduleEventProps = {
  control: Control<FormState>;
  isEditing?: boolean;
};

export const ScheduleEvent = ({ control, isEditing }: ScheduleEventProps) => {
  const { t } = useTranslation(['common', 'calendar']);

  const { allDayEvent, startDate, startTime, recurrenceEnum, ends } = useWatch({
    control,
  });

  const isRecurringEvent = recurrenceEnum !== RecurrenceEnum.NoRecurrence;

  return (
    <>
      <Stack direction="column" gap={1.5}>
        {/* <RHFSwitch
          label={t('common:allDay')}
          switchProps={{ color: 'primary' }}
          controlProps={{ name: 'allDayEvent', control }}
        /> */}

        <Stack direction="row" gap={1}>
          <RHFDatePicker
            label={t('calendar:inputLabels.startDate')}
            inputProps={{ fullWidth: true }}
            controlProps={{ name: 'startDate', control }}
          />

          {!allDayEvent && (
            <Stack direction="row" gap={1} width="100%">
              <RHFTimePicker
                label={t('common:startTime')}
                controlProps={{ name: 'startTime', control }}
              />
              <RHFTimePicker
                label={t('common:endTime')}
                timePickerProps={{
                  minTime: dayjs(startTime as dayjs.Dayjs).add(
                    MINIMUM_EVENT_DURATION,
                    'minutes',
                  ),
                }}
                controlProps={{ name: 'endTime', control }}
              />
            </Stack>
          )}
        </Stack>
      </Stack>

      <RHFSelect
        label={t('calendar:inputLabels.schedule')}
        options={recurrenceOptions}
        getOptionLabel={(option) =>
          t(`calendar:inputLabels.recurrenceEnum.${option}`, {
            day: dayjs(startDate as dayjs.Dayjs).format('dddd'),
          })
        }
        controlProps={{
          name: 'recurrenceEnum',
          control,
        }}
      />

      {isEditing && isRecurringEvent ? (
        <RHFDatePicker
          label={t('calendar:inputLabels.endDate')}
          inputProps={{ fullWidth: true }}
          controlProps={{ name: 'endDate', control }}
        />
      ) : (
        recurrenceEnum &&
        isRecurringEvent && (
          <RHFRadioGroup
            label={t('calendar:inputLabels.ends')}
            radioGroupProps={{ sx: { gap: 1 } }}
            options={[
              {
                value: 'on',
                label: t('calendar:inputLabels.endsOn'),
              },
              {
                value: 'after',
                label: t('calendar:inputLabels.endsAfter'),
              },
            ]}
            renderOption={(option, renderRadio) => (
              <Stack key={option.value} direction="row">
                {renderRadio({
                  sx: {
                    width: '92px',
                  },
                })}
                <Box width="168px">
                  {option.value === 'on' && (
                    <RHFDatePicker
                      inputProps={{
                        variant: 'filled',
                        size: 'small',
                        hiddenLabel: true,
                        disabled: option.value !== ends,
                      }}
                      controlProps={{
                        name: 'endDate',
                        control,
                      }}
                    />
                  )}
                  {option.value === 'after' && (
                    <RHFTextField
                      textFieldProps={{
                        variant: 'filled',
                        size: 'small',
                        type: 'number',
                        hiddenLabel: true,
                        placeholder: '5',
                        disabled: option.value !== ends,
                        InputProps: {
                          endAdornment: (
                            <InputAdornment position="start">
                              {t('calendar:inputLabels.occurrences')}
                            </InputAdornment>
                          ),
                        },
                      }}
                      controlProps={{
                        name: 'occurrences',
                        control,
                      }}
                    />
                  )}
                </Box>
              </Stack>
            )}
            controlProps={{
              name: 'ends',
              control,
            }}
          />
        )
      )}
    </>
  );
};

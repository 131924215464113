import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { AttendanceCodeType } from '@tyro/api';
import { useAttendanceCodeById } from '@tyro/attendance';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  EditState,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { useEffect, useMemo, useState } from 'react';
import type { StudentAttendance } from '../../../hooks';
import { AttendanceBreakdown } from './breakdown';

type AttendanceBreakdownModalProps = {
  hasChanges: boolean;
  showActions: boolean;
  attendance: StudentAttendance;
  editingState: EditState;
  onSave: () => void;
};

export const AttendanceBreakdownModal = ({
  hasChanges,
  showActions,
  attendance,
  editingState,
  onSave,
}: AttendanceBreakdownModalProps) => {
  const { t } = useTranslation(['common', 'attendance']);

  const codeById = useAttendanceCodeById({});
  const [showModal, setShowModal] = useState(false);

  const attendanceTotals = useMemo(() => {
    const currentAttendanceCodes = Object.values(attendance).map(
      ({ attendanceCodeId: codeId }) => codeById?.[codeId],
    );

    const current = currentAttendanceCodes.filter(
      (code) =>
        code?.codeType !== AttendanceCodeType.ExplainedAbsence &&
        code?.codeType !== AttendanceCodeType.UnexplainedAbsence,
    );

    return `${current.length}/${currentAttendanceCodes.length} ${t(
      'common:students',
    )}`;
  }, [attendance, codeById]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  useEffect(() => {
    if (editingState === EditState.Saved) {
      handleCloseModal();
    }
  }, [editingState]);

  const isSaving = editingState === EditState.Saving;

  return (
    <Stack flexDirection="row" flexWrap="wrap">
      <Button color="primary" variant="text" onClick={handleShowModal}>
        {attendanceTotals}
      </Button>
      <Dialog
        open={showModal}
        fullWidth
        maxWidth="xs"
        onClose={handleCloseModal}
      >
        <DialogTitle onClose={handleCloseModal}>
          {t('attendance:attendanceBreakdown')}
        </DialogTitle>
        <DialogContent>
          <AttendanceBreakdown
            attendance={attendance}
            flexDirection="column"
            padding={2}
            borderRadius={2}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="soft"
            color="inherit"
            onClick={handleCloseModal}
            disabled={isSaving}
          >
            {t('common:actions.close')}
          </Button>
          {showActions && (
            <LoadingButton
              variant="contained"
              onClick={onSave}
              loading={isSaving}
            >
              {t(`common:actions.${hasChanges ? 'save' : 'confirm'}`)}
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

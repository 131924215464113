import { useQuery } from '@tanstack/react-query';
import {
  type Templating_VariablesFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { templatesKeys } from './keys';

const variables = graphql(/* GraphQL */ `
  query getVariables($filter: Templating_VariablesFilter) {
    templating_getVariables(filter: $filter) {
      id
      name
      value
      context
    }
  }
`);

const variablesQuery = (filter: Templating_VariablesFilter) => ({
  queryKey: templatesKeys.variables(filter),
  queryFn: () =>
    gqlClient.request(variables, {
      filter,
    }),
});

export function getVariablesQuery(filter: Templating_VariablesFilter) {
  return queryClient.fetchQuery(variablesQuery(filter));
}

export function useVariablesQuery(filter: Templating_VariablesFilter) {
  return useQuery({
    ...variablesQuery(filter),
    select: ({ templating_getVariables }) => templating_getVariables,
  });
}

export type ReturnTypeFromUseVariablesQuery = UseQueryReturnType<
  typeof useVariablesQuery
>[number];

import { Collapse } from '@mui/material';
import { useDisclosure } from '@tyro/core';
import { useEffect, useId, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useActiveLink from '../../../../../hooks/use-active-link';
import type {
  NavigationMenuLink,
  NavigationRootGroup,
} from '../../../../../hooks/use-navigation-config';
import type { NavItemProps } from '../types';
import NavItem from './NavItem';

type NavListRootProps = {
  data: NavigationRootGroup | NavigationMenuLink;
  depth: number;
};

export default function NavList({ data, depth }: NavListRootProps) {
  const collapseRef = useRef<HTMLDivElement | null>(null);
  const id = useId();
  const { pathname } = useLocation();

  const { active, isExternalLink } = useActiveLink(data.path ?? '');

  const { isOpen, onClose, onToggle } = useDisclosure({
    defaultIsOpen: active,
  });

  useEffect(() => {
    if (!active) {
      onClose();
    }
  }, [pathname]);

  useEffect(() => {
    if (isOpen) {
      collapseRef.current?.scrollIntoView({
        block: 'nearest',
        behavior: 'instant',
      });
    }
  }, []);

  const accordionProps: Partial<NavItemProps> = data?.children
    ? {
        id: `${id}-header`,
        'aria-expanded': isOpen,
        'aria-controls': id,
      }
    : {};

  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        open={isOpen}
        active={active}
        isExternalLink={isExternalLink}
        onClick={onToggle}
        {...accordionProps}
      />

      {data.children && (
        <Collapse
          ref={collapseRef}
          in={isOpen}
          addEndListener={(event) => {
            if (isOpen) {
              setTimeout(() => {
                event.scrollIntoView({
                  block: 'nearest',
                  behavior: 'smooth',
                });
              }, 275);
            }
          }}
          unmountOnExit
          aria-labelledby={`${id}-header`}
          id={id}
          role="region"
        >
          <NavSubList data={data.children} depth={depth} />
        </Collapse>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  data: NavigationMenuLink[];
  depth: number;
};

function NavSubList({ data, depth }: NavListSubProps) {
  return (
    <>
      {data.map((list) => (
        <NavList
          key={`${list.title}${list.path ?? ''}`}
          data={list}
          depth={depth + 1}
        />
      ))}
    </>
  );
}

import {
  Box,
  Collapse,
  Divider,
  InputAdornment,
  InputLabel,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { PaymentMethod, usePermissions } from '@tyro/api';
import {
  RHFRadioGroup,
  RHFTextField,
  useFormValidator,
  usePreferredNameLayout,
} from '@tyro/core';
import { Trans, useFormatNumber, useTranslation } from '@tyro/i18n';
import { InfoCircleIcon } from '@tyro/icons';
import { RHFContactAutocomplete } from '@tyro/people';
import get from 'lodash/get';
import { useEffect, useId, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useServiceCharge } from '../../../api/service-charge';
import type { ReturnTypeFromUseStudentFees } from '../../../api/student-fees';
import { PaymentMethodSelect } from './fields/payment-method';
import { type PaymentsToPayAndMethod, usePayFeesSettings } from './store';

interface PayFeesStepOneProps {
  feesToPay: ReturnTypeFromUseStudentFees[];
}

type FormValues = Omit<PaymentsToPayAndMethod, 'total'> & {
  paymentType: 'full' | 'partial';
};

export function PayFeesStepOne({ feesToPay }: PayFeesStepOneProps) {
  const { t } = useTranslation(['common', 'fees']);
  const componentId = useId();
  const { isStaffUser } = usePermissions();
  const { displayName } = usePreferredNameLayout();
  const { formatCurrency } = useFormatNumber();
  const {
    setNextAction,
    paymentsToPayAndMethod,
    setPaymentsToPayAndMethod,
    nextStep,
  } = usePayFeesSettings();

  const { resolver, rules } = useFormValidator<FormValues>();
  const { handleSubmit, control, watch, setValue } = useForm<FormValues>({
    resolver: resolver({
      paymentMethod: [rules.required()],
      onBehalfOf: rules.required(),
      details: rules.maxLength(200),
      fees: {
        amountToPay: [
          rules.required(),
          rules.isNumber(),
          rules.min(0.5),
          rules.validate<string>((value, throwError, formValues, fieldName) => {
            const pathToCurrentFee = fieldName.substring(
              0,
              fieldName.lastIndexOf('.'),
            );
            const fee = get(
              formValues,
              pathToCurrentFee,
            ) as FormValues['fees'][number];
            const valueAsNumber = Number(value);

            if ((valueAsNumber ?? 0) > fee.amount - fee.amountPaid) {
              throwError(t('fees:amountToPayExceedsTheAmountThatsDue'));
            }
          }),
        ],
      },
    }),
    defaultValues: {
      paymentMethod:
        paymentsToPayAndMethod?.paymentMethod ?? PaymentMethod.Card,
      paymentType: 'full',
      fees:
        paymentsToPayAndMethod?.fees ??
        feesToPay.map((fee) => ({
          ...fee,
          amountToPay: fee.amountDue ?? 0,
        })),
    },
  });

  const [paymentMethod, paymentType, fees] = watch([
    'paymentMethod',
    'paymentType',
    'fees',
  ]);
  const { fields } = useFieldArray({
    control,
    name: 'fees',
    keyName: 'fieldId',
  });

  const { data: serviceCharge } = useServiceCharge({
    charges: fees.map(({ id, amountToPay }) => ({
      feeId: id.feeId,
      amount: amountToPay,
    })),
  });

  const total = useMemo(
    () => fees.reduce((acc, fee) => acc + Number(fee.amountToPay), 0),
    [JSON.stringify(fees)],
  );
  const studentIds = useMemo(
    () => feesToPay.map((fee) => fee.person.partyId),
    [feesToPay],
  );

  const onSubmit = handleSubmit((values) => {
    setPaymentsToPayAndMethod({
      total: values.fees.reduce((acc, fee) => acc + Number(fee.amountToPay), 0),
      paymentMethod: values.paymentMethod,
      fees: values.fees.map((fee) => ({
        ...fee,
        amountToPay: Number(fee.amountToPay),
      })),
      onBehalfOf: values?.onBehalfOf,
      details: values.details,
    });
    nextStep();
  });

  useEffect(() => {
    if (paymentType === 'full') {
      setValue(
        'fees',
        fees.map((fee) => ({
          ...fee,
          amountToPay: fee.amountDue ?? 0,
        })),
      );
    }
  }, [paymentType]);

  useEffect(() => {
    setNextAction(() => onSubmit);
  }, []);

  const descriptionId = `${componentId}-description`;
  const totalId = `${componentId}-total`;

  const onlyPaidInFull = fields.every((field) => !field.allowPartialPayments);

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={3}>
        {isStaffUser && (
          <>
            <PaymentMethodSelect
              controlProps={{
                name: 'paymentMethod',
                control,
              }}
            />
            <RHFContactAutocomplete
              controlProps={{
                name: 'onBehalfOf',
                control,
              }}
              label={t('fees:onBehalfOf')}
              contactsFilter={{
                studentPartyIds: studentIds,
              }}
            />
            <RHFTextField
              controlProps={{
                name: 'details',
                control,
              }}
              label={t('common:details')}
              textFieldProps={{
                multiline: true,
                minRows: 2,
                inputProps: {
                  maxLength: 200,
                },
              }}
            />
          </>
        )}
        <Tooltip
          title={
            onlyPaidInFull
              ? t('fees:partialPaymentsNotAllowed', { count: fields.length })
              : undefined
          }
          placement="top-start"
        >
          <Box display="inline">
            <RHFRadioGroup
              disabled={onlyPaidInFull}
              controlProps={{
                name: 'paymentType',
                control,
              }}
              radioGroupProps={{
                row: true,
              }}
              label={
                <Stack direction="row" gap={0.5} alignItems="center">
                  {t('fees:paymentType')}
                  {onlyPaidInFull && (
                    <InfoCircleIcon sx={{ width: 18, height: 18 }} />
                  )}
                </Stack>
              }
              options={[
                {
                  label: t('fees:payInFull'),
                  value: 'full',
                },
                {
                  label: t('fees:partialPayment'),
                  value: 'partial',
                },
              ]}
            />
          </Box>
        </Tooltip>
        <Stack
          spacing={2}
          sx={{
            backgroundColor: 'slate.100',
            borderRadius: 2,
            p: 1.5,
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography
              variant="subtitle1"
              color="slate.500"
              id={descriptionId}
            >
              {t('common:description')}
            </Typography>
            <Typography variant="subtitle1" color="slate.500" id={totalId}>
              {t('common:total')}
            </Typography>
          </Stack>
          <Stack spacing={2}>
            {fields.map((fee, index) => {
              const { feeName, person } = fee;
              const studentName = displayName(person);
              const partialInputId = `${componentId}-partial-${index}`;

              return (
                <Stack
                  key={fee.fieldId}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="subtitle1"
                    component="span"
                    aria-labelledby={descriptionId}
                    sx={{
                      lineHeight: 1.2,
                    }}
                  >
                    <Trans ns="fees" i18nKey="feeNameForStudent">
                      {{ feeName }}
                      <br />
                      <Typography
                        variant="body2"
                        component="span"
                        color="text.secondary"
                      >
                        <>for {{ studentName }}</>
                      </Typography>
                    </Trans>
                  </Typography>

                  <Stack
                    alignItems="flex-end"
                    spacing={1}
                    aria-labelledby={totalId}
                  >
                    <Typography variant="subtitle1" component="p">
                      {formatCurrency(fee.amountDue ?? 0)}
                    </Typography>
                    <Collapse in={paymentType === 'partial'}>
                      <Tooltip
                        title={
                          fee.allowPartialPayments
                            ? undefined
                            : t('fees:partialPaymentsNotAllowed', { count: 1 })
                        }
                        arrow
                        placement="top"
                      >
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <InputLabel htmlFor={partialInputId}>
                            {t('fees:partial')}
                          </InputLabel>
                          <RHFTextField
                            controlProps={{
                              name: `fees.${index}.amountToPay`,
                              control,
                            }}
                            textFieldProps={{
                              id: partialInputId,
                              disabled: !fee.allowPartialPayments,
                              sx: {
                                width: 100,
                                '& .MuiInputBase-root': {
                                  backgroundColor: 'white',
                                },
                              },
                              size: 'small',
                              InputProps: {
                                startAdornment: (
                                  <InputAdornment position="start">
                                    €
                                  </InputAdornment>
                                ),
                              },
                            }}
                          />
                        </Stack>
                      </Tooltip>
                    </Collapse>
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
          <Divider sx={{ borderColor: 'slate.200' }} />
          <Stack alignItems="flex-end">
            <Typography variant="subtitle1">
              {t('fees:total')} {formatCurrency(total)}
            </Typography>
            <Collapse in={paymentMethod === PaymentMethod.Card}>
              <Typography variant="body1">
                {t('fees:serviceFee')}{' '}
                {formatCurrency(
                  (serviceCharge?.userServiceCharge ?? 0) +
                    (serviceCharge?.userVat ?? 0),
                )}
              </Typography>
            </Collapse>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
}

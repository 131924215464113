import { useMutation } from '@tanstack/react-query';

import { type NoticeAction, gqlClient, graphql, queryClient } from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { noticeBoardKeys } from './keys';

const pinNotice = graphql(/* GraphQL */ `
  mutation communications_pinNotice($input: NoticeAction) {
    communications_pinNotice(input: $input) {
      success
    }
  }
`);

export function usePinNotice() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationFn: async (input: NoticeAction) =>
      gqlClient.request(pinNotice, { input }),
    onSuccess: async (_, notice) => {
      await queryClient.invalidateQueries({ queryKey: noticeBoardKeys.all });

      toast(
        notice
          ? t('common:snackbarMessages.updateSuccess')
          : t('common:snackbarMessages.createSuccess'),
      );
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

import { Button, MenuItem, MenuList, Typography } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@tyro/core';
import { availableLanguages, useTranslation } from '@tyro/i18n';
import { useMemo } from 'react';

interface LanguageSwitchModalProps {
  open: boolean;
  onClose: () => void;
}

export function LanguageSwitchModal({
  open,
  onClose,
}: LanguageSwitchModalProps) {
  const { t, i18n } = useTranslation(['common', 'settings']);
  const currentLanguage = useMemo(() => {
    const currentLanguageCode =
      i18n.language as (typeof availableLanguages)[number];
    return {
      code: currentLanguageCode,
      label: t(`settings:language.${currentLanguageCode}`),
    };
  }, [i18n.language]);

  const handleChangeLang = (newLang: string) => {
    i18n.changeLanguage(newLang);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle onClose={onClose}>{t('common:switchLanguage')}</DialogTitle>
      <DialogContent>
        <MenuList sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          {availableLanguages.map((code) => (
            <MenuItem
              key={code}
              sx={{ borderRadius: 1.5 }}
              selected={code === currentLanguage.code}
              onClick={() => handleChangeLang(code)}
            >
              <Typography
                component="h3"
                variant="subtitle2"
                color="text.primary"
              >
                {t(`settings:language.${code}`)}
              </Typography>
            </MenuItem>
          ))}
        </MenuList>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="soft" color="inherit">
          {t('common:actions.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

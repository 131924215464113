import { useQuery } from '@tanstack/react-query';

import {
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { timetableKeys } from '../keys';

const subjectCompetenciesMeta = graphql(/* GraphQL */ `
  query ttsolve_competenciesMeta {
    ttsolve_competenciesMeta {
      groups {
        id
        name
      }
    }
  }
`);

const subjectCompetenciesMetaQuery = {
  queryKey: timetableKeys.subjectCompetenciesMeta(),
  queryFn: async () => gqlClient.request(subjectCompetenciesMeta),
};

export async function getSubjectCompetenciesMeta() {
  return queryClient.fetchQuery(subjectCompetenciesMetaQuery);
}

export function useSubjectCompetenciesMeta() {
  return useQuery({
    ...subjectCompetenciesMetaQuery,
    select: ({ ttsolve_competenciesMeta }) =>
      ttsolve_competenciesMeta?.groups || [],
  });
}

export type ReturnTypeFromUseSubjectCompetenciesMeta = UseQueryReturnType<
  typeof useSubjectCompetenciesMeta
>[number];

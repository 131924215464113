import { useQuery } from '@tanstack/react-query';
import {
  type Tt_BlockFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { timetableKeys } from '../keys';

const ttBlocks = graphql(/* GraphQL */ `
  query ttBlocks($filter: TT_BlockFilter!) {
    tt_blocks(filter: $filter) {
      blockId
      name
      ttClassGroupIds
    }
  }
`);

const ttBlocksQuery = (filter: Tt_BlockFilter) => ({
  queryKey: timetableKeys.blocks(filter),
  queryFn: async () => {
    const { tt_blocks: ttBlocksResponse } = await gqlClient.request(ttBlocks, {
      filter,
    });
    return {
      tt_blocks: ttBlocksResponse.sort((prev, next) =>
        prev.name.localeCompare(next.name),
      ),
    };
  },
});

export function getTtBlocksList(filter: Tt_BlockFilter) {
  return queryClient.fetchQuery(ttBlocksQuery(filter));
}

export function useTtBlocksList(filter: Tt_BlockFilter) {
  return useQuery({
    ...ttBlocksQuery(filter),
    select: ({ tt_blocks }) => tt_blocks,
  });
}

export type ReturnTypeOfUseTtBlocksList = UseQueryReturnType<
  typeof useTtBlocksList
>[number];

import type { ReturnTypeFromUseStudentDetailsForNTK } from '../api/student/students';

export function getContactRelationship(
  relationships: NonNullable<
    ReturnTypeFromUseStudentDetailsForNTK['contacts']
  >[number]['relationships'],
  partyId: number,
) {
  return (
    relationships?.find(({ studentPartyId }) => partyId === studentPartyId) ??
    null
  );
}

export function getPrimaryContact(
  studentPartyId: number,
  contacts: ReturnTypeFromUseStudentDetailsForNTK['contacts'],
) {
  return (contacts
    ?.filter((contact) => {
      const relationship = getContactRelationship(
        contact.relationships,
        studentPartyId,
      );

      return relationship?.allowedToContact;
    })
    .sort((a, b) => {
      const relationshipA = getContactRelationship(
        a.relationships,
        studentPartyId,
      );
      const relationshipB = getContactRelationship(
        b.relationships,
        studentPartyId,
      );
      return (relationshipA?.priority ?? 5) - (relationshipB?.priority ?? 5);
    }) ?? [])[0];
}

import { AssessmentType, Core_PartySource, Iterator } from '@tyro/api';
import { getAcademicNamespace } from '@tyro/api';
import {
  getAttendanceCodes,
  getLessonTimes,
  getRollBookAttendance,
} from '@tyro/attendance';
import {
  type NavObjectFunction,
  NavObjectType,
  getNumber,
  lazyWithRetry,
  throw404Error,
} from '@tyro/core';
import { UserProfileCardIcon } from '@tyro/icons';
import { redirect } from 'react-router-dom';

import { getInClassAssessmentsList } from '@tyro/assessments';
import { getTodayTimetableEvents } from '@tyro/calendar';
import { getStudentBehaviour } from '@tyro/people';
import dayjs from 'dayjs';
import {
  getClassGroups,
  getClassGroupsById,
  getCustomGroupById,
  getCustomGroupDefinition,
  getCustomGroups,
  getSubjectGroupById,
  getSubjectGroupLesson,
  getSubjectGroups,
} from './api';
import { getSupportGroupById, getSupportGroups } from './api/support-groups';
import { getYearGroupById, getYearGroups } from './api/year-groups';
import { getValidEventStartTime } from './utils/get-valid-event-start-time';

// year group

const YearGroupContainer = lazyWithRetry(
  () => import('./components/year-group/container'),
);
const YearGroups = lazyWithRetry(() => import('./pages/year'));
const YearGroupsStudentsPage = lazyWithRetry(
  () => import('./pages/year/profile/students'),
);
const YearGroupAttendancePage = lazyWithRetry(
  () => import('./pages/year/profile/attendance'),
);
const YearGroupProfileTimetablePage = lazyWithRetry(
  () => import('./pages/year/profile/timetable'),
);

// class group

const ClassGroupContainer = lazyWithRetry(
  () => import('./components/class-group/container'),
);
const ClassGroups = lazyWithRetry(() => import('./pages/class'));
const ClassGroupProfileTimetablePage = lazyWithRetry(
  () => import('./pages/class/timetable'),
);
const ClassGroupsStudentsPage = lazyWithRetry(
  () => import('./pages/class/students'),
);
const ClassGroupAttendancePage = lazyWithRetry(
  () => import('./pages/class/attendance'),
);
const SubjectGroupsPage = lazyWithRetry(
  () => import('./pages/class/subject-groups'),
);

// subject group

const SubjectGroupContainer = lazyWithRetry(
  () => import('./components/subject-group/container'),
);
const SubjectGroups = lazyWithRetry(() => import('./pages/subject'));
const SubjectGroupProfileStudentsPage = lazyWithRetry(
  () => import('./pages/subject/profile/students'),
);
const SubjectProfileAssessmentsPage = lazyWithRetry(
  () => import('./pages/subject/profile/assessments'),
);
const SubjectGroupProfileAttendancePage = lazyWithRetry(
  () => import('./pages/subject/profile/attendance'),
);
const SubjectGroupProfileTimetablePage = lazyWithRetry(
  () => import('./pages/subject/profile/timetable'),
);
const SubjectGroupProfileBehaviourPage = lazyWithRetry(
  () => import('./pages/subject/profile/behaviour'),
);
const SubjectGroupProfileRollBookPage = lazyWithRetry(
  () => import('./pages/subject/profile/roll-book'),
);
// support group

const SupportGroupContainer = lazyWithRetry(
  () => import('./components/support-group/container'),
);
const SupportGroups = lazyWithRetry(() => import('./pages/support'));
const SupportGroupProfileStudentsPage = lazyWithRetry(
  () => import('./pages/support/profile/students'),
);
const SupportGroupProfileAttendancePage = lazyWithRetry(
  () => import('./pages/support/profile/attendance'),
);
const SupportGroupProfileTimetablePage = lazyWithRetry(
  () => import('./pages/support/profile/timetable'),
);
const SupportGroupProfileRollBookPage = lazyWithRetry(
  () => import('./pages/support/profile/roll-book'),
);

// custom group

const CustomGroupContainer = lazyWithRetry(
  () => import('./components/custom-group/container'),
);
const CustomGroups = lazyWithRetry(() => import('./pages/custom'));
const CreateCustomGroupPage = lazyWithRetry(
  () => import('./pages/custom/create'),
);
const EditCustomGroupPage = lazyWithRetry(() => import('./pages/custom/edit'));
const CustomGroupStudentsPage = lazyWithRetry(
  () => import('./pages/custom/profile/students'),
);
const CustomGroupStaffPage = lazyWithRetry(
  () => import('./pages/custom/profile/staff'),
);
const CustomGroupTimetablePage = lazyWithRetry(
  () => import('./pages/custom/profile/timetable'),
);
const CustomGroupAttendancePage = lazyWithRetry(
  () => import('./pages/custom/profile/attendance'),
);
const CustomGroupRollBookPage = lazyWithRetry(
  () => import('./pages/custom/profile/roll-book'),
);

function loadGroupAttendanceData(groupId: number) {
  const fromDate = dayjs().startOf('week').format('YYYY-MM-DD');
  const toDate = dayjs().endOf('week').format('YYYY-MM-DD');

  return Promise.all([
    getSubjectGroupById(groupId),
    getLessonTimes({
      resources: {
        partyIds: [groupId],
      },
      startDate: fromDate,
      endDate: toDate,
    }),
    getRollBookAttendance({
      groupId: groupId,
      from: fromDate,
      to: toDate,
    }),
    getAttendanceCodes({}),
  ]);
}

export const getRoutes: NavObjectFunction = (t) => [
  {
    type: NavObjectType.Category,
    title: t('navigation:general.title'),
    children: [
      {
        type: NavObjectType.RootGroup,
        path: 'groups',
        icon: <UserProfileCardIcon />,
        hasAccess: (permissions) => permissions.isStaffUser,
        title: t('navigation:general.groups.title'),
        children: [
          {
            type: NavObjectType.MenuLink,
            path: 'year',
            title: t('navigation:general.groups.year'),
            hasAccess: (permissions) =>
              permissions.hasPermission('ps:1:groups:view_year_groups'),
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => getYearGroups(),
                element: <YearGroups />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: ':groupId',
                element: <YearGroupContainer />,
                loader: ({ params }) => {
                  const groupId = getNumber(params?.groupId);
                  if (!groupId) {
                    throw404Error();
                  }

                  return getYearGroupById({
                    yearGroupEnrollmentPartyId: [groupId],
                  });
                },
                children: [
                  {
                    type: NavObjectType.NonMenuLink,
                    index: true,
                    loader: () => redirect('./students'),
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'students',
                    element: <YearGroupsStudentsPage />,
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'timetable',
                    element: <YearGroupProfileTimetablePage />,
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'attendance',
                    hasAccess: (permissions) =>
                      permissions.hasPermission(
                        'ps:1:attendance:read_session_attendance_year_group',
                      ),
                    element: <YearGroupAttendancePage />,
                  },
                ],
              },
            ],
          },
          {
            type: NavObjectType.MenuLink,
            path: 'class',
            title: t('navigation:general.groups.class'),
            hasAccess: (permissions) =>
              permissions.hasPermission('ps:1:groups:view_class_groups'),
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => getClassGroups(),
                element: <ClassGroups />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: ':groupId',
                element: <ClassGroupContainer />,
                loader: ({ params }) => {
                  const groupId = getNumber(params?.groupId);
                  if (!groupId) {
                    throw404Error();
                  }

                  return getClassGroupsById(groupId);
                },
                children: [
                  {
                    type: NavObjectType.NonMenuLink,
                    index: true,
                    loader: () => redirect('./students'),
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'students',
                    element: <ClassGroupsStudentsPage />,
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'subject-groups',
                    element: <SubjectGroupsPage />,
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'timetable',
                    element: <ClassGroupProfileTimetablePage />,
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'attendance',
                    hasAccess: (permissions) =>
                      permissions.hasPermission(
                        'ps:1:attendance:read_session_attendance_class_group',
                      ),
                    element: <ClassGroupAttendancePage />,
                  },
                ],
              },
            ],
          },
          {
            type: NavObjectType.MenuLink,
            path: 'subject',
            title: t('navigation:general.groups.subject'),
            loader: () => getSubjectGroups(),
            hasAccess: (permissions) =>
              permissions.hasPermission('ps:1:groups:view_subject_groups'),
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => getSubjectGroups(),
                element: <SubjectGroups />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: ':groupId',
                element: <SubjectGroupContainer />,
                loader: async ({ params }) => {
                  const groupId = getNumber(params.groupId);

                  if (!groupId) {
                    throw404Error();
                  }

                  const { calendar_calendarEventsIterator: closestLesson } =
                    await getSubjectGroupLesson({
                      partyId: groupId,
                      iterator: Iterator.Closest,
                    });

                  return Promise.all([
                    getSubjectGroupById(groupId),
                    ...(closestLesson
                      ? [
                          getSubjectGroupLesson({
                            partyId: groupId,
                            eventStartTime: closestLesson.startTime,
                            iterator: Iterator.Next,
                          }),
                        ]
                      : []),
                  ]);
                },
                children: [
                  {
                    type: NavObjectType.NonMenuLink,
                    index: true,
                    loader: () => redirect('./attendance'),
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'students',
                    element: <SubjectGroupProfileStudentsPage />,
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'attendance',
                    element: <SubjectGroupProfileAttendancePage />,
                    loader: async ({ params }) => {
                      const groupId = getNumber(params.groupId);

                      if (!groupId) {
                        throw404Error();
                      }

                      const searchParams = new URLSearchParams(
                        document.location.search,
                      );

                      const eventStartTime = searchParams.get('eventStartTime');

                      return Promise.all([
                        getAttendanceCodes({ teachingGroupCodes: true }),
                        getSubjectGroupLesson({
                          partyId: groupId,
                          eventStartTime:
                            getValidEventStartTime(eventStartTime),
                          iterator: Iterator.Closest,
                        }),
                      ]);
                    },
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'timetable',
                    element: <SubjectGroupProfileTimetablePage />,
                    loader: ({ params }) => {
                      const groupId = getNumber(params.groupId);

                      return getTodayTimetableEvents(groupId);
                    },
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'behaviour',
                    element: <SubjectGroupProfileBehaviourPage />,
                    loader: ({ params }) => {
                      const groupId = getNumber(params.groupId);

                      if (!groupId) {
                        return throw404Error();
                      }

                      return getStudentBehaviour({
                        associatedPartyIds: [groupId],
                      });
                    },
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'roll-book',
                    element: <SubjectGroupProfileRollBookPage />,
                    loader: ({ params }) => {
                      const groupId = getNumber(params.groupId);

                      if (!groupId) {
                        return throw404Error();
                      }

                      return loadGroupAttendanceData(groupId);
                    },
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'assessments',
                    hasAccess: (permissions) =>
                      permissions.hasPermission(
                        'ps:1:assessment:read_assessments',
                      ),
                    loader: async ({ params }) => {
                      const groupId = getNumber(params.groupId);

                      const { activeAcademicNamespace } =
                        await getAcademicNamespace();
                      const activeNameSpaceId =
                        activeAcademicNamespace?.academicNamespaceId ?? 0;

                      if (!groupId) {
                        throw404Error();
                      }
                      return getInClassAssessmentsList({
                        academicNameSpaceId: activeNameSpaceId,
                        subjectGroupIds: [groupId],
                        assessmentType: AssessmentType.InClass,
                      });
                    },
                    element: <SubjectProfileAssessmentsPage />,
                  },
                ],
              },
            ],
          },
          {
            type: NavObjectType.MenuLink,
            path: 'support',
            title: t('navigation:general.groups.support'),
            loader: () => getSupportGroups(),
            hasAccess: ({ isStaffUserWithPermission }) =>
              isStaffUserWithPermission('ps:1:groups:view_subject_groups'),
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => getSupportGroups(),
                element: <SupportGroups />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: ':groupId',
                element: <SupportGroupContainer />,
                loader: async ({ params }) => {
                  const groupId = getNumber(params.groupId);

                  if (!groupId) {
                    throw404Error();
                  }

                  const { calendar_calendarEventsIterator: closestLesson } =
                    await getSubjectGroupLesson({
                      partyId: groupId,
                      iterator: Iterator.Closest,
                    });

                  return Promise.all([
                    getSupportGroupById(groupId),
                    ...(closestLesson
                      ? [
                          getSubjectGroupLesson({
                            partyId: groupId,
                            eventStartTime: closestLesson.startTime,
                            iterator: Iterator.Next,
                          }),
                        ]
                      : []),
                  ]);
                },
                children: [
                  {
                    type: NavObjectType.NonMenuLink,
                    index: true,
                    loader: () => redirect('./students'),
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'students',
                    element: <SupportGroupProfileStudentsPage />,
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'attendance',
                    element: <SupportGroupProfileAttendancePage />,
                    loader: async ({ params }) => {
                      const groupId = getNumber(params.groupId);

                      if (!groupId) {
                        throw404Error();
                      }

                      return Promise.all([
                        getAttendanceCodes({ teachingGroupCodes: true }),
                        getSubjectGroupLesson({
                          partyId: groupId,
                          iterator: Iterator.Closest,
                        }),
                      ]);
                    },
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'timetable',
                    element: <SupportGroupProfileTimetablePage />,
                    loader: ({ params }) => {
                      const groupId = getNumber(params.groupId);

                      return getTodayTimetableEvents(groupId);
                    },
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'roll-book',
                    element: <SupportGroupProfileRollBookPage />,
                    loader: ({ params }) => {
                      const groupId = getNumber(params.groupId);

                      if (!groupId) {
                        return throw404Error();
                      }

                      return loadGroupAttendanceData(groupId);
                    },
                  },
                ],
              },
            ],
          },
          {
            type: NavObjectType.MenuLink,
            path: 'custom',
            title: t('navigation:general.groups.custom'),
            hasAccess: (permissions) =>
              permissions.hasPermission('ps:1:groups:view_custom_groups'),
            children: [
              {
                type: NavObjectType.NonMenuLink,
                index: true,
                loader: () => getCustomGroups(Core_PartySource.SchoolActivity),
                element: <CustomGroups />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'create',
                element: <CreateCustomGroupPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'edit/:groupId',
                loader: ({ params }) => {
                  const groupId = getNumber(params?.groupId);
                  if (!groupId) {
                    throw404Error();
                  }

                  return getCustomGroupDefinition({ partyId: groupId });
                },
                element: <EditCustomGroupPage />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: ':groupId',
                element: <CustomGroupContainer />,
                loader: async ({ params }) => {
                  const groupId = getNumber(params.groupId);

                  if (!groupId) {
                    throw404Error();
                  }

                  return Promise.all([
                    getCustomGroupById(groupId),
                    getCustomGroupDefinition({ partyId: groupId }),
                  ]);
                },
                children: [
                  {
                    type: NavObjectType.NonMenuLink,
                    index: true,
                    loader: () => redirect('./students'),
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'students',
                    element: <CustomGroupStudentsPage />,
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'staff',
                    element: <CustomGroupStaffPage />,
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'attendance',
                    element: <CustomGroupAttendancePage />,
                    loader: async ({ params }) => {
                      const groupId = getNumber(params.groupId);
                      if (!groupId) {
                        throw404Error();
                      }
                      return Promise.all([
                        getAttendanceCodes({ teachingGroupCodes: true }),
                        getSubjectGroupLesson({
                          partyId: groupId,
                          iterator: Iterator.Closest,
                        }),
                      ]);
                    },
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'roll-book',
                    element: <CustomGroupRollBookPage />,
                    loader: ({ params }) => {
                      const groupId = getNumber(params.groupId);
                      if (!groupId) {
                        return throw404Error();
                      }

                      return loadGroupAttendanceData(groupId);
                    },
                  },
                  {
                    type: NavObjectType.NonMenuLink,
                    path: 'timetable',
                    element: <CustomGroupTimetablePage />,
                    loader: ({ params }) => {
                      const groupId = getNumber(params.groupId);
                      return getTodayTimetableEvents(groupId);
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

import { PageContainer, PageHeading, TabPageContainer } from '@tyro/core';

import { TemplatingContextType } from '@tyro/api';
import { useTranslation } from '@tyro/i18n';

export const templatesPaths: Record<string, TemplatingContextType> = {
  students: TemplatingContextType.Student,
  staff: TemplatingContextType.Staff,
};

export default function TemplatesContainer() {
  const { t } = useTranslation(['common', 'templates']);

  return (
    <PageContainer title={t('templates:title')}>
      <PageHeading
        title={t('templates:title')}
        titleProps={{ variant: 'h3' }}
      />
      <TabPageContainer
        links={[
          {
            label: t('common:students'),
            value: 'students',
          },
          {
            label: t('common:staff'),
            value: 'staff',
          },
        ]}
      />
    </PageContainer>
  );
}

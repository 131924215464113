import { useMutation, useQuery } from '@tanstack/react-query';
import {
  type Tt_ClassGroupsFilter,
  type Tt_DeleteClassGroups,
  type Tt_UpsertClassGroup,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { timetableKeys } from '../keys';

const ttClassGroups = graphql(/* GraphQL */ `
  query ttClassGroups($filter: TT_ClassGroupsFilter!) {
    tt_classGroups(filter: $filter) {
      ttClassGroupId
      partyId
      name
    }
  }
`);

const ttUpsertClassGroup = graphql(/* GraphQL */ `
  mutation tt_upsertClassGroup($input: Tt_UpsertClassGroup!) {
    tt_upsertClassGroup(input: $input) {
      success
    }
  }
`);

const ttDeleteClassGroup = graphql(/* GraphQL */ `
  mutation tt_deleteClassGroup($input: Tt_DeleteClassGroups!) {
    tt_deleteClassGroup(input: $input) {
      success
    }
  }
`);

const ttClassGroupsQuery = (filter: Tt_ClassGroupsFilter) => ({
  queryKey: timetableKeys.classGroups(filter),
  queryFn: async () =>
    gqlClient.request(ttClassGroups, {
      filter,
    }),
});

export function getTtClassGroups(filter: Tt_ClassGroupsFilter) {
  return queryClient.fetchQuery(ttClassGroupsQuery(filter));
}

export function useTtClassGroups(filter: Tt_ClassGroupsFilter) {
  return useQuery({
    ...ttClassGroupsQuery(filter),
    select: ({ tt_classGroups }) =>
      tt_classGroups.sort((a, b) => a.name.localeCompare(b.name)),
  });
}

export function useUpsertClassGroup() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationFn: async (input: Tt_UpsertClassGroup) =>
      gqlClient.request(ttUpsertClassGroup, { input }),
    onSuccess: async (_, { ttClassGroupId }) => {
      await queryClient.invalidateQueries({ queryKey: timetableKeys.all });
      toast(
        ttClassGroupId
          ? t('common:snackbarMessages.updateSuccess')
          : t('common:snackbarMessages.createSuccess'),
      );
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

export function useDeleteClassGroup() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationFn: async (input: Tt_DeleteClassGroups) =>
      gqlClient.request(ttDeleteClassGroup, { input }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: timetableKeys.all });
      toast(t('common:snackbarMessages.deleteSuccess'));
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

export type ReturnTypeFromUseTtClassGroups = UseQueryReturnType<
  typeof useTtClassGroups
>[number];

import { QueryOptions, useQuery } from '@tanstack/react-query';
import {
  type QueryAdditionalOptions,
  type Templating_TemplateFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { templatesKeys } from './keys';

const templates = graphql(/* GraphQL */ `
  query getTemplates($filter: Templating_TemplateFilter) {
    templating_getTemplates(filter: $filter) {
      id
      name
      createdByPartyId
      createdBy {
        partyId
      }
      createdOn
      template
      draft
      context
    }
  }
`);

const templatesQuery = (filter: Templating_TemplateFilter) => ({
  queryKey: templatesKeys.templates(filter),
  queryFn: () =>
    gqlClient.request(templates, {
      filter,
    }),
});

export function getTemplates(filter: Templating_TemplateFilter) {
  return queryClient.fetchQuery(templatesQuery(filter));
}

export function useTemplates(
  filter: Templating_TemplateFilter,
  { enabled = true }: QueryAdditionalOptions | undefined = {
    enabled: true,
  },
) {
  return useQuery({
    ...templatesQuery(filter),
    enabled,
    select: ({ templating_getTemplates }) => templating_getTemplates,
  });
}

export type ReturnTypeFromUseTemplates = UseQueryReturnType<
  typeof useTemplates
>[number];

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Collapse,
  Divider,
  InputAdornment,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import { RefundType } from '@tyro/api';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RHFRadioGroup,
  RHFTextField,
  useFormValidator,
} from '@tyro/core';
import { useFormatNumber, useTranslation } from '@tyro/i18n';
import dayjs from 'dayjs';
import get from 'lodash/get';
import { useEffect, useId, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import type { ReturnTypeFromUsePayments } from '../../api/payments';
import { useRefund } from '../../api/refund';

export type RefundModalProps = {
  isOpen: boolean;
  payments: ReturnTypeFromUsePayments[];
  onClose: () => void;
};

type AvailablePayment = ReturnTypeFromUsePayments & {
  receiptNo: number;
  amountToRefund?: number;
};

type FormState = {
  refundType: RefundType;
  availablePayments: AvailablePayment[];
};

const defaultValues: Partial<FormState> = {
  refundType: RefundType.Full,
  availablePayments: [],
};

export function RefundModal({ isOpen, payments, onClose }: RefundModalProps) {
  const { t } = useTranslation(['common', 'fees']);
  const { formatCurrency } = useFormatNumber();

  const { mutate: refundPayments, isPending } = useRefund();

  const { resolver, rules } = useFormValidator<FormState>();
  const { control, handleSubmit, reset, watch, setValue } = useForm<FormState>({
    resolver: resolver({
      availablePayments: {
        amountToRefund: [
          rules.required(),
          rules.isNumber(),
          rules.validate(
            (amountToRefund, throwError, formValues, fieldName) => {
              const pathToCurrentPayment = fieldName.substring(
                0,
                fieldName.lastIndexOf('.'),
              );

              const payment = get(
                formValues,
                pathToCurrentPayment,
              ) as AvailablePayment;

              if (Number(amountToRefund ?? 0) > payment.amount) {
                throwError(t('fees:amountToRefundExceedsTheAmountPaid'));
              }
            },
          ),
        ],
      },
    }),
    defaultValues,
  });

  const { fields } = useFieldArray({
    control,
    name: 'availablePayments',
  });

  const initialAvailablePayments = useMemo(
    () =>
      payments
        .filter((payment) => payment.amount > payment.amountRefunded)
        .map((payment) => {
          const currentAmount = payment.amount - payment.amountRefunded;

          return {
            ...payment,
            receiptNo: payment.id,
            amount: currentAmount,
            amountToRefund: currentAmount,
          };
        }),
    [payments],
  );

  useEffect(() => {
    if (isOpen) {
      reset({
        ...defaultValues,
        availablePayments: initialAvailablePayments,
      });
    }
  }, [isOpen]);

  const [refundType, paymentsToRefund] = watch([
    'refundType',
    'availablePayments',
  ]);

  useEffect(() => {
    if (refundType === RefundType.Full) {
      setValue('availablePayments', initialAvailablePayments);
    }
  }, [refundType]);

  const onSubmit = handleSubmit(({ availablePayments }) => {
    const paymentsWithAmountToRefund = availablePayments.filter(
      ({ amountToRefund }) => Number(amountToRefund) > 0,
    );

    if (paymentsWithAmountToRefund.length === 0) return;

    refundPayments(
      {
        feeId: paymentsWithAmountToRefund[0].fee.id ?? 0,
        studentPartyId: paymentsWithAmountToRefund[0].studentPartyId,
        transactions: paymentsWithAmountToRefund.map(
          ({ amountToRefund, id }) => ({
            paymentId: id,
            amount: Number(amountToRefund ?? 0),
          }),
        ),
      },
      { onSuccess: onClose },
    );
  });

  const totalToRefund = paymentsToRefund.reduce(
    (acc, refund) => acc + Number(refund.amountToRefund ?? 0),
    0,
  );

  const componentId = useId();
  const totalId = `${componentId}-total`;
  const descriptionId = `${componentId}-description`;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll="paper"
      fullWidth
      maxWidth="sm"
    >
      <form onSubmit={onSubmit}>
        <DialogTitle onClose={onClose}>
          {t('fees:refundPayment', { count: fields.length })}
        </DialogTitle>
        <DialogContent>
          <Stack gap={2} mt={1}>
            {fields.length > 1 && (
              <Typography variant="body1">
                {t('fees:paymentsToRefundDescription')}
              </Typography>
            )}
            <RHFRadioGroup
              controlProps={{
                name: 'refundType',
                control,
              }}
              radioGroupProps={{
                row: true,
              }}
              label={t('fees:refundType')}
              options={[
                {
                  label: t('fees:refundInFull'),
                  value: RefundType.Full,
                },
                {
                  label: t('fees:partialRefund'),
                  value: RefundType.Partial,
                },
              ]}
            />
            <Stack gap={2} borderRadius={2} bgcolor="slate.100" p={1.5}>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  variant="subtitle1"
                  color="slate.500"
                  id={descriptionId}
                >
                  {t('common:description')}
                </Typography>
                <Typography variant="subtitle1" color="slate.500" id={totalId}>
                  {t('common:total')}
                </Typography>
              </Stack>
              <Stack gap={2}>
                {fields.map((payment, index) => {
                  const partialInputId = `${componentId}-partial-${index}`;

                  return (
                    <Stack
                      key={payment.id}
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Stack aria-labelledby={descriptionId}>
                        <Typography variant="subtitle1" component="span">
                          {formatCurrency(payment.amount)}
                        </Typography>

                        <Typography
                          variant="body2"
                          component="span"
                          color="text.secondary"
                        >
                          {`${t('fees:receiptNo')}: ${payment.receiptNo}`}
                        </Typography>
                        <Typography
                          variant="body2"
                          component="span"
                          color="text.secondary"
                        >
                          {`${t('fees:stripeId')}: ${
                            payment.paymentIntentId || '-'
                          }`}
                        </Typography>
                        <Typography
                          variant="body2"
                          component="span"
                          color="text.secondary"
                        >
                          {dayjs(payment.transactionDate).format('LLL')}
                        </Typography>
                        {payment.note && (
                          <Typography
                            variant="body2"
                            component="span"
                            color="text.secondary"
                          >
                            {payment.note}
                          </Typography>
                        )}
                      </Stack>

                      <Stack
                        alignItems="flex-end"
                        gap={1}
                        aria-labelledby={totalId}
                      >
                        <Typography variant="subtitle1" component="p">
                          {formatCurrency(payment.amount)}
                        </Typography>
                        <Collapse in={refundType === RefundType.Partial}>
                          <Stack direction="row" alignItems="baseline" gap={2}>
                            <InputLabel htmlFor={partialInputId}>
                              {t('fees:partial')}
                            </InputLabel>
                            <RHFTextField
                              controlProps={{
                                name: `availablePayments.${index}.amountToRefund`,
                                control,
                              }}
                              textFieldProps={{
                                id: partialInputId,
                                sx: {
                                  width: 100,
                                  '& .MuiInputBase-root': {
                                    backgroundColor: 'white',
                                  },
                                },
                                size: 'small',
                                InputProps: {
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      €
                                    </InputAdornment>
                                  ),
                                },
                              }}
                            />
                          </Stack>
                        </Collapse>
                      </Stack>
                    </Stack>
                  );
                })}
              </Stack>
              <Divider sx={{ borderColor: 'slate.200' }} />
              <Stack alignItems="flex-end">
                <Typography variant="subtitle1">
                  {t('fees:total')} {formatCurrency(totalToRefund)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="inherit"
            disabled={isPending}
            onClick={onClose}
          >
            {t('common:actions.cancel')}
          </Button>

          <LoadingButton loading={isPending} type="submit" variant="contained">
            {t('fees:refund')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

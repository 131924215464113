import { Chip, Stack } from '@mui/material';
import { RequestStatus } from '@tyro/api';
import { useMemo } from 'react';
import { useInfoRequestFormList } from '../api/form-list';

export function InfoRequestCountLabel() {
  const { data: infoRequests } = useInfoRequestFormList(
    {},
    { refetchInterval: 1000 * 60 * 5 },
  );

  const unreadCount = useMemo(() => {
    return (
      infoRequests?.filter(
        (infoRequest) => infoRequest.status !== RequestStatus.Complete,
      ).length ?? 0
    );
  }, [infoRequests]);

  return (
    <Stack direction="row" spacing={0.5}>
      {unreadCount > 0 && (
        <Chip
          label={unreadCount}
          size="small"
          variant="soft"
          color="primary"
          sx={{
            borderRadius: 0.75,
          }}
        />
      )}
    </Stack>
  );
}

import { useMutation } from '@tanstack/react-query';

import {
  type UpsertRoomInput,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { roomKeys } from './keys';

const createRooms = graphql(/* GraphQL */ `
  mutation core_upsertRooms($input: [UpsertRoomInput]) {
    core_upsertRooms(input: $input) {
      roomId
    }
  }
`);

export function useCreateOrUpdateRoom() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationKey: roomKeys.createOrUpdateRoom(),
    mutationFn: async (input: UpsertRoomInput[]) =>
      gqlClient.request(createRooms, { input }),
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
    onSuccess: (_, variables) => {
      if (variables?.[0]?.roomId) {
        toast(t('common:snackbarMessages.updateSuccess'));
      } else {
        toast(t('common:snackbarMessages.createSuccess'));
      }
      queryClient.invalidateQueries({ queryKey: roomKeys.all });
    },
  });
}

import { useQuery } from '@tanstack/react-query';
import {
  type Print_FeeOptions,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { feeKeys } from './keys';

const printFees = graphql(/* GraphQL */ `
  query printFees($filter: Print_FeeOptions!) {
    print_fees(filter: $filter) {
      url
      html
    }
  }
`);
const printFeesQuery = (filter: Print_FeeOptions) => ({
  queryKey: feeKeys.printFees(filter),
  staleTime: 0,
  queryFn: () => gqlClient.request(printFees, { filter }),
});

export function getPrintFees(filter: Print_FeeOptions) {
  return queryClient.fetchQuery(printFeesQuery(filter));
}

export function usePrintFees(filter: Print_FeeOptions) {
  return useQuery({
    ...printFeesQuery(filter),
    select: ({ print_fees }) => print_fees,
  });
}

export type ReturnTypeFromUsePrintFees = UseQueryReturnType<
  typeof usePrintFees
>;

import { Chip, Stack, Typography } from '@mui/material';
import { getColorBasedOnIndex } from '@tyro/api';
import { LineClampContainer, LinkRender } from '@tyro/core';
import dayjs from 'dayjs';
import type { ReturnTypeFromUseNotes } from '../../../api/note/list';

interface NTKNoteProps {
  note: ReturnTypeFromUseNotes;
}

export function NTKNote({
  note: { priorityStartDate, priorityEndDate, note, tags },
}: NTKNoteProps) {
  const title =
    priorityStartDate && priorityEndDate
      ? `${dayjs(priorityStartDate).format('D MMM')} - ${dayjs(
          priorityEndDate,
        ).format('D MMM')}`
      : null;

  return (
    <Stack
      sx={{
        mt: 2,
        px: 0.5,
      }}
    >
      {title && (
        <Typography variant="subtitle1" component="h4">
          {title}
        </Typography>
      )}
      <LineClampContainer numberOfLines={3} lineHeight={24} allowExpand>
        <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
          <LinkRender text={note} />
        </Typography>
      </LineClampContainer>
      {tags.length > 0 && (
        <Stack direction="row" gap={1} mt={0.5} flexWrap="wrap">
          {tags.map((tag) => (
            <Chip
              key={tag.id}
              label={tag.name}
              variant="soft"
              color={getColorBasedOnIndex(tag.id)}
              size="small"
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
}

import type {
  TtGridFilter,
  TtIndividualViewLessonFilter,
  TtResourceTimetableViewFilter,
  TtSolve_ClassDataFilter,
  TtSolve_SolveParams,
  TtSolve_SubjectCompetenciesFilter,
  TtSolver_ScheduleFilter,
  TtSolver_ScheduleLessonFilter,
  TtSwapRoomFilter,
  TtSwapTeacherFilter,
  TtTimetableFilter,
  TtTimetableListFilter,
  TtValidation_Filter,
  Tt_AddLessonFilter,
  Tt_BlockFilter,
  Tt_ClassGroupsFilter,
  Tt_EditLessonFilter,
  Tt_GroupsFilter,
  Tt_RoomAssignmentFilter,
  Tt_TeacherAssignmentFilter,
} from '@tyro/api';

export const timetableKeys = {
  all: ['timetable'] as const,
  timetableList: (filter: TtTimetableListFilter) =>
    [...timetableKeys.all, filter] as const,
  unpublishedChanges: (filter: TtTimetableFilter) =>
    [...timetableKeys.all, 'unpublishedChanges', filter] as const,
  timetable: (filter: TtTimetableFilter) =>
    [...timetableKeys.all, filter] as const,
  timetables: (id: number | undefined) => [...timetableKeys.all, id] as const,
  resourceView: (filter: TtResourceTimetableViewFilter) =>
    [...timetableKeys.all, 'resourceView', filter] as const,
  availableTeachersForResource: (filter: TtSwapTeacherFilter) =>
    [...timetableKeys.all, 'teachersForResource', filter] as const,
  availableRoomsForResource: (filter: TtSwapRoomFilter) =>
    [...timetableKeys.all, 'roomsForResource', filter] as const,
  ttGroups: (filter: Tt_GroupsFilter) =>
    [...timetableKeys.all, 'ttGroups', filter] as const,
  grids: (filter: TtGridFilter) =>
    [...timetableKeys.all, 'ttGrids', filter] as const,
  gridList: (filter: TtGridFilter) =>
    [...timetableKeys.all, 'ttGridList', filter] as const,
  addLessonsOptions: (filter: Tt_AddLessonFilter) =>
    [...timetableKeys.all, 'addLessonsOptions', filter] as const,
  editLessonsOptions: (filter: Tt_EditLessonFilter) =>
    [...timetableKeys.all, 'editLessonsOptions', filter] as const,
  search: (query: string) => [...timetableKeys.all, 'search', query] as const,
  solveInput: (filter: TtSolve_SolveParams) =>
    [...timetableKeys.all, 'solveInput', filter] as const,
  restrictions: (filter: TtSolve_SolveParams) =>
    [...timetableKeys.all, 'restrictions', filter] as const,
  restrictionsMeta: (filter: TtSolve_SolveParams) =>
    [...timetableKeys.all, 'restrictionsMeta', filter] as const,
  subjectCompetencies: (filter: TtSolve_SubjectCompetenciesFilter) =>
    [...timetableKeys.all, 'subjectCompetencies', filter] as const,
  subjectCompetenciesMeta: () =>
    [...timetableKeys.all, 'subjectCompetenciesMeta'] as const,
  classGroups: (filter: Tt_ClassGroupsFilter) =>
    [...timetableKeys.all, 'classGroups', filter] as const,
  blocks: (filter: Tt_BlockFilter) =>
    [...timetableKeys.all, 'blocks', filter] as const,
  viewClassData: (filter: TtSolve_ClassDataFilter) =>
    [...timetableKeys.all, 'viewClassData', filter] as const,
  teacherAssignmentStats: (filter: Tt_TeacherAssignmentFilter) =>
    [...timetableKeys.all, 'teacherAssignmentStats', filter] as const,
  teacherAssignments: (filter: Tt_TeacherAssignmentFilter) =>
    [...timetableKeys.all, 'teacherAssignments', filter] as const,
  roomAssignmentStats: (filter: Tt_RoomAssignmentFilter) =>
    [...timetableKeys.all, 'roomAssignmentStats', filter] as const,
  roomAssignments: (filter: Tt_RoomAssignmentFilter) =>
    [...timetableKeys.all, 'roomAssignments', filter] as const,
  competencyByTeacher: (filter: TtSolve_SubjectCompetenciesFilter) =>
    [...timetableKeys.all, 'competencyByTeacher', filter] as const,
  subjectOptionsForImport: (filter: TtTimetableFilter) =>
    [...timetableKeys.all, 'subjectOptionsForImport', filter] as const,
  allScheduleViews: () => [...timetableKeys.all, 'scheduleView'] as const,
  scheduleView: (filter: TtSolver_ScheduleFilter) =>
    [...timetableKeys.allScheduleViews(), filter] as const,
  allSolveStatuses: () => [...timetableKeys.all, 'solveStatus'] as const,
  solveStatus: (filter: TtSolve_SolveParams) =>
    [...timetableKeys.allSolveStatuses(), filter] as const,
  allLessonOptions: () => [...timetableKeys.all, 'lessonOptions'] as const,
  lessonOptions: (filter: TtSolver_ScheduleLessonFilter) =>
    [...timetableKeys.allLessonOptions(), filter] as const,
  allIndividualLessons: () =>
    [...timetableKeys.all, 'individualLessons'] as const,
  individualLessons: (filter: TtIndividualViewLessonFilter) =>
    [...timetableKeys.allIndividualLessons(), filter] as const,
  scheduleValidations: (filter: TtValidation_Filter) =>
    [...timetableKeys.all, 'scheduleValidations', filter] as const,
  solveConfig: (filter: TtSolve_SolveParams) =>
    [...timetableKeys.all, 'solveConfig', filter] as const,
  solverSwappedTeachers: (filter: TtTimetableFilter) =>
    [...timetableKeys.all, 'solverSwappedTeachers', filter] as const,
  groupsNotInTimetable: (filter: TtTimetableFilter) =>
    [...timetableKeys.all, 'groupsNotInTimetable', filter] as const,
};

import { LoadingButton } from '@mui/lab';
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { WriteOffAction } from '@tyro/api';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RHFTextField,
  useFormValidator,
  usePreferredNameLayout,
} from '@tyro/core';
import { useFormatNumber, useTranslation } from '@tyro/i18n';
import { TrashIcon } from '@tyro/icons';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import type { ReturnTypeFromUseStudentFees } from '../../api/student-fees';
import { useWriteOffBalance } from '../../api/write-off-balance';

type WriteOffBalanceMultipleFeesModalProps = {
  isOpen: boolean;
  feesToWriteOff: ReturnTypeFromUseStudentFees[];
  onClose: () => void;
};

type FormState = {
  note?: string;
  feesToWriteOff: ReturnTypeFromUseStudentFees[];
};

const defaultValues: Partial<FormState> = {
  feesToWriteOff: [],
};

export function WriteOffBalanceMultipleFeesModal({
  isOpen,
  feesToWriteOff,
  onClose,
}: WriteOffBalanceMultipleFeesModalProps) {
  const { t } = useTranslation(['common', 'fees']);
  const { displayName } = usePreferredNameLayout();
  const { formatCurrency } = useFormatNumber();

  const { mutate: writeOffBalance, isPending } = useWriteOffBalance();

  const { resolver, rules } = useFormValidator<FormState>();
  const { control, handleSubmit, reset, setValue, watch } = useForm<FormState>({
    resolver: resolver({
      note: rules.maxLength(100),
      feesToWriteOff: rules.required(),
    }),
    defaultValues,
  });

  useEffect(() => {
    if (isOpen) {
      reset({
        ...defaultValues,
        feesToWriteOff,
      });
    }
  }, [isOpen]);

  const [currentFeesToWriteOff] = watch(['feesToWriteOff']);

  const removeFee = useCallback(
    (feeId: number) => {
      setValue(
        'feesToWriteOff',
        currentFeesToWriteOff.filter(({ id }) => feeId !== id.feeId),
      );
    },
    [setValue, currentFeesToWriteOff],
  );

  const onSubmit = handleSubmit(
    ({ note, feesToWriteOff: feesToWriteOffForm }) => {
      writeOffBalance(
        feesToWriteOffForm.map(({ id, person }) => ({
          feeId: id.feeId,
          action: WriteOffAction.WriteOff,
          note,
          partyIds: [person.partyId],
        })),
        {
          onSuccess: onClose,
        },
      );
    },
  );

  const studentName = useMemo(() => {
    const [currentStudent] = feesToWriteOff || [];

    if (!currentStudent) return '';
    const { person, classGroup } = currentStudent;

    return `${displayName(person)} ${classGroup ? `(${classGroup?.name})` : ''}`;
  }, [feesToWriteOff]);

  const disableRemoveFee = currentFeesToWriteOff.length === 1;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll="paper"
      fullWidth
      maxWidth="xs"
    >
      <form onSubmit={onSubmit}>
        <DialogTitle onClose={onClose}>
          {t('fees:writeOffRemainingBalance')}
        </DialogTitle>
        <DialogContent>
          <Stack gap={2} mt={1}>
            <Typography variant="body1">
              {t('fees:writeOffRemainingBalanceMultipleFees', { studentName })}
            </Typography>
            <RHFTextField
              controlProps={{
                name: 'note',
                control,
              }}
              label={t('common:note')}
              textFieldProps={{
                multiline: true,
                minRows: 2,
                inputProps: {
                  maxLength: 200,
                },
              }}
            />

            <List>
              {currentFeesToWriteOff.map(({ id, feeName, amountDue }) => (
                <ListItem
                  key={id.feeId}
                  secondaryAction={
                    <Tooltip
                      title={
                        disableRemoveFee
                          ? t('fees:youMustHaveAtLeastOneFee')
                          : t('fees:removeFee')
                      }
                    >
                      <span>
                        <IconButton
                          aria-label={t('fees:removeFee')}
                          disabled={disableRemoveFee}
                          color="primary"
                          onClick={() => removeFee(id.feeId)}
                        >
                          <TrashIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  }
                >
                  <ListItemText
                    primary={feeName}
                    secondary={t('fees:currentBalance', {
                      amount: formatCurrency(amountDue),
                    })}
                  />
                </ListItem>
              ))}
            </List>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            color="inherit"
            disabled={isPending}
            onClick={onClose}
          >
            {t('common:actions.cancel')}
          </Button>

          <LoadingButton loading={isPending} type="submit" variant="contained">
            {t('common:actions.confirm')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

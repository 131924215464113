import { useQuery } from '@tanstack/react-query';
import {
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { rulesKeys } from './keys';

const rulesRuleMeta = graphql(/* GraphQL */ `
  query rules_ruleTypesMetadata {
    rules_ruleTypesMetadata {
      criteriaType
      ruleType
      name
      description
      ... on Rules_RuleMetaData_AggregateMatch {
        filterFields {
          name
          label
          valueEditorType
          operators {
            name
            label
          }
          selectOptions {
            label
            value
          }
          defaultValue
        }
        aggregateFields {
          aggType
          possibleFields {
            label
            value
          }
        }
        allowedTimeFrames
        name
        description
      }
    }
  }
`);

const rulesRuleMetaQuery = () => ({
  queryKey: rulesKeys.rulesMeta(),
  queryFn: () => gqlClient.request(rulesRuleMeta),
});

export function getRulesRuleMeta() {
  return queryClient.fetchQuery(rulesRuleMetaQuery());
}

export function useRulesRuleMeta() {
  return useQuery({
    ...rulesRuleMetaQuery(),
    select: ({ rules_ruleTypesMetadata }) => rules_ruleTypesMetadata,
  });
}

export type ReturnTypeFromUseRulesRuleMeta = UseQueryReturnType<
  typeof useRulesRuleMeta
>[number];

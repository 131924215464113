import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from '@tyro/i18n';
import {
  DocumentFileIcon,
  FileBlankCheckmarkIcon,
  FileBlankExchangeCrossDelete,
} from '@tyro/icons';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router';

type TemplateEditorActionButtonsProps = {
  maxCardWidth: boolean;
  createdOn?: string | null;
  isSavingTemplate: boolean;
  onPreviewClick: () => void;
};

export const TemplateEditorActionButtons = ({
  maxCardWidth,
  createdOn,
  isSavingTemplate,
  onPreviewClick,
}: TemplateEditorActionButtonsProps) => {
  const { t } = useTranslation(['templates']);
  const navigate = useNavigate();

  return (
    <Stack
      direction={maxCardWidth ? 'row' : 'column'}
      alignItems={maxCardWidth ? 'flex-start' : 'flex-end'}
      justifyContent={maxCardWidth ? 'flex-end' : 'undefined'}
      mt={2}
      ml={2}
      rowGap={1}
      sx={
        !maxCardWidth
          ? {
              position: 'sticky',
              top: '200px',
              height: 'calc(100vh - 160px)',
            }
          : null
      }
    >
      <>
        {createdOn && (
          <Box display="flex" flexDirection="column">
            <Typography component="span" color="text.secondary" fontSize={14}>
              {t('templates:createdOn')}
              <Typography component="span" color="text.primary" fontSize={14}>
                {' '}
                {dayjs(createdOn).format('LL')}
              </Typography>
            </Typography>
          </Box>
        )}

        <Box display="flex" flexDirection="column" ml={2}>
          <Button
            startIcon={<FileBlankExchangeCrossDelete />}
            variant="soft"
            color="primary"
            onClick={() => navigate('/templates/list')}
            sx={{ width: '191px', justifyContent: 'flex-start', mb: 1 }}
          >
            {t('templates:cancelEdits')}
          </Button>
          <Button
            startIcon={<DocumentFileIcon />}
            variant="soft"
            onClick={onPreviewClick}
            sx={{ width: '191px', justifyContent: 'flex-start', mb: 1 }}
          >
            {t('templates:preview')}
          </Button>
          <LoadingButton
            type="submit"
            startIcon={<FileBlankCheckmarkIcon />}
            variant="contained"
            loading={isSavingTemplate}
            sx={{ width: '191px', justifyContent: 'flex-start', mb: 1 }}
          >
            {t('templates:saveTemplate')}
          </LoadingButton>
        </Box>
      </>
    </Stack>
  );
};

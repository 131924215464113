import { CalendarEventAttendeeType } from '@tyro/api';
import { orderByValue } from '@tyro/core';
import type { ReturnTypeFromUseEventForEdit } from '../api/events-for-edit';

export function sortParticipants(
  participants: ReturnTypeFromUseEventForEdit['attendees'],
) {
  return orderByValue(participants || [], (participant) => participant.type, {
    [CalendarEventAttendeeType.Organiser]: 0,
    [CalendarEventAttendeeType.Additional]: 1,
    [CalendarEventAttendeeType.Attendee]: 2,
  });
}

import { FileTransferFeature, useUploadFile } from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';

export function useUploadPhoto() {
  const { t } = useTranslation(['common']);
  const { toast } = useToast();

  return useUploadFile({
    documentType: FileTransferFeature.NoticeboardPhoto,
    additionalFormValues: {
      overwrite: 'false',
    },
    mutationOptions: {
      onError: () => {
        toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
      },
    },
  });
}

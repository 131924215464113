import { useQuery } from '@tanstack/react-query';

import {
  type TtIndividualViewLessonFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { timetableKeys } from '../../keys';

const individualLessons = graphql(/* GraphQL */ `
  query tt_individualLessons($filter: TTIndividualViewLessonFilter!) {
    tt_individualLessons(filter: $filter) {
      id {
        lessonIdx
        lessonInstanceIdx
        timetableGroupId
      }
      spread
      room {
        roomId
        name
      }
      roomPool {
        id
        name
      }
      timeslotInfo {
        dayOfWeek
        startTime
        endTime
        periodType
        name
      }
      timeslotId {
        gridIdx
        dayIdx
        periodIdx
      }
      timetableGroup {
        name
        partyId
        subject {
          id
          name
          colour
        }
        groupTagIds
      }
      teachers {
        person {
          partyId
          title {
            id
            name
            nameTextId
          }
          firstName
          lastName
          avatarUrl
          type
        }
      }
    }
  }
`);

const individualLessonsQuery = (filter: TtIndividualViewLessonFilter) => ({
  queryKey: timetableKeys.individualLessons(filter),
  queryFn: async () => gqlClient.request(individualLessons, { filter }),
});

export async function getIndividualLessons(
  filter: TtIndividualViewLessonFilter,
) {
  return queryClient.fetchQuery(individualLessonsQuery(filter));
}

export function useIndividualLessons(
  filter: TtIndividualViewLessonFilter,
  enabled = true,
) {
  return useQuery({
    ...individualLessonsQuery(filter),
    select: ({ tt_individualLessons }) => tt_individualLessons,
    enabled,
  });
}

export type ReturnTypeFromUseIndividualLessons = UseQueryReturnType<
  typeof useIndividualLessons
>[number];

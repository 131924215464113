import type {
  Templating_DemoLabelTemplateInput,
  Templating_LabelTemplateFilter,
  Templating_ResolveDataTemplateInput,
  Templating_ResolveLabelTemplateInput,
  Templating_SavedVariableTemplateFilter,
} from '@tyro/api';

export const printingKeys = {
  all: ['printing'] as const,
  labels: {
    all: () => [...printingKeys.all, 'labels'] as const,
    templates: (filter: Templating_LabelTemplateFilter) =>
      [...printingKeys.labels.all(), 'templates', filter] as const,
    demoTemplate: (filter: Templating_DemoLabelTemplateInput) =>
      [...printingKeys.labels.all(), 'demoTemplate', filter] as const,
    resolve: (filter: Templating_ResolveLabelTemplateInput) =>
      [...printingKeys.labels.all(), 'resolve', filter] as const,
    resolveDataTemplate: (filter: Templating_ResolveDataTemplateInput) =>
      [...printingKeys.labels.all(), 'resolveDataTemplate', filter] as const,
    variableTemplates: (filter: Templating_SavedVariableTemplateFilter) =>
      [...printingKeys.labels.all(), 'variableTemplates', filter] as const,
  },
};

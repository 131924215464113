import {
  ExtraFieldType,
  type SaveAssessmentResultInput,
  type StudentAssessmentExclusionInput,
  type UsePermissionsReturn,
  getPersonProfileLink,
  usePermissions,
} from '@tyro/api';
import {
  type BulkEditedRows,
  type GridOptions,
  type ICellRendererParams,
  ListNavigatorType,
  type PartyListNavigatorMenuItemParams,
  type ReturnTypeDisplayName,
  StudyLevelSelectCellEditor,
  Table,
  TableBooleanValue,
  TableStudyLevelChip,
  TableSwitch,
  type ValueFormatterParams,
  type ValueSetterParams,
  useListNavigatorSettings,
  usePreferredNameLayout,
} from '@tyro/core';
import { type TFunction, useFormatNumber, useTranslation } from '@tyro/i18n';
import { StudentTableAvatar } from '@tyro/people';
import set from 'lodash/set';
import { useCallback, useMemo, useRef } from 'react';
import {
  type ReturnTypeFromUseAssessmentResults,
  useAssessmentResults,
  useUpdateAssessmentResult,
} from '../../api/assessment-results';
import {
  type ReturnTypeFromUseAssessmentById,
  useAssessmentById,
} from '../../api/assessments';
import { useCommentBanksWithComments } from '../../api/comment-bank';
import { updateStudentAssessmentExclusion } from '../../api/student-assessment-exclusion';
import type { ReturnTypeFromUseCommentBanksWithComments } from '../../pages/term-assessment/subject-group/edit-results';
import { checkAndSetGrades } from '../../utils/check-and-set-grades';
import { getExtraFields } from '../../utils/get-extra-fields';
type EditResultsProps = {
  academicNamespaceId?: number;
  assessmentId?: number;
  subjectGroupId?: number;
  subjectGroupName?: string;
  tableId: string;
};

const editAssessmentPermission = 'ps:1:assessment:write_assessment_result';

const getColumnDefs = (
  t: TFunction<
    ('common' | 'assessments')[],
    undefined,
    ('common' | 'assessments')[]
  >,
  permissions: UsePermissionsReturn,
  onBeforeNavigateProfile: () => void,
  displayName: ReturnTypeDisplayName,
  assessmentData: ReturnTypeFromUseAssessmentById | null | undefined,
  commentBanks: ReturnTypeFromUseCommentBanksWithComments | undefined,
  academicNamespaceId: number,
  formatPercent: (value: number) => string,
): GridOptions<ReturnTypeFromUseAssessmentResults>['columnDefs'] => [
  {
    field: 'student',
    headerName: t('common:name'),
    valueGetter: ({ data }) => displayName(data?.student?.person),
    cellRenderer: ({
      data,
    }: ICellRendererParams<ReturnTypeFromUseAssessmentResults>) =>
      data?.student ? (
        <StudentTableAvatar
          person={data?.student?.person}
          isPriorityStudent={!!data?.student?.extensions?.priority}
          hasSupportPlan={!!data?.student?.extensions?.aen}
          to={getPersonProfileLink(data?.student?.person)}
          onBeforeNavigate={onBeforeNavigateProfile}
        />
      ) : null,
    cellClass: 'cell-value-visible',
    sort: 'asc',
    pinned: 'left',
    lockVisible: true,
  },
  {
    field: 'examinable',
    headerName: t('assessments:examinable'),
    editable: permissions.hasPermission(editAssessmentPermission),
    cellClass: ['ag-editable-cell', 'disable-cell-edit-style'],
    cellEditor: TableSwitch,
    cellRenderer: ({
      data,
    }: ICellRendererParams<ReturnTypeFromUseAssessmentResults>) => (
      <TableBooleanValue value={!!data?.examinable} />
    ),
    valueSetter: (
      params: ValueSetterParams<ReturnTypeFromUseAssessmentResults, boolean>,
    ) => {
      const { newValue } = params;
      if (!newValue) {
        params?.node?.setDataValue('result', null);
      }
      set(params.data ?? {}, 'examinable', params.newValue);
      return true;
    },
  },
  {
    field: 'studentClassGroup',
    headerName: t('common:class'),
  },
  {
    field: 'studentStudyLevel',
    headerName: t('common:level'),
    editable: permissions.hasPermission(editAssessmentPermission),
    valueSetter: (
      params: ValueSetterParams<ReturnTypeFromUseAssessmentResults>,
    ) => {
      set(params.data ?? {}, 'studentStudyLevel', params.newValue);
      if (!params.isEditCheckCall) {
        checkAndSetGrades(academicNamespaceId, params);
      }
      return true;
    },
    cellRenderer: ({
      data,
    }: ICellRendererParams<ReturnTypeFromUseAssessmentResults>) =>
      data?.studentStudyLevel ? (
        <TableStudyLevelChip level={data.studentStudyLevel} />
      ) : null,
    cellEditorSelector: StudyLevelSelectCellEditor(t),
  },
  {
    field: 'result',
    headerName: t('common:result'),
    editable: ({ data }) =>
      !!data?.examinable && permissions.hasPermission(editAssessmentPermission),
    valueFormatter: ({ value }) =>
      typeof value === 'number' ? formatPercent(value) : '',
    valueSetter: (
      params: ValueSetterParams<ReturnTypeFromUseAssessmentResults>,
    ) => {
      const value = !params.newValue ? undefined : Number(params.newValue);
      set(
        params.data ?? {},
        'result',
        Number.isNaN(value) || value === undefined
          ? undefined
          : Math.max(0, Math.min(100, value)),
      );

      if (!params.isEditCheckCall) {
        checkAndSetGrades(academicNamespaceId, params);
      }
      return true;
    },
  },
  {
    field: 'gradeResult',
    headerName: t('common:grade'),
  },
  ...(assessmentData?.captureTarget
    ? ([
        {
          field: 'targetResult',
          headerName: t('assessments:targetResult'),
          editable: permissions.hasPermission(editAssessmentPermission),
          valueFormatter: ({
            value,
          }: ValueFormatterParams<
            ReturnTypeFromUseAssessmentResults,
            number
          >) => (typeof value === 'number' ? formatPercent(value) : ''),
          valueSetter: (
            params: ValueSetterParams<ReturnTypeFromUseAssessmentResults>,
          ) => {
            const value = !params.newValue
              ? undefined
              : Number(params.newValue);
            set(
              params.data ?? {},
              'targetResult',
              Number.isNaN(value) || value === undefined
                ? undefined
                : Math.max(0, Math.min(100, value)),
            );
            if (!params.isEditCheckCall) {
              checkAndSetGrades(academicNamespaceId, params);
            }
            return true;
          },
        },
        {
          field: 'targetGradeResult',
          headerName: t('assessments:targetGrade'),
        },
      ] as const)
    : []),
  ...getExtraFields(assessmentData?.extraFields, permissions, commentBanks),
];

export const InClassResultsTable = ({
  academicNamespaceId,
  assessmentId,
  subjectGroupId,
  subjectGroupName,
  tableId,
}: EditResultsProps) => {
  const { t } = useTranslation(['assessments', 'common']);
  const permissions = usePermissions();
  const { displayName } = usePreferredNameLayout();
  const { formatPercent } = useFormatNumber();
  const assessmentResultsFilter = {
    assessmentId: assessmentId ?? 0,
    subjectGroupIds: [subjectGroupId ?? 0],
  };
  const visibleDataRef =
    useRef<() => ReturnTypeFromUseAssessmentResults[]>(null);

  const { data: studentResults } = useAssessmentResults(
    academicNamespaceId ?? 0,
    assessmentResultsFilter,
  );
  const { data: assessmentData, isLoading: isLoadingAssessmentData } =
    useAssessmentById({
      academicNameSpaceId: academicNamespaceId ?? 0,
      ids: [assessmentId ?? 0],
    });

  const commentBanksRequired = useMemo(() => {
    if (!assessmentData) return [];

    const collectedCommentBanks =
      assessmentData?.extraFields?.reduce<number[]>((acc, extraField) => {
        if (
          extraField?.extraFieldType === ExtraFieldType.CommentBank &&
          extraField?.commentBankId
        ) {
          acc.push(extraField.commentBankId);
        }

        return acc;
      }, []) ?? [];

    if (assessmentData.commentBank?.commentBankId) {
      collectedCommentBanks.push(assessmentData.commentBank.commentBankId);
    }

    return collectedCommentBanks;
  }, [assessmentData]);

  const { data: commentBanks } = useCommentBanksWithComments({
    ids: commentBanksRequired,
  });

  const { mutateAsync: updateAssessmentResult } = useUpdateAssessmentResult(
    academicNamespaceId ?? 0,
    assessmentResultsFilter,
  );

  const { storeList } =
    useListNavigatorSettings<PartyListNavigatorMenuItemParams>({
      type: ListNavigatorType.Student,
    });

  const onBeforeNavigateProfile = useCallback(() => {
    storeList(
      subjectGroupName,
      visibleDataRef
        .current?.()
        .map(({ student: { person }, studentPartyId, studentClassGroup }) => ({
          id: studentPartyId,
          type: 'person',
          name: displayName(person),
          firstName: person.firstName,
          lastName: person.lastName,
          avatarUrl: person.avatarUrl,
          caption: studentClassGroup,
        })),
    );
  }, [subjectGroupName]);

  const columnDefs = useMemo(
    () =>
      getColumnDefs(
        t,
        permissions,
        onBeforeNavigateProfile,
        displayName,
        assessmentData,
        commentBanks,
        academicNamespaceId ?? 0,
        (value: number) => formatPercent(value),
      ),
    [
      t,
      permissions,
      onBeforeNavigateProfile,
      displayName,
      assessmentData,
      commentBanks,
      academicNamespaceId,
      formatPercent,
    ],
  );

  const saveAssessmentResult = async (
    data: BulkEditedRows<
      ReturnTypeFromUseAssessmentResults,
      | 'examinable'
      | 'extraFields'
      | 'result'
      | 'gradeResult'
      | 'targetResult'
      | 'targetGradeResult'
      | 'teacherComment.comment'
      | 'subjectGroup.irePP.examinable'
    >,
  ) => {
    const examinableChanges = Object.entries(data).reduce<
      StudentAssessmentExclusionInput[]
    >((acc, [key, value]) => {
      if (value.examinable) {
        acc.push({
          assessmentId: assessmentId ?? 0,
          studentPartyId: Number(key),
          subjectGroupId: subjectGroupId ?? 0,
          excluded: !value.examinable?.newValue,
        });
      }
      return acc;
    }, []);

    if (examinableChanges.length > 0) {
      await updateStudentAssessmentExclusion(
        academicNamespaceId ?? 0,
        examinableChanges,
      );
    }

    const results = studentResults?.reduce<SaveAssessmentResultInput[]>(
      (acc, result) => {
        const editedColumns = data[result.studentPartyId];
        if (editedColumns) {
          const newResult = {
            ...result,
            subjectGroupId: subjectGroupId ?? 0,
            assessmentId: assessmentData?.id ?? 0,
          };

          for (const [key, { newValue }] of Object.entries(editedColumns)) {
            if (key.startsWith('extraFields')) {
              const splitKey = key.split('.');
              const extraFieldId = Number(splitKey[1]);
              const extraFieldProperty = splitKey[2] as
                | 'commentBankCommentId'
                | 'result';

              if (newResult.extraFields?.[extraFieldId]) {
                set(
                  newResult.extraFields[extraFieldId],
                  extraFieldProperty,
                  newValue ?? null,
                );
              } else {
                set(newResult.extraFields, extraFieldId, {
                  assessmentExtraFieldId: extraFieldId,
                  ...(extraFieldProperty === 'commentBankCommentId'
                    ? {
                        commentBankCommentId: newValue ?? null,
                      }
                    : { result: newValue ?? null }),
                });
              }
            } else {
              set(newResult, key, newValue ?? null);
            }
          }

          acc.push({
            ...newResult,
            extraFields: Object.values(newResult.extraFields).map(
              (value) => value,
            ),
          });
        }
        return acc;
      },
      [],
    );

    if (!results) return Promise.reject();

    return updateAssessmentResult(results);
  };

  return (
    <Table
      key={academicNamespaceId}
      visibleDataRef={visibleDataRef}
      rowData={studentResults ?? []}
      autoSave
      isLoading={isLoadingAssessmentData}
      columnDefs={columnDefs}
      getRowId={({ data }) => String(data?.studentPartyId)}
      onBulkSave={saveAssessmentResult}
      statusBar={{
        statusPanels: [
          {
            statusPanel: 'tyroAggStatusPanel',
            statusPanelParams: {
              columns: [
                {
                  id: 'result',
                  aggregationTypes: ['avg'],
                },
                ...(assessmentData?.captureTarget
                  ? [
                      {
                        id: 'targetResult',
                        aggregationTypes: ['avg'],
                        onSelectOnly: true,
                      },
                    ]
                  : []),
              ],
            },
          },
        ],
      }}
      tableId={tableId}
    />
  );
};

import { useQuery } from '@tanstack/react-query';
import {
  type PaymentFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { feeKeys } from './keys';

const payments = graphql(/* GraphQL */ `
  query fees_payments($filter: PaymentFilter) {
    fees_payments(filter: $filter) {
      id
      fee {
        id
        name
      }
      studentPartyId
      student {
        person {
          partyId
          title {
            id
            name
            nameTextId
          }
          firstName
          lastName
          avatarUrl
          type
        }
      }
      payee {
        partyId
        title {
          id
          name
          nameTextId
        }
        firstName
        lastName
        avatarUrl
        type
      }
      amount
      paymentIntentId
      paymentMethod
      transactionDate
      serviceFeesAmount
      note
      cardType
      refunds {
        id
        amount
        refundType
        refundStatus
        refundDate
      }
    }
  }
`);

const paymentsQuery = (filter: PaymentFilter) => ({
  queryKey: feeKeys.payments(filter),
  queryFn: () => gqlClient.request(payments, { filter }),
});

export function getPayments(filter: PaymentFilter) {
  return queryClient.fetchQuery(paymentsQuery(filter));
}

export function usePayments(filter: PaymentFilter) {
  return useQuery({
    ...paymentsQuery(filter),
    select: ({ fees_payments }) =>
      fees_payments.map((payment) => ({
        ...payment,
        amountRefunded: payment.refunds.reduce(
          (amount, refund) => amount + refund.amount,
          0,
        ),
      })),
  });
}

export type ReturnTypeFromUsePayments = UseQueryReturnType<
  typeof usePayments
>[number];

import { Box, Link, ListItemText, Tooltip } from '@mui/material';
import { ChevronDownIcon, ChevronRightIcon } from '@tyro/icons';
import { type LinkProps, Link as RouterLink } from 'react-router-dom';
import type { NavItemProps } from '../types';
import {
  StyledDotIcon,
  StyledIcon,
  StyledItem,
  type StyledItemProps,
} from './styles';

export default function NavItem({
  item,
  depth,
  open,
  active,
  isExternalLink,
  ...other
}: NavItemProps) {
  const { title, path, info, icon, children } = item;

  const subItem = !icon;

  let additionalItemProps:
    | StyledItemProps
    | Partial<Pick<LinkProps, 'to' | 'target' | 'rel'>> = {};

  if (isExternalLink) {
    additionalItemProps = {
      component: Link,
      to: path ?? '',
      target: '_blank',
      rel: 'noopener" underline="none',
    };
  } else if (!children) {
    additionalItemProps = {
      component: RouterLink,
      to: path ?? '',
    };
  }

  return (
    <StyledItem
      {...additionalItemProps}
      depth={depth}
      active={active}
      {...other}
    >
      {icon && <StyledIcon>{icon}</StyledIcon>}

      {subItem && (
        <StyledIcon>
          <StyledDotIcon active={active && subItem} />
        </StyledIcon>
      )}

      <ListItemText
        primary={title}
        // secondary={
        //   caption && (
        //     <Tooltip title={translate(caption)} placement="top-start">
        //       <span>{translate(caption)}</span>
        //     </Tooltip>
        //   )
        // }
        primaryTypographyProps={{
          noWrap: true,
          component: 'span',
          variant: active ? 'subtitle2' : 'body2',
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'caption',
        }}
      />

      {info && <Box>{info}</Box>}

      {!!children &&
        (open ? (
          <ChevronDownIcon sx={{ ml: 1, flexShrink: 0 }} />
        ) : (
          <ChevronRightIcon sx={{ ml: 1, flexShrink: 0 }} />
        ))}
    </StyledItem>
  );
}

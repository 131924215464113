import { useQuery } from '@tanstack/react-query';
import {
  type StudentAenFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { peopleKeys } from '../../keys';

const studentAenData = graphql(/* GraphQL */ `
  query wellbeing_studentAen($filter: StudentAenFilter!) {
    wellbeing_studentAen(filter: $filter) {
      studentPartyId
      entries {
        id
        studentPartyId
        startDate
        endDate
        type
        typeNote
        contact
        snaSupport
        provision
        note
      }
    }
  }
`);

const studentAenDataQuery = (filter: StudentAenFilter) => ({
  queryKey: peopleKeys.students.aen(filter),
  queryFn: async () =>
    gqlClient.request(studentAenData, {
      filter,
    }),
});

export function getStudentAenData(filter: StudentAenFilter) {
  return queryClient.fetchQuery(studentAenDataQuery(filter));
}

export function useStudentAenData(filter: StudentAenFilter, enabled = true) {
  return useQuery({
    ...studentAenDataQuery(filter),
    enabled,
    select: ({ wellbeing_studentAen }) => wellbeing_studentAen,
  });
}

export type ReturnTypeFromUseStudentAen = UseQueryReturnType<
  typeof useStudentAenData
>;

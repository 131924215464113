import { useQuery } from '@tanstack/react-query';
import {
  type RoomFilter,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { naturalSort } from '@tyro/core';
import { roomKeys } from './keys';

const coreRooms = graphql(/* GraphQL */ `
  query core_rooms($filter: RoomFilter) {
    core_rooms(filter: $filter) {
      roomId
      name
      capacity
      description
      pools {
        id
        name
        description
        colour
      }
      includeInTimetable
      externalSystemId
      location
      disabled
    }
  }
`);

const coreRoomsQuery = (filter?: RoomFilter) => ({
  queryKey: roomKeys.coreRooms(),
  queryFn: async () => {
    const { core_rooms: rooms } = await gqlClient.request(coreRooms, {
      filter,
    });
    return {
      core_rooms: rooms?.sort((a, b) => naturalSort(a.name, b.name)),
    };
  },
});

export function getCoreRooms(filter?: RoomFilter) {
  return queryClient.fetchQuery(coreRoomsQuery(filter));
}

export function useCoreRooms(filter?: RoomFilter) {
  return useQuery({
    ...coreRoomsQuery(filter),
    select: ({ core_rooms }) => core_rooms,
  });
}

export type ReturnTypeFromUseCoreRooms = UseQueryReturnType<
  typeof useCoreRooms
>[number];

import { Box, Stack, Typography } from '@mui/material';
import { formatPhoneNumber, usePreferredNameLayout } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { UserDetailsCardIcon } from '@tyro/icons';
import { useMemo } from 'react';
import { useStudentDetailsForNTK } from '../../../api/student/students';
import {
  getContactRelationship,
  getPrimaryContact,
} from '../../../utils/get-primary-contact';
import { joinAddress } from '../../../utils/join-address';

interface PersonalInfoCardProps {
  studentPartyId: number;
  open: boolean;
}

export function PersonalInfoCard({
  studentPartyId,
  open,
}: PersonalInfoCardProps) {
  const { t } = useTranslation(['common', 'people']);
  const { displayName, displayNames } = usePreferredNameLayout();

  const { data: studentData } = useStudentDetailsForNTK(studentPartyId, open);

  const schoolDetails = useMemo(() => {
    const years = studentData?.yearGroups ?? [];
    const classGroup = studentData?.classGroup;
    const tutors = studentData?.tutors ?? [];
    const yearGroupLeads = studentData?.yearGroupLeads ?? [];

    return {
      [t('people:year')]: years?.map((a) => a.shortName).join(', ') || '-',
      [t('people:class')]: classGroup?.name || '-',
      [t('people:yearHead')]: displayNames(yearGroupLeads) || '-',
      [t('people:tutor')]: displayNames(tutors) || '-',
    };
  }, [studentData]);

  const primaryContact = useMemo(
    () => getPrimaryContact(studentPartyId, studentData?.contacts),
    [studentPartyId, studentData?.contacts],
  );

  const primaryContactRelationship = getContactRelationship(
    primaryContact?.relationships,
    studentPartyId,
  );

  return (
    <Stack
      sx={{
        backgroundColor: 'white',
        borderRadius: 4,
        p: 2,
        pt: 1.5,
        mt: 1.5,
        border: '2px solid',
        borderColor: 'slate.200',
      }}
      spacing={2}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <UserDetailsCardIcon sx={{ color: 'slate.500' }} />
        <Typography variant="h6" component="h3" color="text.secondary">
          {t('people:personalDetails')}
        </Typography>
      </Stack>

      <Stack spacing={0.5}>
        <Typography variant="subtitle1" component="h4" fontWeight="bold">
          {t('people:schoolDetails')}
        </Typography>
        <Stack
          component="dl"
          sx={{
            m: 0,
            '& dt': {
              fontWeight: 'medium',
            },
          }}
        >
          {Object.entries(schoolDetails).map(([key, value]) => (
            <Stack key={key} direction="row" spacing={1}>
              <Box component="dt">{key}:</Box>
              <Box component="dd">{value}</Box>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Stack spacing={0.5}>
        <Typography variant="subtitle1" component="h4" fontWeight="bold">
          {t('people:homeDetails')}
        </Typography>
        <Stack direction="row" spacing={4}>
          <Stack spacing={0.5}>
            <Typography
              variant="subtitle1"
              component="h5"
              color="text.secondary"
            >
              {t('people:primaryContact')}
            </Typography>
            <Stack>
              <Typography variant="body1">
                {displayName(primaryContact?.person) || '-'}
              </Typography>
              <Typography variant="body1">
                {primaryContactRelationship?.relationshipType
                  ? t(
                      `common:relationshipType.${primaryContactRelationship.relationshipType}`,
                    )
                  : '-'}
              </Typography>
              {primaryContact?.personalInformation?.primaryPhoneNumber ? (
                <Typography
                  variant="body1"
                  component="a"
                  href={`tel:${formatPhoneNumber(
                    primaryContact.personalInformation.primaryPhoneNumber,
                  )}`}
                  color="primary.main"
                  fontWeight="medium"
                  sx={{
                    textDecoration: 'none',
                  }}
                >
                  {formatPhoneNumber(
                    primaryContact.personalInformation.primaryPhoneNumber,
                  )}
                </Typography>
              ) : (
                <Typography variant="body1">-</Typography>
              )}
            </Stack>
          </Stack>
          <Stack spacing={0.5}>
            <Typography
              variant="subtitle1"
              component="h5"
              color="text.secondary"
            >
              {t('people:homeAddress')}
            </Typography>
            <Stack>
              <Typography variant="body1">
                {joinAddress(studentData?.personalInformation?.primaryAddress, {
                  separator: (
                    <span>
                      ,<br />
                    </span>
                  ),
                })}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

import { useTranslation } from '@tyro/i18n';
import { type ReactElement, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  type SvgIconProps,
  Typography,
} from '@mui/material';

import { Tooltip } from '@mui/material';
import {
  FormAdmin_InformationRequestConfigMetaType,
  FormFd_SystemTag,
  FormType,
  Forms_LinkEntityType,
  type Forms_LinkInput,
  RequestStatus,
  getColorBasedOnIndex,
  usePermissions,
} from '@tyro/api';
import {
  ActionMenu,
  PlaceholderCard,
  SearchInput,
  useDebouncedValue,
  usePreferredNameLayout,
} from '@tyro/core';
import {
  AddIcon,
  CheckmarkCircleIcon,
  CloseCircleIcon,
  DocListIcon,
  EditPenCheckmark,
  EyeIcon,
  EyeSlashIcon,
  NoteWithClipIcon,
  ThumbsDownIcon,
  ThumbsUpIcon,
  TimerIcon,
  TrashIcon,
} from '@tyro/icons';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSearchFormsSubmissions } from '../api/search-forms-submissions';
import {
  DeleteFormSubmissionConfirmModal,
  type DeleteFormSubmissionConfirmModalProps,
} from './delete-form-submission-confirm-modal';
import { AddFormModal } from './requests/add-form-modal';
import {
  UpsertOnCallFormModal,
  type UpsertOnCallFormModalProps,
} from './requests/upsert-on-call-form-modal';

const IconByStatus: Record<RequestStatus, ReactElement<SvgIconProps>> = {
  [RequestStatus.Complete]: <ThumbsUpIcon color="success" />,
  [RequestStatus.PartiallyComplete]: <ThumbsDownIcon color="error" />,
  [RequestStatus.Incomplete]: <ThumbsDownIcon color="error" />,
};

type ListFormsProps = {
  link: Forms_LinkInput;
};

export function ListForms({ link }: ListFormsProps) {
  const { t } = useTranslation(['common', 'forms']);
  const { displayName } = usePreferredNameLayout();
  const navigate = useNavigate();
  const location = useLocation();
  const { isStaffUserWithPermission } = usePermissions();

  const [searchForm, setSearchForm] = useState('');
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);

  const {
    value: formToDelete,
    debouncedValue: debouncedFormToDelete,
    setValue: setFormToDelete,
  } = useDebouncedValue<DeleteFormSubmissionConfirmModalProps['formToDelete']>({
    defaultValue: null,
  });

  const {
    value: onCallRequest,
    debouncedValue: debounceOnCallRequest,
    setValue: setOnCallRequest,
  } = useDebouncedValue<UpsertOnCallFormModalProps['initialState']>({
    defaultValue: null,
  });

  const { data: formsListData = [] } = useSearchFormsSubmissions({
    links: [link],
  });

  const filteredList = useMemo(() => {
    const lowerCaseSearch = searchForm.toLowerCase();
    return formsListData.filter((form) =>
      form.title.toLowerCase().includes(lowerCaseSearch),
    );
  }, [searchForm, formsListData]);

  return (
    <Stack gap={2}>
      <Stack direction="row" justifyContent="space-between">
        <SearchInput
          value={searchForm}
          onChange={(e) => setSearchForm(e.target.value)}
          size="small"
        />
        {isStaffUserWithPermission('ps:1:forms:forms_users') && (
          <ActionMenu
            menuItems={[
              {
                label: t('forms:addForm'),
                icon: <AddIcon />,
                onClick: () => setIsAddFormOpen(true),
              },
              ...(link.type === Forms_LinkEntityType.SchoolActivity
                ? [
                    {
                      label: t('forms:addPermissionSlip'),
                      icon: <DocListIcon />,
                      onClick: () =>
                        navigate(
                          `/forms/requests/create/permission-slip/${link.id}`,
                          {
                            state: {
                              from: location.pathname,
                            },
                          },
                        ),
                    },
                  ]
                : []),
              ...(link.type === Forms_LinkEntityType.Timetable
                ? [
                    {
                      label: t('forms:addOnCallForm'),
                      icon: <DocListIcon />,
                      onClick: () => setOnCallRequest({}),
                    },
                  ]
                : []),
            ]}
          />
        )}
      </Stack>

      {formsListData.length === 0 && (
        <PlaceholderCard>
          <Typography variant="body1">{t('forms:noFormsToShow')}</Typography>
        </PlaceholderCard>
      )}

      {formsListData.length > 0 &&
        filteredList.length === 0 &&
        searchForm.length > 0 && (
          <PlaceholderCard>
            <Typography variant="body1" color="primary">
              {t('forms:noFormsFound')}
            </Typography>
          </PlaceholderCard>
        )}

      {filteredList.length > 0 && (
        <Grid container spacing={2}>
          {filteredList.map((form) => {
            const {
              id,
              title,
              description,
              tags,
              singleSubmissionInfo,
              infoRequestInfo,
              type,
              updatedBy,
              updatedOn,
            } = form;

            const queryParams = [
              id.objectId ? `id=${id.objectId}` : false,
              id.forPartyId ? `forPartyId=${id.forPartyId}` : false,
              id.provider ? `provider=${id.provider}` : false,
            ]
              .filter(Boolean)
              .join('&');

            const isCompleted =
              singleSubmissionInfo?.status === RequestStatus.Complete;

            const isPermissionSlip = tags?.some(
              (tag) => tag.systemTag === FormFd_SystemTag.SaPermissionSlip,
            );

            const {
              responseCompleteCount = 0,
              responseExpected = 0,
              responseConfirmationCount = 0,
              responseRejectedCount = 0,
              dueDate,
              metaConfig,
              metaConfigType,
              id: infoId,
              isConfirmable,
              currentlyVisibleForResponders,
            } = infoRequestInfo || {};

            const {
              publishDate: metaPublishDate,
              dueDate: metaDueDate,
              excludeTeachers,
              ...restMetaConfig
            } = metaConfig || {};

            const isOnCallForm =
              metaConfigType ===
              FormAdmin_InformationRequestConfigMetaType.TtOnCallRequest;

            return (
              <Grid key={queryParams} item xs={6} md={4}>
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <CardHeader
                    component="h3"
                    avatar={<NoteWithClipIcon color="primary" />}
                    sx={{
                      p: 2,
                      pb: 0,
                      border: 0,
                      '.MuiCardHeader-avatar': {
                        marginRight: 1,
                      },
                    }}
                    titleTypographyProps={{
                      variant: 'subtitle1',
                    }}
                    title={title}
                    action={
                      <IconButton
                        color="primary"
                        aria-label={t('common:delete')}
                        onClick={() => setFormToDelete(form)}
                      >
                        <TrashIcon />
                      </IconButton>
                    }
                  />
                  <CardContent sx={{ p: 2, height: '100%' }}>
                    <Stack gap={2} alignItems="start">
                      {description && (
                        <Box
                          bgcolor="slate.50"
                          border="1px solid"
                          borderColor="divider"
                          borderRadius={1.25}
                          p={1}
                          width="100%"
                        >
                          <Typography variant="body2">{description}</Typography>
                        </Box>
                      )}
                      {(tags || []).map((tag) => (
                        <Chip
                          key={tag.id}
                          size="small"
                          variant="soft"
                          color={getColorBasedOnIndex(tag.id)}
                          label={tag.name}
                        />
                      ))}
                    </Stack>
                  </CardContent>
                  <CardActions sx={{ p: 0 }}>
                    <Stack width="100%">
                      {type === FormType.InfoRequest && (
                        <>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                            p={2}
                            gap={2}
                          >
                            <Stack
                              flexDirection="row"
                              alignItems="center"
                              gap={0.5}
                            >
                              <Typography variant="caption" fontWeight={600}>
                                {responseCompleteCount}
                                {'/'}
                                {responseExpected}
                              </Typography>
                              <Typography
                                variant="caption"
                                fontWeight={600}
                                color="text.secondary"
                                textTransform="lowercase"
                              >
                                {t('common:completed')}
                              </Typography>
                            </Stack>

                            <LinearProgress
                              variant="determinate"
                              value={
                                (responseCompleteCount / responseExpected) * 100
                              }
                              color="primary"
                              sx={{
                                height: 8,
                                width: '50%',
                                borderRadius: 4,
                              }}
                            />
                          </Stack>
                          {isConfirmable && (
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center"
                              width="100%"
                              p={2}
                              pt={0}
                              gap={2}
                            >
                              <Stack
                                flexDirection="row"
                                alignItems="center"
                                gap={0.5}
                              >
                                <CheckmarkCircleIcon color="success" />
                                <Typography
                                  variant="caption"
                                  fontWeight={600}
                                  color="text.secondary"
                                >
                                  {t('common:confirmed')}
                                </Typography>
                                <Typography variant="caption" fontWeight={600}>
                                  {responseConfirmationCount}
                                </Typography>
                              </Stack>

                              <Stack
                                flexDirection="row"
                                alignItems="center"
                                gap={0.5}
                              >
                                <CloseCircleIcon color="error" />
                                <Typography
                                  variant="caption"
                                  fontWeight={600}
                                  color="text.secondary"
                                >
                                  {t('common:rejected')}
                                </Typography>
                                <Typography variant="caption" fontWeight={600}>
                                  {responseRejectedCount}
                                </Typography>
                              </Stack>
                            </Stack>
                          )}
                        </>
                      )}
                      {type === FormType.SingleSubmission && (
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                          p={2}
                          gap={2}
                        >
                          {singleSubmissionInfo?.status && (
                            <Stack
                              direction="row"
                              gap={0.5}
                              alignItems="center"
                            >
                              {IconByStatus[singleSubmissionInfo.status]}
                              <Typography
                                variant="caption"
                                fontWeight={600}
                                color={isCompleted ? 'green.500' : 'rose.500'}
                              >
                                {t(
                                  `forms:formStatus.${singleSubmissionInfo.status}`,
                                )}
                              </Typography>
                            </Stack>
                          )}
                          <Stack direction="row" gap={1}>
                            <Typography variant="caption" fontWeight={600}>
                              {displayName(updatedBy.person)}
                            </Typography>
                            <Typography variant="caption">
                              {dayjs(updatedOn).format('L')}
                            </Typography>
                          </Stack>
                        </Stack>
                      )}
                      <Stack
                        bgcolor="blue.50"
                        p={2}
                        justifyContent={
                          type === FormType.InfoRequest
                            ? 'space-between'
                            : 'flex-end'
                        }
                        width="100%"
                        flexDirection="row"
                        ml={0}
                        gap={2}
                        flexWrap="wrap"
                      >
                        {type === FormType.InfoRequest && dueDate && (
                          <Stack>
                            <Stack
                              flexDirection="row"
                              alignItems="center"
                              gap={0.5}
                            >
                              <TimerIcon color="error" />
                              <Typography
                                variant="caption"
                                fontWeight={600}
                                color="text.secondary"
                              >
                                {t('common:due')}
                              </Typography>
                              <Typography variant="caption" fontWeight={600}>
                                {dayjs(dueDate).format('L')}
                              </Typography>
                            </Stack>
                            {typeof currentlyVisibleForResponders ===
                              'boolean' && (
                              <Tooltip
                                title={
                                  currentlyVisibleForResponders
                                    ? t('forms:visibleDetails')
                                    : t('forms:notVisibleDetails')
                                }
                                describeChild
                                placement="top-start"
                              >
                                <Stack
                                  flexDirection="row"
                                  alignItems="center"
                                  gap={0.5}
                                >
                                  {currentlyVisibleForResponders ? (
                                    <EyeIcon color="success" />
                                  ) : (
                                    <EyeSlashIcon color="disabled" />
                                  )}
                                  <Typography
                                    variant="caption"
                                    fontWeight={600}
                                  >
                                    {currentlyVisibleForResponders
                                      ? t('forms:visible')
                                      : t('forms:notVisible')}
                                  </Typography>
                                </Stack>
                              </Tooltip>
                            )}
                          </Stack>
                        )}
                        <Stack
                          flexDirection="row"
                          gap={2}
                          width="100%"
                          justifyContent="end"
                        >
                          <Button
                            color="primary"
                            variant="soft"
                            startIcon={<EyeIcon />}
                            onClick={() =>
                              navigate(
                                isPermissionSlip || isOnCallForm
                                  ? `/forms/requests/view?${queryParams}`
                                  : `/forms/single-submission/view?${queryParams}`,
                                {
                                  state: {
                                    from: location.pathname,
                                  },
                                },
                              )
                            }
                          >
                            {t('common:actions.view')}
                          </Button>

                          <Button
                            color="primary"
                            variant="soft"
                            startIcon={<EditPenCheckmark />}
                            onClick={() =>
                              isOnCallForm
                                ? setOnCallRequest({
                                    ...restMetaConfig,
                                    id: {
                                      id: infoId?.id ?? 0,
                                      provider: infoId?.provider ?? '',
                                    },
                                    dueDate: dayjs(metaDueDate),
                                    publishDate: dayjs(metaPublishDate),
                                    excludeTeachersIds: excludeTeachers || [],
                                    isInUse: responseCompleteCount > 0,
                                  })
                                : navigate(
                                    isPermissionSlip
                                      ? `/forms/requests/edit?${queryParams}`
                                      : `/forms/single-submission/edit?${queryParams}&linkId=${link.id}&linkType=${link.type}`,
                                    {
                                      state: {
                                        from: location.pathname,
                                      },
                                    },
                                  )
                            }
                          >
                            {t('common:actions.edit')}
                          </Button>
                        </Stack>
                      </Stack>
                    </Stack>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      )}
      <UpsertOnCallFormModal
        isOpen={!!onCallRequest}
        timetableId={Number(link.id)}
        initialState={onCallRequest || debounceOnCallRequest}
        onClose={() => setOnCallRequest(null)}
      />
      <AddFormModal
        isOpen={isAddFormOpen}
        onClose={() => setIsAddFormOpen(false)}
        onSelect={(form) => {
          const formId = form.id;

          navigate(
            `/forms/single-submission/add?id=${formId.id}&provider=${formId.provider}&linkId=${link.id}&linkType=${link.type}`,
            {
              state: {
                from: location.pathname,
              },
            },
          );
        }}
      />
      <DeleteFormSubmissionConfirmModal
        open={!!formToDelete}
        formToDelete={formToDelete || debouncedFormToDelete}
        onClose={() => setFormToDelete(null)}
      />
    </Stack>
  );
}

import { useMutation } from '@tanstack/react-query';
import {
  type FormsAdmin_UpsertInformationRequest,
  type FormsAdmin_UpsertInformationRequestUsingMetaConfigObj,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { formsKeys } from './keys';

const upsertInformationRequest = graphql(/* GraphQL */ `
  mutation formsAdmin_upsertInformationRequest(
    $input: FormsAdmin_UpsertInformationRequest!
  ) {
    formsAdmin_upsertInformationRequest(input: $input) {
      id {
        id
        provider
      }
    }
  }
`);

const upsertInformationRequestUsingMetaConfig = graphql(/* GraphQL */ `
  mutation formsAdmin_upsertInformationRequestUsingMetaConfig(
    $input: FormsAdmin_UpsertInformationRequestUsingMetaConfigObj!
  ) {
    formsAdmin_upsertInformationRequestUsingMetaConfig(input: $input) {
      id
      provider
    }
  }
`);

export function useUpsertInformationRequest() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationFn: async (input: FormsAdmin_UpsertInformationRequest) =>
      gqlClient.request(upsertInformationRequest, { input }),
    onSuccess: async (_, form) => {
      await queryClient.invalidateQueries({ queryKey: formsKeys.all });

      toast(
        form?.id
          ? t('common:snackbarMessages.updateSuccess')
          : t('common:snackbarMessages.createSuccess'),
      );
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

export function useUpsertInformationRequestUsingMetaConfig() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationFn: async (
      input: FormsAdmin_UpsertInformationRequestUsingMetaConfigObj,
    ) => gqlClient.request(upsertInformationRequestUsingMetaConfig, { input }),
    onSuccess: async (_, form) => {
      await queryClient.invalidateQueries({ queryKey: formsKeys.all });

      toast(
        form.id
          ? t('common:snackbarMessages.updateSuccess')
          : t('common:snackbarMessages.createSuccess'),
      );
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

import {
  type NavObjectFunction,
  NavObjectType,
  getNumber,
  lazyWithRetry,
  throw404Error,
} from '@tyro/core';
import { DocListIcon } from '@tyro/icons';
import { getFormRequests } from './api/form-requests';
import { getFormDefinitions } from './api/forms-definitions';
import { getInfoRequestTableView } from './api/info-request-table-view';
import { getPreviewFormDefinition } from './api/preview-form-definition';
import { getTags } from './api/tags';
import { getViewSubmissions } from './api/view-submissions';

const FormTemplates = lazyWithRetry(() => import('./pages/templates'));
const FormTags = lazyWithRetry(() => import('./pages/tags'));

const CreateFormTemplate = lazyWithRetry(
  () => import('./pages/templates/create'),
);
const EditFormTemplate = lazyWithRetry(() => import('./pages/templates/edit'));
const PreviewFormTemplate = lazyWithRetry(
  () => import('./pages/templates/preview'),
);

const FormRequests = lazyWithRetry(() => import('./pages/requests'));
const FormRequestViewPage = lazyWithRetry(
  () => import('./pages/requests/view'),
);
const CreateFormRequestPage = lazyWithRetry(
  () => import('./pages/requests/create'),
);
const EditFormRequestPage = lazyWithRetry(
  () => import('./pages/requests/edit'),
);

const CreatePermissionSlipPage = lazyWithRetry(
  () => import('./pages/requests/create-permission-slip'),
);

const AddSingleSubmission = lazyWithRetry(
  () => import('./pages/single-submission/add'),
);
const ViewSingleSubmission = lazyWithRetry(
  () => import('./pages/single-submission/view'),
);
const EditSingleSubmission = lazyWithRetry(
  () => import('./pages/single-submission/edit'),
);

export const getRoutes: NavObjectFunction = (t) => [
  {
    type: NavObjectType.Category,
    title: t('navigation:management.title'),
    children: [
      {
        type: NavObjectType.RootGroup,
        path: 'forms',
        title: t('navigation:management.forms.title'),
        icon: <DocListIcon />,
        hasAccess: (permissions) =>
          permissions.isStaffUserWithPermission('ps:1:forms:forms_admin'),
        children: [
          {
            type: NavObjectType.MenuLink,
            path: 'tags',
            title: t('navigation:management.forms.tags'),
            loader: () => getTags({ ids: [] }),
            element: <FormTags />,
          },
          {
            type: NavObjectType.MenuLink,
            path: 'templates',
            title: t('navigation:management.forms.templates'),
            loader: () =>
              getFormDefinitions({
                ids: [],
                isReusableTemplate: true,
              }),
            element: <FormTemplates />,
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'templates/create',
            element: <CreateFormTemplate />,
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'templates/edit/:templateId',
            loader: async ({ params }) => {
              const templateId = getNumber(params?.templateId);
              if (!templateId) throw404Error();

              const { formsAdmin_formDefinitions: formData } =
                await getFormDefinitions({
                  ids: [
                    {
                      id: templateId,
                      provider: 'STANDARD',
                    },
                  ],
                });

              if (!formData?.[0].tenantCanEdit) {
                return throw404Error();
              }

              return formData;
            },
            element: <EditFormTemplate />,
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'templates/preview/:templateId',
            loader: ({ params }) => {
              const templateId = getNumber(params?.templateId);
              if (!templateId) throw404Error();

              return getPreviewFormDefinition({
                id: {
                  id: templateId,
                  provider: 'STANDARD',
                },
              });
            },
            element: <PreviewFormTemplate />,
          },
          {
            type: NavObjectType.MenuLink,
            path: 'requests',
            title: t('navigation:management.forms.requests'),
            loader: () => getFormRequests({ ids: [] }),
            element: <FormRequests />,
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'requests/view',
            loader: ({ request }) => {
              const url = new URL(request.url);

              const id = getNumber(url.searchParams.get('id'));
              const provider = url.searchParams.get('provider');

              if (!id || !provider) {
                throw404Error();
              }

              return getInfoRequestTableView({
                id: { id, provider },
              });
            },
            element: <FormRequestViewPage />,
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'requests/create',
            element: <CreateFormRequestPage />,
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'requests/create/permission-slip/:id',
            element: <CreatePermissionSlipPage />,
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'requests/edit',
            element: <EditFormRequestPage />,
            loader: async ({ request }) => {
              const url = new URL(request.url);

              const id = url.searchParams.get('id');
              const provider = url.searchParams.get('provider');

              if (!id || !provider) {
                throw404Error();
              }

              return getFormRequests({
                ids: [
                  {
                    id: getNumber(id) ?? 0,
                    provider,
                  },
                ],
              });
            },
          },
          {
            type: NavObjectType.NonMenuLink,
            path: 'single-submission',
            loader: ({ request }) => {
              const url = new URL(request.url);

              const id = url.searchParams.get('id');
              const provider = url.searchParams.get('provider');
              const forPartyId = getNumber(url.searchParams.get('forPartyId'));

              if (!id || !provider) {
                throw404Error();
              }

              return Promise.all([
                getPreviewFormDefinition({
                  id: {
                    id: getNumber(id),
                    provider,
                  },
                }),
                getViewSubmissions({
                  id: {
                    forPartyId,
                    objectId: getNumber(id) ?? 0,
                    provider,
                  },
                }),
              ]);
            },
            children: [
              {
                type: NavObjectType.NonMenuLink,
                path: 'add',
                element: <AddSingleSubmission />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'edit',
                element: <EditSingleSubmission />,
              },
              {
                type: NavObjectType.NonMenuLink,
                path: 'view',
                element: <ViewSingleSubmission />,
              },
            ],
          },
        ],
      },
    ],
  },
];

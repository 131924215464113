import { PageContainer, PageHeading, TabPageContainer } from '@tyro/core';

import { Core_CustomFieldPartySource } from '@tyro/api';
import { useTranslation } from '@tyro/i18n';

export const customFieldsPaths: Record<string, Core_CustomFieldPartySource> = {
  students: Core_CustomFieldPartySource.Student,
  staff: Core_CustomFieldPartySource.Staff,
  contacts: Core_CustomFieldPartySource.Contact,
};

export default function CustomFieldsContainer() {
  const { t } = useTranslation(['common', 'people', 'settings']);

  return (
    <PageContainer title={t('settings:customFields.title')}>
      <PageHeading
        title={t('settings:customFields.title')}
        titleProps={{ variant: 'h3' }}
      />
      <TabPageContainer
        links={[
          {
            label: t('common:students'),
            value: 'students',
          },
          {
            label: t('common:staff'),
            value: 'staff',
          },
          {
            label: t('people:contacts'),
            value: 'contacts',
          },
        ]}
      />
    </PageContainer>
  );
}

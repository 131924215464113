import { useMutation, useQuery } from '@tanstack/react-query';
import {
  type RoomPoolFilter,
  type UpsertRoomPool,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { roomKeys } from './keys';

const coreRoomPools = graphql(/* GraphQL */ `
  query core_roomPools($filter: RoomPoolFilter) {
    core_roomPools(filter: $filter) {
      id
      name
      description
      colour
      rooms {
        roomId
        name
      }
    }
  }
`);

const roomPoolsSelect = graphql(/* GraphQL */ `
  query roomPoolsSelect($filter: RoomPoolFilter) {
    core_roomPools(filter: $filter) {
      id
      name
      colour
    }
  }
`);

const createRoomPools = graphql(/* GraphQL */ `
  mutation core_upsertRoomPools($input: [UpsertRoomPool]) {
    core_upsertRoomPools(input: $input) {
      id
    }
  }
`);

const coreRoomPoolsQuery = (filter: RoomPoolFilter) => ({
  queryKey: roomKeys.coreRoomPools(),
  queryFn: async () => gqlClient.request(coreRoomPools, { filter }),
});

export function getCoreRoomPools(filter: RoomPoolFilter) {
  return queryClient.fetchQuery(coreRoomPoolsQuery(filter));
}

export function useCoreRoomPools(filter: RoomPoolFilter) {
  return useQuery({
    ...coreRoomPoolsQuery(filter),
    select: ({ core_roomPools }) => core_roomPools,
  });
}

const roomPoolsSelectQuery = (filter: RoomPoolFilter) => ({
  queryKey: roomKeys.roomPoolsSelect(),
  queryFn: async () => gqlClient.request(roomPoolsSelect, { filter }),
});

export function getRoomPoolsSelect(filter: RoomPoolFilter) {
  return queryClient.fetchQuery(roomPoolsSelectQuery(filter));
}

export function useRoomPoolsSelect(filter: RoomPoolFilter) {
  return useQuery({
    ...roomPoolsSelectQuery(filter),
    select: ({ core_roomPools }) => core_roomPools,
  });
}

export function useCreateOrUpdateRoomPools() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationKey: roomKeys.createOrUpdateRoomPools(),
    mutationFn: async (input: UpsertRoomPool[]) =>
      gqlClient.request(createRoomPools, { input }),
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
    onSuccess: async (_, [variables]) => {
      await queryClient.invalidateQueries({ queryKey: roomKeys.all });
      toast(
        t(
          `common:snackbarMessages.${
            variables?.id ? 'updateSuccess' : 'createSuccess'
          }`,
        ),
      );
    },
  });
}

export type ReturnTypeFromUseCoreRoomPools = UseQueryReturnType<
  typeof useCoreRoomPools
>[number];

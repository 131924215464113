import { Button } from '@mui/material';
import { type Person, TemplatingContextType } from '@tyro/api';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useFormValidator,
} from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { RHFStaffAutocomplete, RHFStudentAutocomplete } from '@tyro/people';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { getResolveTemplate } from '../../api/resolve-template';

export type PrintTemplateData = {
  partyIds: Pick<Person, 'partyId'>[];
};

type SelectRecipientsModalProps = {
  isOpen: boolean;
  context: TemplatingContextType | undefined;
  htmlContent: string | null | undefined;
  onClose: () => void;
};

export function SelectRecipientsModal({
  isOpen,
  context,
  htmlContent,
  onClose,
}: SelectRecipientsModalProps) {
  const { t } = useTranslation(['templates', 'common']);

  const { resolver, rules } = useFormValidator<PrintTemplateData>();
  const { control, handleSubmit, reset } = useForm<PrintTemplateData>({
    resolver: resolver({
      partyIds: rules.required(),
    }),
    defaultValues: {
      partyIds: [],
    },
  });

  const handlePreview = handleSubmit(async ({ partyIds }) => {
    if (context) {
      const resolvedTemplateResponse = await getResolveTemplate({
        context,
        template: htmlContent ?? '',
        partyIds: partyIds?.map((member) => member?.partyId) ?? [],
      });

      if (resolvedTemplateResponse.templating_resolveTemplate?.temporaryUrl) {
        window.open(
          resolvedTemplateResponse.templating_resolveTemplate?.temporaryUrl,
          '_blank',
          'noreferrer',
        );
        onClose();
      }
    }
  });

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);

  const commonProps = {
    fullWidth: true,
    multiple: true,
    optionIdKey: 'partyId',
    controlProps: {
      name: 'partyIds',
      control,
    },
    sx: { marginTop: 2 },
  } as const;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      scroll="paper"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{t('templates:templateStudentList')}</DialogTitle>
      <DialogContent>
        {context === TemplatingContextType.Student && (
          <>
            <DialogContentText>
              {t('templates:selectStudentsForTemplate')}
            </DialogContentText>
            <RHFStudentAutocomplete
              {...commonProps}
              label={t('common:students')}
            />
          </>
        )}
        {context === TemplatingContextType.Staff && (
          <>
            <DialogContentText>
              {t('templates:selectStaffForTemplate')}
            </DialogContentText>
            <RHFStaffAutocomplete {...commonProps} label={t('common:staff')} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="soft" onClick={onClose}>
          {t('common:actions.cancel')}
        </Button>

        <Button variant="contained" onClick={handlePreview}>
          {t('common:actions.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

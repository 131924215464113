import { useMutation } from '@tanstack/react-query';
import {
  type Core_CopyGroups,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { useToast } from '@tyro/core';
import { useTranslation } from '@tyro/i18n';
import { groupsKeys } from './keys';

const copyGroups = graphql(/* GraphQL */ `
  mutation core_copyGroups($input: [Core_CopyGroups]!) {
    core_copyGroups(input: $input) {
      success
    }
  }
`);

export function useCopyGroups() {
  const { toast } = useToast();
  const { t } = useTranslation(['common']);

  return useMutation({
    mutationFn: async (input: Core_CopyGroups[]) =>
      gqlClient.request(copyGroups, { input }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: groupsKeys.custom.all(),
      });

      toast(t('common:snackbarMessages.importedSuccess'));
    },
    onError: () => {
      toast(t('common:snackbarMessages.errorFailed'), { variant: 'error' });
    },
  });
}

import { useQuery } from '@tanstack/react-query';

import {
  type TtSolve_SolveParams,
  type UseQueryReturnType,
  gqlClient,
  graphql,
  queryClient,
} from '@tyro/api';
import { timetableKeys } from '../keys';

const restrictionsMeta = graphql(/* GraphQL */ `
  query ttsolve_restrictionsMeta($filter: TtSolve_SolveParams!) {
    ttsolve_restrictionsMeta(filter: $filter) {
      restriction {
        ruleId
        arguments {
          type
          label
          description
          required
          selectValues {
            id
            value
          }
        }
      }
    }
  }
`);

const restrictionsMetaQuery = (filter: TtSolve_SolveParams) => ({
  queryKey: timetableKeys.restrictionsMeta(filter),
  queryFn: async () => gqlClient.request(restrictionsMeta, { filter }),
});

export async function getRestrictionsMeta(filter: TtSolve_SolveParams) {
  return queryClient.fetchQuery(restrictionsMetaQuery(filter));
}

export function useRestrictionsMeta(filter: TtSolve_SolveParams) {
  return useQuery({
    ...restrictionsMetaQuery(filter),
    select: ({ ttsolve_restrictionsMeta }) =>
      (ttsolve_restrictionsMeta?.restriction || []).flatMap((restriction) => ({
        ...restriction,
        arguments: restriction.arguments?.flatMap((argument) =>
          argument ? [argument] : [],
        ),
      })),
  });
}

export type ReturnTypeFromUseRestrictionsMeta = UseQueryReturnType<
  typeof useRestrictionsMeta
>[number];
